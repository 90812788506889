import { CATEGORY_LOADER } from 'store/types';
import { GET_CATEGORY_LOADER } from 'store/types';
import { GET_CATEGORY } from 'store/types';

let initState = {
	getCatLoader: false,
	catLoader: false,
	categories: [],
};
export default function (state = initState, action) {
	switch (action.type) {
		case GET_CATEGORY:
			return {
				...state,
				categories: action.payload,
			};
		case CATEGORY_LOADER:
			return {
				...state,
				catLoader: action.payload,
			};
		case GET_CATEGORY_LOADER:
			return {
				...state,
				getCatLoader: action.payload,
			};
		default:
			return { ...state };
	}
}
