import React from 'react';
import PageTitle from 'components/PageTitle';
import ReportCard from 'components/ReportCard';
// import ReportingTable from "components/ReportingComp/ReportingTables";
// import LogoTitle from "components/Sidebar/LogoTitle";
import { Table, Button, Row, Col } from 'reactstrap';
// import Card from "../../../components/ReportingComp/ReportingCard";

const Reporting = (props) => {
	const pathRoute = props.history.location.pathname;
	var takeway = pathRoute.includes('takeaway');
	var instore = pathRoute.includes('in-store');
	const secondaryHeader = () => {


		if (takeway == true) {
			return {
				value: 'Takeaway/Delivery ',
				secondary: true,
			};
		} else if (instore == true) {
			return {
				value: 'InStore ',
				secondary: true,
			};
		} else {
			return {
				secondary: false,
			};
		}
	};
	const dataTable = [
		{
			itemImg: '/green-circle.png',
			name: 'Pasta Gambari',
			numbers: 38,
			price: '1300',
		},
		{
			itemImg: '/green-circle.png',
			name: 'Pinsa  Truffel',
			numbers: 24,
			price: '800',
		},
		{
			itemImg: '/green-circle.png',
			name: 'Pinsa  Margheritha',
			numbers: 17,
			price: '400',
		},
		{
			itemImg: '/green-circle.png',
			name: 'Pinsa  Reef & Beef',
			numbers: 15,
			price: '420',
		},
		{
			itemImg: '/green-circle.png',
			name: 'Item',
			numbers: 'X',
			price: '0',
		},
		{
			itemImg: '/green-circle.png',
			name: 'Item',
			numbers: 'X',
			price: '800',
		},
		{
			itemImg: '/green-circle.png',
			name: 'Item',
			numbers: 'X',
			price: '400',
		},
		{
			itemImg: '/green-circle.png',
			name: 'Item',
			numbers: 'X',
			price: '420',
		},
		{
			itemImg: '/green-circle.png',
			name: 'Item',
			numbers: 'X',
			price: '1300',
		},
		{
			itemImg: '/green-circle.png',
			name: 'Item',
			numbers: 'X',
			price: '800',
		},
	];

	return (
		<>
			<Row className='p-3'>
				<Col xs='12' sm='12' md='12' lg='12'>
					<Row>
						<Col xs='12' sm='12' md='6' lg='6'>
							<div className='card p-2 border border-light rounded'>
								<PageTitle
									icon={'/img/sidebar/report.png'}
									title='Reporting'
									{...secondaryHeader()}
								/>
							</div>
						</Col>
						<Col xs='12' sm='12' md='6' lg='6'>
							<div className='d-flex flex-row align-items-center'>
								{pathRoute == '/store/in-store/reporting' ||
									pathRoute ==
									'/store/takeaway-delivery/reporting' ? (
									''
								) : (
									<div className='d-flex align-items-center'>
										<div className='mr-2'>
											<img
												src='/img/sidebar/store.png'
												alt='store'
												height='30px'
											/>
										</div>
										<h4 className='mt-1 text-black'>
											Vapiano MEDIENHAFEN 20
										</h4>
									</div>
								)}
								<div className='d-flex flex-row mt-2 ml-auto justify-content-end'>
									<Button color='primary'>
										<img
											src='/img/sidebar/printing.png'
											alt='printer'
											height='30px'
										/>
									</Button>
									<Button color='primary'>
										<img
											src='/img/sidebar/undo.png'
											alt='printer'
											height='30px'
										/>
									</Button>
								</div>
							</div>
						</Col>
					</Row>
					<ReportCard
						headerTitle={
							takeway == true
								? 'Takeaway/Delivery Income'
								: 'Store Income'
						}
					/>
					{/* Second Row */}
					<Row className='mt-4'>
						<Col md='6'>
							<div className='card p-3 border border-light rounded  '>
								<div className='d-flex flex-row mt-2'>
									<div className='mr-2 mt-2'>
										<img
											src='/img/ReportPage/invoice.png'
											alt='store'
											height='30px'
										/>
									</div>
									<h5 className='mt-3 text-dark'>
										<b>Tickets</b>
									</h5>
								</div>
								<div className='d-flex flex-row justify-content-between'>
									{' '}
									<div className='mt-3 text-center'>
										<div className='d-flex flex-row justify-content-center'>
											<img
												src='/green-circle.png'
												alt='green'
												height='10px'
												className='mr-2 mt-1'
											/>
											<h6 className='text-dark '>
												<b>Last Week</b>
											</h6>
										</div>
										<h2 className='text-dark '>
											<b>-7%</b>
										</h2>
									</div>
									<div className='mt-3 text-center'>
										<div className='d-flex flex-row justify-content-center'>
											<img
												src='/ellipse.png'
												alt='ellipse'
												height='10px'
												className='mr-2 mt-1'
											/>
											<h6 className='text-dark '>
												<b>Forecast</b>
											</h6>
										</div>
										<h2 className='text-dark '>
											<b>10%</b>
										</h2>
									</div>
									<div className='mt-3 text-center'>
										<div className='d-flex flex-row justify-content-center'>
											<img
												src='/purple-dot.png'
												alt='ellipse'
												height='10px'
												className='mr-2 mt-1'
											/>
											<h6 className='text-dark '>
												<b>Yesterday</b>
											</h6>
										</div>
										<h2 className='text-dark '>
											<b>+10%</b>
										</h2>
									</div>
								</div>
							</div>
						</Col>
						<Col md='6'>
							<div className='card p-3 border border-light rounded '>
								<div className='d-flex flex-row mt-2 justify-content-between'>
									<div className='mr-2 mt-2 d-flex flex-row'>
										<img
											src='/img/ReportPage/invoice-black.png'
											alt='store'
											height='30px'
										/>
										<h4 className='ml-2 mt-1 text-dark'>
											<b>Average Tickets</b>
										</h4>
									</div>

									<div className='mt-2'>
										<h4 className='text-success'>
											<b>30,15€</b>
										</h4>
									</div>
								</div>
								<div className='d-flex flex-row justify-content-between'>
									{' '}
									<div className='mt-3 text-center'>
										<div className='d-flex flex-row justify-content-center'>
											<img
												src='/green-circle.png'
												alt='green'
												height='10px'
												className='mr-2 mt-1'
											/>
											<h6 className='text-dark '>
												<b>Last Week</b>
											</h6>
										</div>
										<h2 className='text-dark '>
											<b>-7%</b>
										</h2>
									</div>
									<div className='mt-3 text-center'>
										<div className='d-flex flex-row justify-content-center'>
											<img
												src='/ellipse.png'
												alt='ellipse'
												height='10px'
												className='mr-2 mt-1'
											/>
											<h6 className='text-dark '>
												<b>Forecast</b>
											</h6>
										</div>
										<h2 className='text-dark '>
											<b>10%</b>
										</h2>
									</div>
									<div className='mt-3 text-center'>
										<div className='d-flex flex-row justify-content-center'>
											<img
												src='/purple-dot.png'
												alt='ellipse'
												height='10px'
												className='mr-2 mt-1'
											/>
											<h6 className='text-dark '>
												<b>Yesterday</b>
											</h6>
										</div>
										<h2 className='text-dark '>
											<b>+10%</b>
										</h2>
									</div>
								</div>
							</div>
						</Col>
					</Row>
					<Row className='mt-4'>
						<Col md='6'>
							<div className='card p-3 border border-light rounded  '>
								<div className='d-flex flex-row mt-2 justify-content-between'>
									<div className='mr-2 mt-2 d-flex flex-row'>
										<img
											src='/img/ReportPage/user-blank.png'
											alt='store'
											height='30px'
										/>
										<h4 className='ml-2 mt-1 text-dark'>
											<b>Guests</b>
										</h4>
									</div>

									<div className='mt-2'>
										<h4 className='text-success'>
											<b>28</b>
										</h4>
									</div>
								</div>
								<div className='d-flex flex-row justify-content-between'>
									{' '}
									<div className='mt-3 text-center'>
										<div className='d-flex flex-row justify-content-center'>
											<img
												src='/green-circle.png'
												alt='green'
												height='10px'
												className='mr-2 mt-1'
											/>
											<h6 className='text-dark '>
												<b>Last Week</b>
											</h6>
										</div>
										<h2 className='text-dark '>
											<b>-7%</b>
										</h2>
									</div>
									<div className='mt-3 text-center'>
										<div className='d-flex flex-row justify-content-center'>
											<img
												src='/ellipse.png'
												alt='ellipse'
												height='10px'
												className='mr-2 mt-1'
											/>
											<h6 className='text-dark '>
												<b>Forecast</b>
											</h6>
										</div>
										<h2 className='text-dark '>
											<b>10%</b>
										</h2>
									</div>
									<div className='mt-3 text-center'>
										<div className='d-flex flex-row justify-content-center'>
											<img
												src='/purple-dot.png'
												alt='ellipse'
												height='10px'
												className='mr-2 mt-1'
											/>
											<h6 className='text-dark '>
												<b>Yesterday</b>
											</h6>
										</div>
										<h2 className='text-dark '>
											<b>+10%</b>
										</h2>
									</div>
								</div>
							</div>
						</Col>
						<Col md='6'>
							{/* Average Guests */}
							<div className='card p-3 border border-light rounded '>
								<div className='d-flex flex-row mt-2 justify-content-between'>
									<div className='mr-2 mt-2 d-flex flex-row'>
										<img
											src='/img/ReportPage/invoice-black.png'
											alt='store'
											height='30px'
										/>
										<h4 className='ml-2 mt-1 text-dark'>
											<b>Average Guests</b>
										</h4>
									</div>

									<div className='mt-2'>
										<h4 className='text-success'>
											<b>15,72</b>
										</h4>
									</div>
								</div>
								<div className='d-flex flex-row justify-content-between'>
									{' '}
									<div className='mt-3 text-center'>
										<div className='d-flex flex-row justify-content-center'>
											<img
												src='/green-circle.png'
												alt='green'
												height='10px'
												className='mr-2 mt-1'
											/>
											<h6 className='text-dark '>
												<b>Last Week</b>
											</h6>
										</div>
										<h2 className='text-dark '>
											<b>-7%</b>
										</h2>
									</div>
									<div className='mt-3 text-center'>
										<div className='d-flex flex-row justify-content-center'>
											<img
												src='/ellipse.png'
												alt='ellipse'
												height='10px'
												className='mr-2 mt-1'
											/>
											<h6 className='text-dark '>
												<b>Forecast</b>
											</h6>
										</div>
										<h2 className='text-dark '>
											<b>10%</b>
										</h2>
									</div>
									<div className='mt-3 text-center'>
										<div className='d-flex flex-row justify-content-center'>
											<img
												src='/purple-dot.png'
												alt='ellipse'
												height='10px'
												className='mr-2 mt-1'
											/>
											<h6 className='text-dark '>
												<b>Yesterday</b>
											</h6>
										</div>
										<h2 className='text-dark '>
											<b>+10%</b>
										</h2>
									</div>
								</div>
							</div>
						</Col>
					</Row>

					{/* third Row */}
					<Row>
						<Col xs='12' className='mt-4'>
							<div className='card p-3 border border-light rounded'>
								<div className='d-flex flex-row justify-content-between '>
									<div className='d-flex flex-row mt-2'>
										<img
											src='/img/sidebar/stats.png'
											alt='store'
											height='30px'
										/>

										<h5 className='ml-2 text-dark'>
											<b>Performance</b>
										</h5>
									</div>
									<div className='mb-2'>
										<Button color='primary'>
											compare to
										</Button>
									</div>
								</div>
								<div className='w-100'>
									<img
										src='/img/ReportPage/progress-Stat.png'
										alt='progress'
										className='w-100'
									/>
								</div>
							</div>
						</Col>
						<Col xs='12' className='mt-4'>
							<div className='card p-3 border border-light rounded'>
								<div className='d-flex flex-row justify-content-between '>
									<div className='d-flex flex-row mt-2'>
										<img
											src='/img/sidebar/stats.png'
											alt='store'
											height='20px'
										/>

										<h4 className='ml-2 text-dark'>
											<b>Open Table</b>
										</h4>
									</div>
									<div className='mb-2'>
										<h2 className='text-success'>
											<b>28</b>
										</h2>
									</div>
								</div>
							</div>
						</Col>
						<Col xs='12' className='mt-4'>
							<div className='card p-3 border border-light rounded '>
								<div className='d-flex flex-row justify-content-between '>
									<div className='d-flex flex-row mt-2'>
										<img
											src='/img/ReportPage/invoice.png'
											alt='store'
											height='30px'
										/>

										<h4 className='ml-2 text-dark'>
											<b>Staff Receipt</b>
										</h4>
									</div>
									<div className='mb-2'>
										<h2 className='text-success'>
											<b>94</b>
										</h2>
									</div>
								</div>
								<div className='mt-2'>
									<Table
										className='table table-borderless'
										responsive
									>
										<tbody>
											<tr>
												<td>
													<img
														src='/green-circle.png'
														alt='green'
														height='10px'
													/>
												</td>
												<td>Sara Kellermann</td>
												<td>38</td>
												<td>1300 €</td>
											</tr>
											<tr>
												<td>
													<img
														src='/ellipse.png'
														alt='green'
														height='10px'
													/>
												</td>
												<td>David Muns</td>
												<td>24</td>
												<td>800 €</td>
											</tr>
											<tr>
												<td>
													<img
														src='/purple-dot.png'
														alt='green'
														height='10px'
													/>
												</td>
												<td>Markus Wiel</td>
												<td>17</td>
												<td>400 €</td>
											</tr>
											<tr>
												<td>
													<img
														src='/green-circle.png'
														alt='green'
														height='10px'
													/>
												</td>
												<td>Zoe Schniedar</td>
												<td>38</td>
												<td>1300 €</td>
											</tr>
										</tbody>
									</Table>
								</div>
							</div>
						</Col>
						<Col xs='12' className='mt-4'>
							<div className='card p-3 border border-light rounded '>
								<div className='d-flex flex-row justify-content-between '>
									<div className='d-flex flex-row mt-2'>
										<img
											src='/img/sidebar/stats.png'
											alt='store'
											height='20px'
										/>

										<h4 className='ml-2 text-dark'>
											<b>Tips Average</b>
										</h4>
									</div>
									<div className='mb-2'>
										<h2 className='text-success'>
											<b>28</b>
										</h2>
									</div>
								</div>
							</div>
						</Col>
						<Col xs='12' className='mt-4'>
							<div className='card p-3 border border-light rounded '>
								<div className='d-flex flex-row justify-content-between '>
									<div className='d-flex flex-row mt-2'>
										<img
											src='/img/ReportPage/invoice.png'
											alt='store'
											height='30px'
										/>

										<h4 className='ml-2 text-dark'>
											<b>Tops Selling Product</b>
										</h4>
									</div>
									<div className='mb-2'>
										<h2 className='text-success'>
											<b>94</b>
										</h2>
									</div>
								</div>
								<div className='mt-2'>
									<Table
										className='table table-borderless'
										responsive
									>
										<tbody>
											{dataTable.map((m, i) => {
												return (
													<tr>
														<td>
															<img
																src={m.itemImg}
																alt='green'
																height='10px'
															/>
														</td>
														<td>{m.name}</td>
														<td>{m.numbers}</td>
														<td>{m.price} €</td>
													</tr>
												);
											})}
										</tbody>
									</Table>
								</div>
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
};

export default Reporting;
