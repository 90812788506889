import { RepositoryFactory } from "../../repository/RepositoryFactory";
var StationsRepository = RepositoryFactory.get("stations");

export const getAllStations = () => async (dispatch) => {
  try {
    let { data } = await StationsRepository.getAllStations();

    dispatch({
      type: "GET_ALL_STATIONS",
      payload: data.data,
    });
  } catch (err) {
    console.log("error", err.message);
  }
};
export const createStations = (payload) => async (dispatch) => {
  try {
    let { data } = await StationsRepository.createStations(payload);
    dispatch(getAllStations());
  } catch (err) {
    console.log("error", err.message);
  }
};
export const updateStations = (obj) => async (dispatch) => {
  try {
    let { data } = await StationsRepository.updateStations(obj);
    //   console.log("item deleted");
    if (data.success) {
      alert("Station Updated");
      dispatch(getAllStations());
    }
  } catch (err) {
    console.log("error", err.message);
  }
};
export const deleteStations = (_id) => async (dispatch) => {
  try {
    let { data } = await StationsRepository.deleteStations(_id);
    //   console.log("item deleted");
    alert("Station Deleted");
    dispatch(getAllStations());
  } catch (err) {
    console.log("error", err.message);
  }
};
