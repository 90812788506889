export const fontSize = [
  { value: "12", label: "12px" },
  { value: "14", label: "14px" },
  { value: "16", label: "16px" },
  {
    value: "18",
    label: "18px",
  },
  {
    value: "20",
    label: "20px",
  },
  {
    value: "22",
    label: "22px",
  },
  {
    value: "24",
    label: "24px",
  },
  {
    value: "26",
    label: "26px",
  },
  {
    value: "28",
    label: "28px",
  },
  {
    value: "30",
    label: "30px",
  },
  {
    value: "32",
    label: "32px",
  },
];

export const fontfamily = [
  { value: "poppins", label: "Font Poppins" },
  { value: "montserrat", label: "Font Montserrat" },
  { value: "nunito", label: "Font Nunito" },
  { value: "barlow", label: "Font Barlow" },
];
