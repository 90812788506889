import FloorPlanCard from "components/FloorPlanCard";
import PageTitle from "components/PageTitle";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Col, Row, Spinner } from "reactstrap";
import { getAllSessions } from "store/actions/floorPlanActions";
const FloorPlans = () => {
  const dispatch = useDispatch();
  const { sessions, getSessionsLoader, removeSessionLoader } = useSelector(
    (state) => state.floor_plans
  );
  const { blId } = useSelector((state) => state.blId);
  useEffect(() => {
    dispatch(getAllSessions(blId));
  }, [blId]);
  console.log('ses--?',sessions)
  return (
    <>
      <Row className="p-3">
        <Col lg="6" sm="12" md="6" xs="12">
          <div className="card p-2 border border-light rounded ">
            <PageTitle icon={"/img/sidebar/map.png"} title="Sessions" />
          </div>
        </Col>
        <Col lg="12" sm="12" md="12" xs="12">
          {getSessionsLoader ? (
            <Spinner
              className="d-flex mx-auto mt-5"
              size="lg"
              color="primary"
            />
          ) : (
            <Row className="p-3">
              {sessions &&
                sessions.map((data) => {
                  return (
                    <FloorPlanCard
                      data={data}
                      blId={blId}
                      loading={removeSessionLoader}
                    />
                  );
                })}
              {/* <FloorPlanCard title={"Andre"} />
                <FloorPlanCard title={"Daniele"} />
    
                <FloorPlanCard title={"Chanh Le"} />
                <FloorPlanCard title={"Andre"} />
                <FloorPlanCard title={"Daniele"} /> */}
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

export default FloorPlans;
