import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Button,
	Form,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Spinner,
} from 'reactstrap';
import { updateAllergen, createAllergen } from 'store/actions/allergenAction';

const AllergenModal = props => {
	const [allergenName, setAllergenName] = useState('');
	const [allergenFile, setAllergenFile] = useState([]);
	const dispatch = useDispatch();
	const { allergenLoader } = useSelector(state => state.allergen);
	return (
		<>
			<Modal
				centered
				isOpen={props.allergenShow}
				toggle={props.handleAllergenClose}
			>
				<ModalHeader>{props.title}</ModalHeader>
				<Form
					onSubmit={e => {
						e.preventDefault();
						let obj;
						if (props.btnTitle == 'Add') {
							obj = {
								title: allergenName,
								image: allergenFile,
							};
							dispatch(
								createAllergen(obj, () => {
									setAllergenName('');
									setAllergenFile([]);
									props.handleAllergenClose();
								})
							);
						} else {
							obj = {
								title: allergenName,
								image: allergenFile,
								id: props.singleval._id,
							};
							dispatch(
								updateAllergen(obj, () => {
									setAllergenName('');
									setAllergenFile([]);
									props.handleAllergenClose();
								})
							);
						}
					}}
				>
					<ModalBody>
						<div>
							<Label>Name</Label>
							<Input
								type='text'
								className='form-control w-75'
								placeholder={props.title + ' Name'}
								required
								defaultValue={
									props.btnTitle != 'Add'
										? props.singleval &&
										  props.singleval.title
										: allergenName
								}
								onChange={e => setAllergenName(e.target.value)}
							/>
							{props.btnTitle != 'Add' && (
								<>
									<img
										className='mt-3'
										src={
											props.singleval &&
											props.singleval.icon
										}
										alt='imag'
										height='100'
										width='100'
									/>
									<br />
								</>
							)}
							<Label className='mt-3'>Allergen Image</Label>
							<Input
								type='file'
								required={props.btnTitle == 'Add'}
								onChange={e =>
									setAllergenFile(e.target.files[0])
								}
							/>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button
							className='mr-3'
							color='danger'
							disabled={allergenLoader}
							onClick={props.handleAllergenClose}
						>
							Close
						</Button>
						{props.btnTitle == 'Add' ? (
							<Button
								disabled={allergenLoader}
								color='primary'
								type='submit'
							>
								{allergenLoader ? <Spinner size='sm' /> : 'Add'}
							</Button>
						) : (
							<Button
								disabled={allergenLoader}
								color='primary'
								type='submit'
							>
								{allergenLoader ? (
									<Spinner size='sm' />
								) : (
									'Edit'
								)}
							</Button>
						)}
					</ModalFooter>
				</Form>
			</Modal>
		</>
	);
};

export default AllergenModal;
