import { getArticles } from "./articleAction";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
var ArticleDetailsRepository = RepositoryFactory.get("article_details");

export const getArticleDetailLoader = (val) => async (dispatch) => {
  dispatch({
    type: "GET_ARTICLE_DETAIL_LOADER",
    payload: val,
  });
};
export const articleDetailsLoader = (val) => async (dispatch) => {
  dispatch({
    type: "ARTICLE_DETAIL_LOADER",
    payload: val,
  });
};

export const getDetailsByArticle = (id, bid) => async (dispatch) => {
  dispatch(getArticleDetailLoader(true));
  let { data } = await ArticleDetailsRepository.getArticleDetails(id, bid);

  try {
    if (data.success) {
      dispatch({
        type: "GET_ARTICLE_DETAILS",
        payload: data.data,
      });
      dispatch(getArticleDetailLoader(false));
    } else {
      dispatch(getArticleDetailLoader(false));
    }
  } catch (e) {
    dispatch({
      type: "GET_ARTICLE_DETAILS",
      payload: [],
    });
    dispatch(getArticleDetailLoader(false));
  }
};

export const addDetailsInArticle =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(articleDetailsLoader(true));
    try {
      let { data } = await ArticleDetailsRepository.addArticleDetails(payload);
      // console.log('add action', data);
      if (data.success) {
        // alert('Ingredient Added');
        onSuccess();
        dispatch(getArticles(payload.blId));
        dispatch(getDetailsByArticle(payload.article_id, payload.blId));
        dispatch(articleDetailsLoader(false));
      } else {
        dispatch(articleDetailsLoader(false));
      }
    } catch (e) {
      dispatch(articleDetailsLoader(false));
    }
  };

export const addMenuDetailsInArticle =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(articleDetailsLoader(true));
    try {
      let { data } = await ArticleDetailsRepository.addMenuInArticleDetails(
        payload
      );
      // console.log('add action', data);
      if (data.success) {
        // alert('Ingredient Added');
        dispatch(articleDetailsLoader(false));
      } else {
        dispatch(articleDetailsLoader(false));
      }
    } catch (e) {
      dispatch(articleDetailsLoader(false));
    }
  };

export const getArticleDescription = (id, blId) => async (dispatch) => {
  let { data } = await ArticleDetailsRepository.getArticleDescription(id, blId);

  try {
    if (data.message == "Succesfully get SKU details") {
      dispatch({
        type: "GET_ARTICLE_DESCRIPTION",
        payload: data.data,
      });
    }
  } catch (e) {
    dispatch({
      type: "GET_ARTICLE_DESCRIPTION",
      payload: {},
    });
  }
};

// export const changeItemStatusAct = payload => async dispatch => {
// 	let { data } = await ArticleDetailsRepository.changeItemStatusRep(payload);

// 	try {
// 		if (data.success) {
// 			dispatch({
// 				type: 'GET_ARTICLE_DETAILS',
// 				payload: data.data,
// 			});
// 		}
// 	} catch (e) {
// 		console.log(e.message);
// 	}
// };
