import Repository from './Repository';
const ARTICLE_MENU = '/article-menu';
export default {
	getMenusByArticle(payload) {
		return Repository.get(`${ARTICLE_MENU}/${payload}`);
	},
	addMenusInArticle(payload) {
		return Repository.post(`${ARTICLE_MENU}`, payload);
	},
};
