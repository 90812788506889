import ArticleItem from 'components/ArticleItem';
import PageTitle from 'components/PageTitle';
import React, { useState, useEffect } from 'react';
import { articleAction } from 'store/actions/articleAction';
import { useDispatch, useSelector } from 'react-redux';
import { ArticleData } from '../../../components/DummyData/Article';

import {
	Button,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Spinner,
} from 'reactstrap';
import { DragDropContext } from 'react-beautiful-dnd';
import { Droppable } from 'react-beautiful-dnd';
import { Draggable } from 'react-beautiful-dnd';
import ArticalModal from 'components/ArticalModal';
const Index = () => {
	const [showModal, setShowModal] = useState('');
	const [grp, setGrp] = useState([]);
	const [group, setGroup] = useState('');
	// const handleClose = () => setShowModal(false);
	const [single, setSingle] = useState({});
	const dispatch = useDispatch();
	const { articles, loading } = useSelector(state => state?.articles);
	const { blId } = useSelector(state => state.blId);

	let [activeArticles, setActiveArticles] = useState([]);
	let [addArticleModal, setAddArticleModal] = useState(false);
	// let [articles, setArticles] = useState(ArticleData);
	// let articles = new Array(6).fill(1).map((_, key) => ({ id: key }));
	const isArticleActive = idx => {
		return activeArticles.indexOf(idx) != -1;
	};

	useEffect(() => {
		// let ls = localStorage.getItem('blId');
		dispatch(articleAction(blId));
	}, [blId]);

	const toggleArticleActive = idx => {
		setActiveArticles(prevProp => {
			let articleIdx = activeArticles.indexOf(idx);
			if (articleIdx == -1) {
				prevProp.push(idx);
				return [...prevProp];
			} else {
				prevProp.splice(articleIdx, 1);
				return [...prevProp];
			}
		});
	};
	const toggleAddArticleModal = idx => {
		setAddArticleModal(!addArticleModal);
	};
	const expandAll = () => {
		setActiveArticles(prevProp => {
			return articles.map(article => article.id);
		});
	};
	const collapseAll = () => {
		setActiveArticles([]);
	};

	return (
		<>
			<div className='pb-4'>
				<div className='d-block d-md-flex align-items-center'>
					<div className='d-flex align-items-center'>
						<PageTitle
							title='Articles'
							icon='/img/sidebar/spoon.png'
						/>
						<span className='ml-3'>
							<i className='fas fa-wrench'></i>
						</span>
						<FormGroup className='ml-3' check>
							<Label check>
								<Input className='mt-2' type='checkbox' />{' '}
								<small className='font-weight-bold'>
									POS inactive
								</small>
							</Label>
						</FormGroup>
					</div>
					<div className='d-block d-sm-flex ml-auto mt-3 mt-md-0 align-items-center'>
						<Button color='light' size='sm' onClick={expandAll}>
							Expand All <i className='fa fa-angle-down'></i>
						</Button>
						<Button color='light' size='sm' onClick={collapseAll}>
							Collapse All <i className='fa fa-angle-up'></i>
						</Button>
						<br />
						<Button
							color='primary'
							className='mt-3 mt-sm-0'
							onClick={toggleAddArticleModal}
						>
							Add Group
							<i className='ml-2 fa fa-plus-circle'></i>
						</Button>
					</div>
				</div>
				<div className='border mt-3 card'>
					<DragDropContext>
						<Droppable droppableId='droppableArticle'>
							{(provided, snapshot) => (
								<div
									ref={provided.innerRef}
									className='d-flex flex-column '
								>
									{loading ? (
										<Spinner
											className='d-flex mx-auto mt-5'
											size='lg'
											color='primary'
										/>
									) : (
										<>
											{articles.map((article, k) => (
												<Draggable
													key={k}
													draggableId={k.toString()}
													index={k}
												>
													{(provided, snapshot) => (
														<ArticleItem
															showModal={
																showModal
															}
															setShowModal={
																setShowModal
															}
															title={
																'Edit Article'
															}
															// handleClose={handleClose}
															single={single}
															setSingle={
																setSingle
															}
															group={group}
															setGroup={setGroup}
															setGrp={setGrp}
															grp={grp}
															article={article}
															ref={
																provided.innerRef
															}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															isActive={isArticleActive(
																article.id
															)}
															toggleActive={() =>
																toggleArticleActive(
																	article.id
																)
															}
														/>
													)}
												</Draggable>
											))}
										</>
									)}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</div>
			</div>
			{single && (
				<ArticalModal
					showModal={showModal}
					setShowModal={setShowModal}
					title={'Edit Article'}
					// handleClose={handleClose}
					single={single}
					group={group}
					setGrp={setGrp}
					grp={grp}
				/>
			)}
			<Modal
				centered
				isOpen={addArticleModal}
				toggle={toggleAddArticleModal}
			>
				<ModalHeader toggle={toggleAddArticleModal}>
					Add Group
				</ModalHeader>
				<Form>
					<ModalBody>
						<FormGroup>
							<Label>Group Name</Label>
							<Input type='text' />
						</FormGroup>
					</ModalBody>
					<ModalFooter>
						<Button color='primary'>Save</Button>
					</ModalFooter>
				</Form>
			</Modal>
		</>
	);
};

export default Index;
