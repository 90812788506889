import React, { useState, useEffect } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Droppable } from 'react-beautiful-dnd';
import { DragDropContext } from 'react-beautiful-dnd';
import { Collapse, CustomInput, Table } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import ArticalModal from './ArticalModal';
import { getAllIngredientsAct } from 'store/actions/ingredientActions';

const IngredientItem = React.forwardRef(
	({ ingredient, isActive, toggleActive, ...props }, ref) => {
		const [showModal, setShowModal] = useState(false);
		const handleClose = () => setShowModal(false);
		const dispatch = useDispatch();
		const { blId } = useSelector(state => state.blId);
		const { ingredientsAll } = useSelector(state => state.ingredient);

		return (
			<div ref={ref} className='article' {...props}>
				<div
					className='d-flex align-items-center p-3'
					onClick={toggleActive}
				>
					<span className='mr-3 cursor-pointer'>
						<i className='fa fa-grip-lines'></i>
					</span>
					<div className='d-flex flex-column group-name'>
						<small className='text-xs'>Group name</small>
						<h6 className='h6 mb-0'>{ingredient?.group}</h6>
					</div>
					<i className='ml-auto'>
						{isActive ? (
							<i className='fa fa-angle-up'></i>
						) : (
							<i className='fa fa-angle-down'></i>
						)}
					</i>
				</div>
				<Collapse isOpen={isActive}>
					<Table
						responsive
						borderless
						className='article__table mb-0'
					>
						<DragDropContext>
							<Droppable droppableId='droppableArticleItems'>
								{(provided, snapshot) => (
									<tbody ref={provided.innerRef}>
										{ingredient?.items.map((item, k) => (
											<Draggable
												key={k}
												draggableId={k.toString()}
												index={k}
											>
												{(provided, snapshot) => (
													<tr
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														className='w-100'
													>
														<td>
															<span className='cursor-pointer text-md'>
																<i className='fa fa-grip-lines'></i>
															</span>
														</td>
														<td>
															<span className='mb-0 group-name group-name--subitem d-flex w-100'>
																{
																	item?.productName
																}
															</span>
														</td>
														<td>Prices</td>
														<td>
															<span className='mb-0 group-name group-name--subitem d-flex w-100'>
																{
																	item?.productPrice
																}{' '}
																€
															</span>
														</td>
														{!window.location.href.includes(
															'admin'
														) && (
															<td>
																<CustomInput
																	type='switch'
																	id={
																		'switch' +
																		k
																	}
																/>
															</td>
														)}
														<td>
															<i
																onClick={e => {
																	setShowModal(
																		true
																	);
																}}
																className='fa fa-cog'
															></i>
														</td>
													</tr>
												)}
											</Draggable>
										))}
									</tbody>
								)}
							</Droppable>
						</DragDropContext>
					</Table>
				</Collapse>
				{/* <ArticalModal
					showModal={showModal}
					title={'Edit Article'}
					handleClose={handleClose}
				/> */}
			</div>
		);
	}
);

export default IngredientItem;
