export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';

export const AUTH_SET_LOADING = 'AUTH_SET_LOADING';
export const AUTH_NOTIFICATION = 'AUTH_NOTIFICATION';

export const SET_ARTICLES = 'SET_ARTICLES';
export const GET_BUSINESSES = 'GET_BUSINESSES';
export const GET_BUSINESSES_LOADER = 'GET_BUSINESSES_LOADER';
export const GET_INGREDIENTS = 'GET_INGREDIENTS';
export const GET_INGREDIENTS_ALL = 'GET_INGREDIENTS_ALL';
export const GET_INGREDIENTS_LOADER = 'GET_INGREDIENTS_LOADER';
export const GET_MENU = 'GET_MENU';
export const GET_SINGLE_MENU = 'GET_SINGLE_MENU';
export const GET_SINGLE_MENU_ITEMS = 'GET_SINGLE_MENU_ITEMS';
export const GET_MENU_LOADER = 'GET_MENU_LOADER';
export const MENU_LOADER = 'MENU_LOADER';
export const GET_CATEGORY = 'GET_CATEGORY';
export const CATEGORY_LOADER = 'CATEGORY_LOADER';
export const GET_CATEGORY_LOADER = 'GET_CATEGORY_LOADER';
