import {
	GET_INGREDIENTS,
	GET_INGREDIENTS_ALL,
	GET_INGREDIENTS_LOADER,
} from 'store/types';

let initState = {
	getIngrLoader: false,
	ingredients: [],
	ingredientsAll: [],
};
export default function (state = initState, action) {
	switch (action.type) {
		case GET_INGREDIENTS:
			return {
				...state,
				ingredients: action.payload,
			};
		case GET_INGREDIENTS_ALL:
			return {
				...state,
				ingredientsAll: action.payload,
			};
		case GET_INGREDIENTS_LOADER:
			return {
				...state,
				getIngrLoader: action.payload,
			};
		default:
			return { ...state };
	}
}
