import React, { useState } from 'react';
import OrdersTable from 'store/OrdersTable';
import PageTitle from 'components/PageTitle';
import { Button, Col, Input, Row } from 'reactstrap';
const Order = () => {
	const [expand, setExpand] = useState(false);
	const [toggle, setToggle] = useState();
	return (
		<>
				<Row className='p-3'>
			<Col xs="12" md="6" sm="12" lg="6" >
		<div className=' card border border-light rounded  head p-2 '>
				<PageTitle icon='/img/sidebar/order.png' title='Orders' />
			</div>
			</Col>
			<Col xs="12" md="6" sm="12" lg="6" >
			<div className='d-flex align-items-center justify-content-between mt-0 mt-sm-2'>
				<span className='d-flex flex-row ml-auto'>
					<Button
						color='light'
						size='sm'
						onClick={() => {
							setExpand(true);
						}}
						className='w-100'
					>
						Expand All <i className='fa fa-angle-down'></i>
					</Button>
					<Button
						color='light'
						size='sm'
						onClick={() => {
							setExpand(false);
							setToggle();
						}}
						className='w-100'
					>
						Collapse All <i className='fa fa-angle-up'></i>
					</Button>
					<Input type='text' placeholder='Search' className='w-100' />
				</span>
			</div>
			</Col>
			<Col xs="12" md="12" sm="12" lg="12">
			<div className='mt-3 mb-4 card'>
				<OrdersTable
					expand={expand}
					toggle={toggle}
					setToggle={setToggle}
				/>
			</div>
	</Col>
			</Row>
		</>
	);
};
export default Order;
