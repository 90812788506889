import axios from 'axios';
import { GET_CATEGORY_LOADER } from 'store/types';
import { CATEGORY_LOADER } from 'store/types';
import { GET_CATEGORY } from 'store/types';
import { RepositoryFactory } from '../../repository/RepositoryFactory';
var categoryRepository = RepositoryFactory.get('category');

export const getCategoryLoader = val => async dispatch => {
	dispatch({
		type: GET_CATEGORY_LOADER,
		payload: val,
	});
};
export const categoryLoader = val => async dispatch => {
	dispatch({
		type: CATEGORY_LOADER,
		payload: val,
	});
};

export const getAllCategories = () => async dispatch => {
	// dispatch(getCategoryLoader(true));
	// const { data } = await axios.get(
	// 	'https://appapi1.va-piano.ch/api/category'
	// );
	// try {
	// 	if (data) {
	// 		dispatch({
	// 			type: GET_CATEGORY,
	// 			payload: data,
	// 		});
	// 		dispatch(getCategoryLoader(false));
	// 	} else {
	// 		dispatch(getCategoryLoader(false));
	// 	}
	// } catch (e) {
	// 	dispatch({
	// 		type: GET_CATEGORY,
	// 		payload: [],
	// 	});
	// 	dispatch(getCategoryLoader(false));
	// }
	dispatch(getCategoryLoader(true));
	let { data } = await categoryRepository.getAllCategories();
	try {
		if (data.success) {
			dispatch({
				type: GET_CATEGORY,
				payload: data.data,
			});
			dispatch(getCategoryLoader(false));
		} else {
			dispatch(getCategoryLoader(false));
		}
	} catch (e) {
		dispatch({
			type: GET_CATEGORY,
			payload: [],
		});
		dispatch(getCategoryLoader(false));
	}
};
export const createCategory =
	(payload, onSuccess = () => {}) =>
	async dispatch => {
		dispatch(categoryLoader(true));
		try {
			let { data } = await categoryRepository.createCategory(payload);
			if (data.success) {
				alert('Category Added');
				dispatch(getAllCategories());
				dispatch(categoryLoader(false));
				onSuccess();
			} else {
				dispatch(categoryLoader(false));
			}
		} catch (e) {
			dispatch(categoryLoader(false));
		}
	};
export const updateCategory =
	(payload, onSuccess = () => {}) =>
	async dispatch => {
		dispatch(categoryLoader(true));
		try {
			let { data } = await categoryRepository.updateCategory(payload);
			if (data.success) {
				alert('Category Updated');
				dispatch(getAllCategories());
				dispatch(categoryLoader(false));
				onSuccess();
			} else {
				dispatch(categoryLoader(false));
			}
		} catch (e) {
			dispatch(categoryLoader(false));
		}
	};
export const deleteCategory =
	(payload, onSuccess = () => {}) =>
	async dispatch => {
		dispatch(categoryLoader(true));
		try {
			let { data } = await categoryRepository.deleteCategory(payload);
			if (data.success) {
				alert('Category Deleted');
				dispatch(categoryLoader(false));
				dispatch(getAllCategories());
				onSuccess();
			} else {
				dispatch(categoryLoader(false));
			}
		} catch (e) {
			dispatch(categoryLoader(false));
		}
	};
