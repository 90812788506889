import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Button} from "reactstrap";
// import { getBusinessSettings } from "store/actions/settingsAction";
import { updateAppColors } from "store/actions/settingsAction";

const AppColor = () => {
  const [updatedData, setUpdatedData] = useState("");
  let storeType = null;
  const dispatch = useDispatch();
  const { blId } = useSelector((state) => state.blId);
  const businessID = useSelector((state) => state?.settings.allSettings._id);
  const [currentData, setCurrentData] = useState();

  let pageLayoutSettings = useSelector(
    (state) => state?.settings?.allSettings?.page_layout
  );

  if (window.location.pathname === "/store/takeaway-delivery/settings") {
    storeType = "takeaway_delivery";
    // currentSettings = currentSettings?.takeaway_delivery;
  } else if (window.location.pathname === "/store/in-store/settings") {
    storeType = "instore";
    // currentSettings = currentSettings?.instore;
  }

  useEffect(() => {
    if (storeType === "instore")
      setCurrentData(pageLayoutSettings?.instore)
    else if (storeType === "takeaway_delivery")
      setCurrentData(pageLayoutSettings?.takeaway_delivery)
  }, [storeType, pageLayoutSettings])


  useEffect(() => {
    //initialize updatedData structure
    let obj = [
      {
        page: "welcome",
        updatedKeys: {},
      },
      {
        page: "menu",
        updatedKeys: {},
      },
      {
        page: "bill",
        updatedKeys: {},
      },
      {
        page: "cart",
        updatedKeys: {},
      },
      {
        page: "guest_wall",
        updatedKeys: {},
      },
      {
        page: "service",
        updatedKeys: {},
      },
      {
        page: "footer",
        updatedKeys: {},
      },
    ];
    setUpdatedData(obj);
  }, []);

  const handleSubmit = () => {
    const payload = {
      blID: blId,
      type: storeType.toString(),
      updated_section: [...updatedData],
    };
    dispatch(updateAppColors(payload, businessID));
    // dispatch(dispatch(getBusinessSettings({
    //   buid: blId,
    //   table_no: 20
    // })))
  };

  const handleChange = (page, keyVal) => {
    switch (page) {
      case "welcome": {
        let original = [...updatedData];
        let obj = { ...updatedData[0] };
        obj = { ...obj, updatedKeys: { ...obj.updatedKeys, ...keyVal } };
        original[0] = { ...obj };
        setUpdatedData(original);

        let section = { ...currentData[page] };
        section = { ...section, ...keyVal };
        let current = { ...currentData };
        current[page] = section;
        setCurrentData(current);
        break;
      }
      case "menu": {
        let original = [...updatedData];
        let obj = { ...updatedData[1] };
        obj = { ...obj, updatedKeys: { ...obj.updatedKeys, ...keyVal } };
        original[1] = { ...obj };
        setUpdatedData(original);

        let section = { ...currentData[page] };
        section = { ...section, ...keyVal };
        let current = { ...currentData };
        current[page] = section;
        setCurrentData(current);
        break;
      }
      case "bill": {
        let original = [...updatedData];
        let obj = { ...updatedData[2] };
        obj = { ...obj, updatedKeys: { ...obj.updatedKeys, ...keyVal } };
        original[2] = { ...obj };
        setUpdatedData(original);

        let section = { ...currentData[page] };
        section = { ...section, ...keyVal };
        let current = { ...currentData };
        current[page] = section;
        setCurrentData(current);
        break;
      }
      case "cart": {
        let original = [...updatedData];
        let obj = { ...updatedData[3] };
        obj = { ...obj, updatedKeys: { ...obj.updatedKeys, ...keyVal } };
        original[3] = { ...obj };
        setUpdatedData(original);

        let section = { ...currentData[page] };
        section = { ...section, ...keyVal };
        let current = { ...currentData };
        current[page] = section;
        setCurrentData(current);
        break;
      }
      case "guest_wall": {
        let original = [...updatedData];
        let obj = { ...updatedData[4] };
        obj = { ...obj, updatedKeys: { ...obj.updatedKeys, ...keyVal } };
        original[4] = { ...obj };
        setUpdatedData(original);

        let section = { ...currentData[page] };
        section = { ...section, ...keyVal };
        let current = { ...currentData };
        current[page] = section;
        setCurrentData(current);
        break;
      }
      case "service": {
        let original = [...updatedData];
        let obj = { ...updatedData[5] };
        obj = { ...obj, updatedKeys: { ...obj.updatedKeys, ...keyVal } };
        original[5] = { ...obj };
        setUpdatedData(original);

        let section = { ...currentData[page] };
        section = { ...section, ...keyVal };
        let current = { ...currentData };
        current[page] = section;
        setCurrentData(current);
        break;
      }
      case "footer": {
        let original = [...updatedData];
        let obj = { ...updatedData[6] };
        obj = { ...obj, updatedKeys: { ...obj.updatedKeys, ...keyVal } };
        original[6] = { ...obj };
        setUpdatedData(original);

        let section = { ...currentData[page] };
        section = { ...section, ...keyVal };
        let current = { ...currentData };
        current[page] = section;
        setCurrentData(current);
        break;
      }
      default: {
        console.log("invalid page");
        break;
      }
    }
  };

  return (
    <>
      <div className="shadow bg-white rounded mt-5 mb-2">
        <div className="wrapper p-3">
          <div className="title d-flex align-items-center">
            <img
              src="/suitcase.png"
              alt="clock"
              className="mr-3"
              height="20px"
            />

            <span>
              <h4 className="m-0 text-black">App Colors</h4>
            </span>
          </div>

          {/* Join Table Color */}
          <div className="heading-colors-group my-3">
            <h4 className="text-dark">Welcome</h4>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Create Table Background Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.welcome?.primary_create_table_btn_bgcolor}
                onChange={(e) => {
                  handleChange("welcome", {
                    primary_create_table_btn_bgcolor: e.target.value,
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Join Table Button Background Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.welcome?.primary_join_table_btn_bgcolor}
                onChange={(e) => {
                  handleChange("welcome", {
                    primary_join_table_btn_bgcolor: e.target.value,
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Create Table Button Text Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={
                  currentData?.welcome?.primary_create_table_btn_text_color
                }
                onChange={(e) => {
                  handleChange("welcome", {
                    primary_create_table_btn_text_color: e.target.value,
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Join Table Button Text Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.welcome?.primary_join_table_btn_text_color}
                onChange={(e) => {
                  handleChange("welcome", {
                    primary_join_table_btn_text_color: e.target.value,
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Primary Title Text Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.welcome?.primary_title_text_color}
                onChange={(e) => {
                  handleChange("welcome", {
                    primary_title_text_color: e.target.value,
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Secondary Title Text Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.welcome?.secondary_title_text_color}
                onChange={(e) => {
                  handleChange("welcome", {
                    secondary_title_text_color: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          {/* Menu Page Color */}
          <div className="heading-colors-group my-3">
            <h4 className="text-dark">Menu</h4>

            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Subheader Background Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.menu?.primary_menu_subheader_bgcolor}
                onChange={(e) => {
                  handleChange("menu", {
                    primary_menu_subheader_bgcolor: e.target.value,
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Search Button Text Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.menu?.primary_search_btn_text_color}
                onChange={(e) => {
                  handleChange("menu", {
                    primary_search_btn_text_color: e.target.value,
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Search Button Background Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.menu?.primary_search_btn_bgcolor}
                onChange={(e) => {
                  handleChange("menu", {
                    primary_search_btn_bgcolor: e.target.value,
                  });
                }}
              />
            </div>

            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Order Button Text Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.menu?.primary_order_btn_text_color}
                onChange={(e) => {
                  handleChange("menu", {
                    primary_order_btn_text_color: e.target.value,
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Order Button Background Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.menu?.primary_order_btn_bgcolor}
                onChange={(e) => {
                  handleChange("menu", {
                    primary_order_btn_bgcolor: e.target.value,
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Personalize Button Text Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.menu?.primary_personalize_btn_text_color}
                onChange={(e) => {
                  handleChange("menu", {
                    primary_personalize_btn_text_color: e.target.value,
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Personalize Button Background Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.menu?.primary_personalize_btn_bgcolor}
                onChange={(e) => {
                  handleChange("menu", {
                    primary_personalize_btn_bgcolor: e.target.value,
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Special Line Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.menu?.primary_line_summer_specials_color}
                onChange={(e) => {
                  handleChange("menu", {
                    primary_line_summer_specials_color: e.target.value,
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Best Seller Line Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.menu?.primary_line_best_seller_color}
                onChange={(e) => {
                  handleChange("menu", {
                    primary_line_best_seller_color: e.target.value,
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">Vegan Line Color</small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.menu?.primary_line_vegan_color}
                onChange={(e) => {
                  handleChange("menu", {
                    primary_line_vegan_color: e.target.value,
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                General Line Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.menu?.primary_line_general_color}
                onChange={(e) => {
                  handleChange("menu", {
                    primary_line_general_color: e.target.value,
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                General Title Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.menu?.primary_title_general_color}
                onChange={(e) => {
                  handleChange("menu", {
                    primary_title_general_color: e.target.value,
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Primary Menu Item Title Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.menu?.primary_menu_item_title_color}
                onChange={(e) => {
                  handleChange("menu", {
                    primary_menu_item_title_color: e.target.value,
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Primary Menu Item Price Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.menu?.primary_menu_item_price_color}
                onChange={(e) => {
                  handleChange("menu", {
                    primary_menu_item_price_color: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          {/* Bill Page Colors */}

          <div className="heading-colors-group my-3">
            <h4 className="text-dark">Bill Page</h4>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Pay All Button Text Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.bill?.primary_pay_all_btn_text_color}
                onChange={(e) => {
                  handleChange("bill", {
                    primary_pay_all_btn_text_color: e.target.value,
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Pay All Background Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.bill?.primary_pay_all_btn_bgcolor}
                onChange={(e) => {
                  handleChange("bill", {
                    primary_pay_all_btn_bgcolor: e.target.value,
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Pay Divide Button Text Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.bill?.primary_pay_divide_btn_text_color}
                onChange={(e) => {
                  handleChange("bill", {
                    primary_pay_divide_btn_text_color: e.target.value,
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Pay Divide Button Background Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.bill?.primary_pay_divide_btn_bgcolor}
                onChange={(e) => {
                  handleChange("bill", {
                    primary_pay_divide_btn_bgcolor: e.target.value,
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Pay Selected Button Background Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.bill?.primary_payselected_btn_bgcolor}
                onChange={(e) => {
                  handleChange("bill", {
                    primary_payselected_btn_bgcolor: e.target.value,
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Pay Selected Button Text Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.bill?.primary_pay_selected_btn_text_color}
                onChange={(e) => {
                  handleChange("bill", {
                    primary_pay_selected_btn_text_color: e.target.value,
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Empty Message Text Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.bill?.primary_empty_message_text_color}
                onChange={(e) => {
                  handleChange("bill", {
                    primary_empty_message_text_color: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          {/* Cart */}

          <div className="heading-colors-group my-3">
            <h4 className="text-dark">Cart</h4>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Course Item Text Title Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.cart?.primary_course_title_text_color}
                onChange={(e) => {
                  handleChange("cart", {
                    primary_course_title_text_color: e.target.value,
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Course Place Order Button Text Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.cart?.primary_place_order_btn_text_color}
                onChange={(e) => {
                  handleChange("cart", {
                    primary_place_order_btn_text_color: e.target.value,
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Course Place Order Button Background Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.cart?.primary_place_order_btn_bgcolor}
                onChange={(e) => {
                  handleChange("cart", {
                    primary_place_order_btn_bgcolor: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          {/* Wall of fame */}

          <div className="heading-colors-group my-3">
            <h4 className="text-dark">Wall of Fame</h4>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Guest Book Entry Title Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={
                  currentData?.guest_wall?.primary_guestbook_entry_text_color
                }
                onChange={(e) => {
                  handleChange("guest_wall", {
                    primary_guestbook_entry_text_color: e.target.value,
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Wall of Fame Title Text Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={
                  currentData?.guest_wall?.primary_wall_of_fame_title_text_color
                }
                onChange={(e) => {
                  handleChange("guest_wall", {
                    primary_wall_of_fame_title_text_color: e.target.value,
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Tick Button Background Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.guest_wall?.primary_tick_btn_bgcolor}
                onChange={(e) => {
                  handleChange("guest_wall", {
                    primary_tick_btn_bgcolor: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          {/* Service  */}
          <div className="heading-colors-group my-3">
            <h4 className="text-dark">Service</h4>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Service Title Text Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.service?.primary_service_title_color}
                onChange={(e) => {
                  handleChange("service", {
                    primary_service_title_color: e.target.value,
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Help Title Text Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.service?.primary_help_title_color}
                onChange={(e) => {
                  handleChange("service", {
                    primary_help_title_color: e.target.value,
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Confirm Button Text Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.service?.primary_confirm_btn_text_color}
                onChange={(e) => {
                  handleChange("service", {
                    primary_confirm_btn_text_color: e.target.value,
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Confirm Button Background Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.service?.primary_confirm_btn_bgcolor}
                onChange={(e) => {
                  handleChange("service", {
                    primary_confirm_btn_bgcolor: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="heading-colors-group my-3">
            <h4 className="text-dark">Footer</h4>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Footer Title Text Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.footer?.primary_text_color}
                onChange={(e) => {
                  handleChange("footer", {
                    primary_text_color: e.target.value,
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <small className="font-weight-semi-bold">
                Footer Background Color
              </small>
              <input
                type="color"
                className="ml-auto custom-color-input"
                value={currentData?.footer?.primary_bgcolor}
                onChange={(e) => {
                  handleChange("footer", { primary_bgcolor: e.target.value });
                }}
              />
            </div>
          </div>

          {/* {colors.map((color) => (
                        <div className="d-flex align-items-center mt-2">
                          <small className="font-weight-semi-bold">
                            {color.name}
                          </small>
                          <input
                            type="color"
                            value={color.color}
                            className="ml-auto custom-color-input"
                          ></input>
                        </div>
                      ))} */}
          <div className="d-flex mt-3">
            <Button
              color="primary"
              className="text-uppercase px-4"
              onClick={handleSubmit}
            >
              Save
            </Button>
            {/* <Button
                          color="secondary"
                          className="text-uppercase px-4"
                        >
                          Reset
                        </Button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AppColor;
