import Repository from "./Repository";
const FLOOR_PLANS = "/floor-plans";
const SESSIONS = "/table-sessions";
export default {
  getAllFloorplans(payload) {
    return Repository.get(`${FLOOR_PLANS}/${payload}`);
  },
  getAllSessions(payload) {
    return Repository.get(`${SESSIONS}/${payload}`);
  },
  removeSession(payload) {
    return Repository.delete(`${SESSIONS}/${payload}`);
  },
};
