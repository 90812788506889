import { GET_MENU } from "store/types";
import { GET_SINGLE_MENU_ITEMS } from "store/types";
import { GET_MENU_LOADER } from "store/types";
import { MENU_LOADER } from "store/types";

let initState = {
  menuAddLoader: false,
  getMenuLoader: false,
  getAlleArtikelenLoader: false,
  menus: [],
  menuItems: [],
  takeAwaymenus: [],
  kioskMenus: [],
  alleArtikelen: [],
};
export default function (state = initState, action) {
  switch (action.type) {
    case GET_MENU:
      return {
        ...state,
        menus: action.payload,
      };
    case "GET_ALLE_ARTIKELENA":
      return {
        ...state,
        alleArtikelen: action.payload,
      };
    case "GET_TAKE_AWAY_MENU":
      return {
        ...state,
        takeAwaymenus: action.payload,
      };
    case "GET_KIOSK_MENU":
      return {
        ...state,
        kioskMenus: action.payload,
      };
    case GET_SINGLE_MENU_ITEMS:
      return {
        ...state,
        menuItems: action.payload,
      };
    case "GET_SINGLE_MENU_ITEMS_FAILED":
      return {
        ...state,
        menuItems: [],
      };
    case MENU_LOADER:
      return {
        ...state,
        menuAddLoader: action.payload,
      };
    case GET_MENU_LOADER:
      return {
        ...state,
        getMenuLoader: action.payload,
      };
    case "GET_ALLE_ARTIKELENA_LOADER":
      return {
        ...state,
        getAlleArtikelenLoader: action.payload,
      };
    default:
      return { ...state };
  }
}
