import PageTitle from 'components/PageTitle';
import ReportCard from 'components/ReportCard';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
	Button,
	Card,
	Spinner,
	Col,
	Form,
	FormGroup,
	Input,
	Label,
	Row,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getStoreLogo } from 'store/actions/storeSettingAction';
import { addStoreLogo } from 'store/actions/storeSettingAction';
import vapino from '../../assets/img/logo.png';
const Settings = () => {
	const dispatch = useDispatch();
	const { logo, isload } = useSelector((state) => state.store_setting);
	const { blId } = useSelector((state) => state.blId);
	useEffect(() => {
		dispatch(getStoreLogo(blId));
	}, []);
	console.log('logo', logo);

	const handleNewLogoImage = (imageData) => {
		let obj = {
			blId: blId,
			file: imageData,
		};
		if (imageData !== '') {
			dispatch(addStoreLogo(obj));
		}
		// addStoreLogo(imageData);
	};
	return (
		<>
			<Row className='p-3'>
				<Col md='6' sm='12' xs='12' lg='6'>
					<div className='card border border-light rounded  p-2 '>
						<PageTitle
							icon='/img/sidebar/store.png'
							title='Store Settings'
						/>
					</div>
				</Col>

				<Col md='6' sm='12' xs='12' lg='6'>
					<Button
						tag={Link}
						to='/admin/stores/summary'
						color='primary'
						className='mt-3 ml-auto mt-sm-0 float-right'
					>
						View List
						<i className='ml-2 fa fa-list'></i>
					</Button>
				</Col>
				<Col md='12' sm='12' xs='12' lg='12'>
					<ReportCard headerTitle={'Store Income'} />
				</Col>

				<Col md='6' className='mb-4 mb-xl-0 mt-2'>
					<div className=' card p-3 border border-light rounded h-100'>
						<FormGroup check>
							<Label check>
								<Input type='checkbox' />
								Take Away
							</Label>
						</FormGroup>
						<FormGroup check className='mt-4'>
							<Label check>
								<Input type='checkbox' />
								Delivery
							</Label>
						</FormGroup>
						<FormGroup check className='mt-4'>
							<Label check>
								<Input type='checkbox' />
								InStore
							</Label>
						</FormGroup>
						<div className='d-flex mt-3'>
							<Button className='ml-auto' color='primary'>
								Save
							</Button>
						</div>
					</div>
				</Col>
				<Col md='6' className='mb-4 mb-xl-0 mt-2 '>
					<div className='card p-3 border border-light rounded h-100'>
						<div className='d-flex'>
							<label
								for='image'
								className='ml-auto cursor-pointer'
							>
								{isload == true ? (
									<Spinner color='primary' />
								) : (
									<span>
										<i className='fa fa-cog'></i>
									</span>
								)}
								<input
									type='file'
									className='d-none'
									id='image'
									name='image'
									onChange={(event) =>
										handleNewLogoImage(
											event.target.files[0]
										)
									}
								/>
							</label>
						</div>
						<img
							className='image-preview'
							src={logo.logo}
							alt='logo not found'
						/>
						<div className='d-flex mt-3'>
							{/* <Button className='ml-auto' color='primary'>
								{isload == true ? <Spinner /> : 'Save'}
							</Button> */}
						</div>
					</div>
				</Col>
			</Row>

			{/* <div className='d-flex p-4'>
				<h2>OPEN SESSIONS</h2>
			</div>
			<div className='d-flex pb-4'>
				<b>Copyright © 2022 Company. All rights reserved.</b>
			</div> */}
		</>
	);
};

export default Settings;
