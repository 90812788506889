let initState = {
	getAllCourses: false,
	courses: [],
};
export default function (state = initState, action) {
	switch (action.type) {
		case 'GET_COURSE':
			return {
				...state,
				courses: action.payload,
			};
		case 'GET_COURSE_LOADER':
			return {
				...state,
				getAllCourses: action.payload,
			};
		default:
			return { ...state };
	}
}
