
import { TablePagination } from '@mui/material';
import PageTitle from 'components/PageTitle';
import React from 'react';
import { Col, Row, Table } from 'reactstrap';

const PostalCode = ()=>{
    const value="Vapiano Düsseldorf Medienhafen"
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
     const PostalData=[
         {
             postalCode: "10963",
             amountWithExtra:"10",
             extraAmount:"20",
             minAmount : "2€",
             distance :"0.5km",
             active:"true"
         },
         {
            postalCode: "10963",
            amountWithExtra:"90",
            extraAmount:"70",
            minAmount : "2€",
            distance :"90km",
            active:"true"

        },
        {
            postalCode: "10961",
            amountWithExtra:"22",
            extraAmount:"30",
            minAmount : "2€",
            distance :"120km",
            active:"true"

        },
        {
            postalCode: "10967",
            amountWithExtra:"50",
            extraAmount:"40",
            minAmount : "2€",
            distance :"0.5km",
            active:"true"

        },
        {
            postalCode: "10999",
            amountWithExtra:"30",
            extraAmount:"10",
            minAmount : "2€",
            distance :"6km",
            active:"true"

        }
     ]
    return(
        <>
        <Row className='p-3'>
            <Col xs="12" sm="6" md="6" lg="6">
            <div className='card p-2 border border-light rounded'>
                        <PageTitle title={`Postal Codes - ${value}`} icon=""/>
                        </div>
                        </Col>
<Col xs="12" sm="12" md="8" lg="8" >
    <div className='card mt-2 border border-light rounded'>
         <div className='p-3'>
             <h3 className=' text-muted'>Delivery Postal Codes</h3>
         </div>
         <div className='p-2'>
         <Table responsive>
             <thead>
                 <tr>
                     <th>Postal Codes</th>
                     <th>Amount With Extra</th>
                     <th>Extra Amount</th>
                     <th>Minimum Amount</th>
                     <th>distance</th>
                     <th>Active</th>
                 </tr>
             </thead>
             <tbody>
                
                     {
                         PostalData.map((p, index)=>{
                             return(
                                <tr>
                                 <td>{p.postalCode}</td>
                                 <td>{p.amountWithExtra}</td>
                                 <td>{p.extraAmount}</td>
                                 <td>{p.minAmount}</td>
                                 <td>{p.distance}</td>
                                 <td>{p.active}</td>
                              
                                 </tr>
                             )
                         })
                     }
                 
             </tbody>
         </Table>
         </div>
         < div className='mt-2'>
         <TablePagination
  component="div"
  count={100}
  page={page}
  rowsPerPage={rowsPerPage}
 
/>
</div>
    </div>
</Col>
<Col xs="12" sm="12" md="4" lg="4" >  
</Col>
        </Row>
        </>
    )
}


export default PostalCode;