import { RepositoryFactory } from 'repository/RepositoryFactory';
let StoreSettingRepository = RepositoryFactory.get('storesetting');
export const getStoreLogo = (payload) => async (dispatch) => {
	try {
		console.log('payload', payload);
		let { data } = await StoreSettingRepository.getStoreLogo(payload);
		console.log('data', data);
		if (data.success) {
			dispatch({
				type: 'GET_STORE_LOGO',
				payload: data.data,
			});
		}
	} catch (err) {
		console.log('error', err.message);
	}
};
export const addStoreLogo = (payload) => async (dispatch) => {
	try {
		dispatch({
			type: 'LOADER_CHANGE',
			payload: true,
		});
		let { data } = await StoreSettingRepository.addStoreLogo(payload);
		if (data.success) {
			dispatch({
				type: 'ADD_STORE_LOGO',
				payload: data.data,
			});
			dispatch({
				type: 'LOADER_CHANGE',
				payload: false,
			});
		}
	} catch (e) {
		console.log(e);
		dispatch({
			type: 'LOADER_CHANGE',
			payload: false,
		});
	}
};
