import React from 'react';
import MenuSection from 'components/MenuSection';
import { Row, Col } from 'reactstrap';
import PageTitle from 'components/PageTitle';
const SubgroupsArticles = () => {
	return (
		<>
		<Row>
			<Col xs="12" sm="12" md="6" lg="6">
			<div className='card border border-light rounded p-2'>
				<PageTitle
					title='Subgroups Articles'
					icon='/img/sidebar/object.png'
				/>
			</div>
			</Col>
			<Col xs="12" sm="12" md="6" lg="6">

			<div className='mt-3'>
				
					<Col md='6'>{/* <MenuSection title='INHOUSE' /> */}</Col>
					<Col md='6'>
						{/* <MenuSection title='Alle Artikelen' /> */}
					</Col>
			</div>
			</Col>
			</Row>

		</>
	);
};

export default SubgroupsArticles;
