import PageTitle from 'components/PageTitle';
import React from 'react';
import { Col, Row } from 'reactstrap';

const Marketing =()=>{
    return (
        <>
<Row className="p-3">
    <Col lg="6" md="6" sm="12" xs="12">
    <div className='card p-2 border border-light rounded'>
    <PageTitle title="Marketing Email Exports"/>
    </div>
    </Col>
    <Col lg="12" md="12" sm="12" xs="12">
        <div className='mt-2 p-2'>
            <button className='btn btn-primary'>EXPORTS EMAIL LIST</button>
        </div>
    </Col>
</Row>

        </>
    )
}

export default Marketing;