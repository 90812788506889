import { SET_ARTICLES } from "../types";

const initialState = {
  articles: [],
  loading: false,
  loading2: false,
  image: {},
};

export default function articleReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_ARTICLES: {
      return {
        ...state,
        articles: payload,
      };
    }
    case "SET_ARTICLE_LOADING":
      return {
        ...state,
        loading: payload,
      };
    case "SET_ARTICLE_LOADING_2":
      return {
        ...state,
        loading2: payload,
      };
    case "SET_IMAGE":
      return {
        ...state,
        image: payload
      }
    default:
      return {
        ...state,
      };
  }
}
