import React from 'react';
const NavHeader = ({ icon, text }) => {
	return (
		<>
			<div className='nav-item main'>
				<div className='nav-link'>
					<img
						src={icon}
						className='mb-1'
						alt='icons'
						height='20px '
					/>
					<span className='ml-2' style={{ fontSize: '16px' }}>
						{text}
					</span>
				</div>
			</div>
		</>
	);
};

export default NavHeader;
