import PageTitle from "components/PageTitle";
import SettingsModal from "components/SettingsModal";
import React, { useEffect, useState } from "react";
import { Col, Row, Button, Spinner } from "reactstrap";
import { useLocation } from "react-router-dom";
import AppColor from "./settings/appcolor";
import AppFontSize from "./settings/appFont";
import { useDispatch, useSelector } from "react-redux";
import { generalSettingAction } from "store/actions/settingsAction";
import { Switch } from "@mui/material";
import { getBusinessSettings } from "store/actions/settingsAction";
import AppIconUpload from "./settings/AppIconUpload";

const Settings = () => {
  const businessID = localStorage.getItem("blId");
  const loading = useSelector((state) => state?.settings?.loading);
  const allsetting = useSelector((state) => state?.settings?.allSettings);

  let location = useLocation();
  const dispatch = useDispatch();
  const [genSettingObj, SetGenSettings] = useState({
    blId: businessID,
    animation: false,
    special: false,
    vegan: false,
    best_seller: false,
    business_name: "",
    body_bgcolor: "",
  });

  useEffect(() => {
    dispatch(
      getBusinessSettings({
        buid: businessID,
        table_no: businessID === 660076343853236 ? 20 : null, //no table_no for Boname
      })
    );
  }, []);

  useEffect(() => {
    SetGenSettings({
      blId: businessID,
      animation: allsetting?.animation,
      special: allsetting?.special,
      vegan: allsetting?.vegan,
      best_seller: allsetting?.best_seller,
      business_name: allsetting?.business_name,
      body_bgcolor: allsetting?.body_bgcolor,
    });
  }, [allsetting]);

  const data = [
    {
      day: "Monday",
      date1: "9:00/15:00",
      date2: "18:00/23:30",
      icon: <img src="/pen.png" alt="pen" height="10px" />,
    },
    {
      day: "Tuesday",
      date1: "9:00/15:00",
      date2: "18:00/23:30",
      icon: <img src="/pen.png" alt="pen" height="10px" />,
    },
    {
      day: "Wednesday",
      date1: "9:00/15:00",
      date2: "18:00/23:30",
      icon: <img src="/pen.png" alt="pen" height="10px" />,
    },
    {
      day: "Thursday",
      date1: "9:00/15:00",
      date2: "18:00/23:30",
      icon: <img src="/pen.png" alt="pen" height="10px" />,
    },
    {
      day: "Friday",
      date1: "9:00/15:00",
      date2: "18:00/23:30",
      icon: <img src="/pen.png" alt="pen" height="10px" />,
    },
    {
      day: "Saturday",
      date1: "9:00/15:00",
      date2: "18:00/23:30",
      icon: <img src="/pen.png" alt="pen" height="10px" />,
    },
  ];

  const [show, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const ShowModal = () => setShowModal(true);

  const generalSaveFunc = () => {
    dispatch(
      generalSettingAction({
        blId: businessID,
        animation: genSettingObj.animation,
        special: genSettingObj.special,
        vegan: genSettingObj.vegan,
        best_seller: genSettingObj.best_seller,
        business_name: genSettingObj.business_name,
        body_bgcolor: genSettingObj.body_bgcolor,
      })
    );
  };
  const handleChange = (evt) => {
    SetGenSettings({
      ...genSettingObj,
      [evt.target.name]: evt.target.checked,
    });
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner animation="grow" />
      </div>
    );
  }
  return (
    <Row className="p-3">
      <Col xs="12" md="6" sm="12" lg="6">
        <div className=" card border border-light rounded  head p-2 ">
          <PageTitle icon={"/img/sidebar/setting.png"} title="Settings" />
        </div>
      </Col>
      <Col xs="12" md="12" sm="12" lg="12">
        <div className="card p-2 border-light rounded border-bottom mt-2">
          <div className="card p-3 mt-3 ">
            <Row className="mt-3 ">
              <Col lg="6" md="12" sm="12" xs="12">
                <div className="shadow bg-white rounded h-100 ">
                  <div className="d-flex flex-row p-3 align-items-center">
                    <i className="fa fa-clock fa-lg mr-3"></i>
                    <span>
                      <h4 className="mb-0 text-black">
                        Opening hours{" "}
                        {location.pathname ===
                          "/store/takeaway-delivery/settings"
                          ? "Take Away"
                          : ""}
                      </h4>
                    </span>
                  </div>
                  <div className="px-5 pb-3">
                    {data.map((d, i) => {
                      return (
                        <div className="d-grid justify-content-between mb-2">
                          <div className="font-weight-semi-bold">{d.day}</div>
                          <div className="font-weight-semi-bold">{d.date1}</div>
                          <div className="font-weight-semi-bold">{d.date2}</div>
                          <div className="text-right">
                            <i className="fa fa-edit" onClick={ShowModal}></i>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Col>
              {location.pathname === "/store/in-store/settings" ? (
                //   <Col lg="6" md="12" sm="12" xs="12">
                //     <div className="shadow bg-white rounded h-100 ">
                //       <div className="d-flex flex-row p-3 align-items-center">
                //         <i className="fa fa-clock fa-lg mr-3"></i>
                //         <span>
                //           <h4 className="mb-0 text-black">
                //             Opening hours Delivery
                //           </h4>
                //         </span>
                //       </div>
                //       <div className="px-5 pb-3">
                //         {data.map((d, i) => {
                //           return (
                //             <div className="d-grid justify-content-between mb-2">
                //               <div className="font-weight-semi-bold">{d.day}</div>
                //               <div className="font-weight-semi-bold">
                //                 {d.date1}
                //               </div>
                //               <div className="font-weight-semi-bold">
                //                 {d.date2}
                //               </div>
                //               <div className="text-right">
                //                 <i className="fa fa-edit" onClick={ShowModal}></i>
                //               </div>
                //             </div>
                //           );
                //         })}
                //       </div>
                //     </div>
                //   </Col>
                // ) : (
                <Col lg="6" md="12" sm="12" xs="12" className="mt-5 mt-lg-0">
                  <div className="shadow bg-white rounded pb-2 h-100">
                    <div className="d-flex flex-row align-items-center p-3">
                      <img
                        src="/spanner.png"
                        alt="spanner"
                        className="mr-3"
                        height="20px"
                      />
                      <span>
                        <h4 className="mb-0 text-black">General</h4>
                      </span>
                    </div>
                    <div className="d-flex px-3">
                      <p className="flex-shrink-0 font-weight-semi-bold">
                        Store name
                      </p>
                      <input
                        onChange={(e) => [
                          SetGenSettings({
                            ...genSettingObj,
                            business_name: e.target.value,
                          }),
                        ]}
                        value={genSettingObj.business_name}
                        type="text"
                        className="ml-4 flex-grow-0 border-top-0 border-left-0 border-right-0 pb-0 mb-4 ml-2 w-100"
                      />
                    </div>
                    <div className="d-flex justify-content-between px-3">
                      <p className="flex-shrink-0 font-weight-semi-bold">
                        Animation
                      </p>
                      <div className="d-flex justify-content-between mt-2">
                        <Switch
                          checked={genSettingObj.animation}
                          name="animation"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                      </div>
                    </div>
                    {/* Best Seller */}

                    <div className="d-flex justify-content-between px-3">
                      <p className="flex-shrink-0 font-weight-semi-bold">
                        Best Seller
                      </p>
                      <div className="d-flex justify-content-between mt-2">
                        <Switch
                          name="best_seller"
                          checked={genSettingObj.best_seller}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                      </div>
                    </div>
                    {/* Summer Special */}

                    <div className="d-flex justify-content-between px-3">
                      <p className="flex-shrink-0 font-weight-semi-bold">
                        Special
                      </p>
                      <div className="d-flex justify-content-between mt-2">
                        <Switch
                          name="special"
                          checked={genSettingObj.special}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                      </div>
                    </div>
                    {/* Vegan */}

                    <div className="d-flex justify-content-between px-3">
                      <p className="flex-shrink-0 font-weight-semi-bold">
                        Vegan
                      </p>
                      <div className="d-flex justify-content-between mt-2">
                        <Switch
                          name="vegan"
                          checked={genSettingObj.vegan}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                      </div>
                    </div>

                    <div className="d-flex px-3">
                      <p className="flex-shrink-0 font-weight-semi-bold">
                        Body Color
                      </p>
                      <input
                        onChange={(e) => [
                          SetGenSettings({
                            ...genSettingObj,
                            body_bgcolor: e.target.value,
                          }),
                        ]}
                        value={genSettingObj.body_bgcolor}
                        type="color"
                        className="ml-auto custom-color-input mr-3"
                      />
                    </div>

                    <div className="d-flex justify-content-end px-3 my-2 ">
                      <Button
                        color="primary"
                        className="text-uppercase px-4"
                        onClick={() => {
                          generalSaveFunc();
                        }}
                      >
                        Save
                      </Button>
                      {/* <Button color="secondary" className="text-uppercase px-4">
                        Reset
                      </Button> */}
                    </div>
                  </div>
                </Col>
              ) : null}
            </Row>
            {location.pathname === "/store/takeaway-delivery/settings" && (
              <Row className="mt-3 ">
                <Col lg="6" md="12" sm="12" xs="12">
                  <div className="shadow bg-white rounded h-100 ">
                    <div className="d-flex flex-row p-3 align-items-center">
                      <i className="fa fa-clock fa-lg mr-3"></i>
                      <span>
                        <h4 className="mb-0 text-black">
                          Opening hours Delivery
                        </h4>
                      </span>
                    </div>
                    <div className="px-5 pb-3">
                      {data.map((d, i) => {
                        return (
                          <div className="d-grid justify-content-between mb-2">
                            <div className="font-weight-semi-bold">{d.day}</div>
                            <div className="font-weight-semi-bold">
                              {d.date1}
                            </div>
                            <div className="font-weight-semi-bold">
                              {d.date2}
                            </div>
                            <div className="text-right">
                              <i className="fa fa-edit" onClick={ShowModal}></i>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Col>
              </Row>
            )}
          </div>

          <Row>
            {/* 3rd Chunk */}
            <Col sm="12" xs="12" md="6" lg="6">
              <AppColor />
            </Col>
            <Col sm="12" xs="12" md="6" lg="6">
              <AppFontSize />
            </Col>
          </Row>

          <Row className="pb-4">
            {/* 4th section */}

            <AppIconUpload businessID={businessID} />
          </Row>
          <SettingsModal show={show} handleClose={handleClose} />
        </div>
      </Col>
    </Row>
  );
};

export default Settings;
