import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Droppable } from "react-beautiful-dnd";
import { Draggable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { DragDropContext } from "react-beautiful-dnd";
import { Collapse, CustomInput, Table } from "reactstrap";
import ProductMain from "../assets/img/PlatenKnife.png";
import ArticalModal from "./ArticalModal";
import { getDetailsByArticle } from "store/actions/article_detailsAction";
import { changeItemStatusAct } from "store/actions/articleAction";
import LoadingOverlay from "react-loading-overlay";
import { Spinner } from "reactstrap";
import { changeGroupImageAct } from "store/actions/articleAction";
import { getGroupImage } from "store/actions/articleAction";

const ArticleItem = React.forwardRef(
  (
    {
      article,
      isActive,
      toggleActive,
      showModal,
      setShowModal,
      title,
      single,
      group,
      setGrp,
      grp,
      setSingle,
      setGroup,
      ...props
    },
    ref
  ) => {
    // const [showModal, setShowModal] = useState('');
    // const [grp, setGrp] = useState([]);
    // const handleClose = () => setShowModal(false);
    // const [single, setSingle] = useState({});
    const { articles } = useSelector((state) => state?.articles);

    const getListStyle = (isDraggingOver) => ({
      width: "100%",
    });
    const fileInput = useRef(null);
    const dispatch = useDispatch();
    useEffect(async () => {
      await setGrp(article.items);
    }, [article.items]);
    // console.log('ARTICEL ITEM', article);

    const { blId } = useSelector((state) => state.blId);
    const { loading, loading2 } = useSelector((state) => state.articles);
    const { image } = useSelector((state) => state.articles);

    let [groupId, setGroupId] = useState("");
    const onPickFile = (e) => {
      setGroupId(article?.groupImage);
      e.preventDefault();
      fileInput.current.click();
    };

    let handleGroupImage = (img) => {
      let obj = {
        id: article?.id,
        blId: blId,
        group: article?.group,
        image: img,
      };
      if (img != "") dispatch(changeGroupImageAct(obj));
      // setGroupId("")
    };

    const onFilePicked = async (event) => {
      handleGroupImage(event.target.files[0]);
      // setGroupId('')
    };

    let [articleId, setArticleId] = useState("");
    let [articleId2, setArticleId2] = useState("");
    // console.log(groupId)

    return (
      <div ref={ref} className="article" {...props}>
        <div className="d-flex align-items-center p-3" onClick={toggleActive}>
          <span className="mr-3 cursor-pointer">
            <i className="fa fa-grip-lines"></i>
          </span>
          <div className="d-flex flex-column">
            {loading2 && groupId === article?.groupImage ? (
              <Spinner size="sm" color="primary" />
            ) : (
              <img src={article?.groupImage} alt="" height={75} width={75} />
            )}
          </div>
          <div className="d-flex flex-column ml-3 group-name">
            <small className="text-xs">Group name</small>
            <h6 className="h6 mb-0">{article?.group}</h6>
          </div>

          <i className="ml-auto">
            {isActive ? (
              <i
                // onClick={toggleActive}
                className="cursor-pointer fa fa-angle-up"
              ></i>
            ) : (
              <i
                // onClick={toggleActive}
                className="cursor-pointer fa fa-angle-down"
              ></i>
            )}
          </i>
          <div className="d-flex my-2 mt-2">
            {/* <form onSubmit={onSubmitHandler}> */}
            <input
              // onChange={(event) =>
              //   handleNewNewsImage(event.target.files[0])
              // }
              onChange={onFilePicked}
              type="file"
              ref={fileInput}
              accept="image/png, image/gif, image/jpeg, image/jpg"
              style={{
                width: "100%",
                height: "40%",
                opacity: 0,
                position: "absolute",
                top: "0px",
                left: "0px",
                display: "none",
              }}
            ></input>
            <i
              onClick={onPickFile}
              className="fas fa-plus-circle ml-3 cursor-pointer text-lg"
            ></i>
            {/* </form> */}
          </div>
        </div>
        <Collapse isOpen={isActive}>
          {/* <LoadingOverlay active={loading2} spinner text="Loading...."> */}
          <Table responsive borderless className="article__table mb-0">
            <DragDropContext>
              <Droppable droppableId="droppableArticleItems">
                {(provided, snapshot) => (
                  <tbody ref={provided.innerRef}>
                    {article?.items.map((item, k) => (
                      <Draggable key={k} draggableId={k.toString()} index={k}>
                        {(provided, snapshot) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="w-100"
                          >
                            <td>
                              <span className="cursor-pointer text-md">
                                <i className="fa fa-grip-lines"></i>
                              </span>
                            </td>
                            <td>
                              {loading2 && articleId2 == item?.sku ? (
                                <Spinner
                                  className="d-flex mx-auto"
                                  size="sm"
                                  color="primary"
                                />
                              ) : (
                                <>
                                  {item?.image ? (
                                    <img
                                      className="article__img"
                                      alt="des"
                                      src={item?.image}
                                    />
                                  ) : (
                                    <img
                                      className="article__img"
                                      alt="des"
                                      src={ProductMain}
                                    />
                                  )}
                                </>
                              )}
                            </td>
                            <td>
                              <span className="mb-0 group-name group-name--subitem d-flex w-100">
                                {item?.productName}
                              </span>
                            </td>
                            <td>Prices</td>
                            <td>
                              <span className="mb-0 group-name group-name--subitem d-flex w-100">
                                {/* {item.prices
																	.length > 0
																	? item
																			.prices[0]
																			.amount
																	: 0}{' '} */}
                                {item.productPrice ? item.productPrice : "0.0"}{" "}
                                €
                              </span>
                            </td>
                            {!window.location.href.includes("admin") && (
                              <td>
                                {loading2 && articleId == item?.sku ? (
                                  <Spinner size="sm" />
                                ) : (
                                  <CustomInput
                                    checked={item.isItem}
                                    type="switch"
                                    id={item?.sku}
                                    onClick={(e) => {
                                      setArticleId(item?.sku);
                                      let obj = {
                                        article_id: item?.sku,
                                        blId: blId,
                                        isItem: e.target.checked,
                                      };
                                      dispatch(changeItemStatusAct(obj));
                                    }}
                                  />
                                )}
                              </td>
                            )}
                            <td>
                              <button
                                className="btn btn-light"
                                type="button"
                                onClick={() => {
                                  setGroup(article?.group);
                                  setSingle(item);
                                  setShowModal(item.sku);
                                  setArticleId2(item.sku);
                                }}
                              >
                                <i className="fa fa-cog"></i>
                              </button>
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
          {/* </LoadingOverlay> */}
        </Collapse>
        {/* <ArticalModal
					showModal={showModal}
					setShowModal={setShowModal}
					title={'Edit Article'}
					// handleClose={handleClose}
					single={single}
					group={article?.group}
					setGrp={setGrp}
					grp={grp}
				/> */}
      </div>
    );
  }
);

export default ArticleItem;
