import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Button,
	Form,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Spinner,
} from 'reactstrap';
import { updateCategory } from 'store/actions/categoryActions';
import { createCategory } from 'store/actions/categoryActions';

const CategoryModal = props => {
	const [categoryName, setCategoryName] = useState('');
	const [categoryFile, setCategoryFile] = useState([]);
	const dispatch = useDispatch();
	const { catLoader } = useSelector(state => state.category);
	return (
		<>
			<Modal centered isOpen={props.show} toggle={props.handleClose}>
				<ModalHeader>{props.title}</ModalHeader>
				<Form
					onSubmit={e => {
						e.preventDefault();
						let obj;
						if (props.btnTitle == 'Add') {
							obj = {
								title: categoryName,
								image: categoryFile,
							};
							dispatch(
								createCategory(obj, () => {
									setCategoryName('');
									setCategoryFile([]);
									props.handleClose();
								})
							);
						} else {
							obj = {
								title: categoryName,
								image: categoryFile,
								id: props.singleval._id,
							};
							dispatch(
								updateCategory(obj, () => {
									setCategoryName('');
									setCategoryFile([]);
									props.handleClose();
								})
							);
						}
					}}
				>
					<ModalBody>
						<div>
							<Label>Name</Label>
							<Input
								type='text'
								className='form-control w-75'
								placeholder={props.title + ' Name'}
								required
								defaultValue={
									props.btnTitle != 'Add'
										? props.singleval &&
										  props.singleval.title
										: categoryName
								}
								onChange={e => setCategoryName(e.target.value)}
							/>
							{props.btnTitle != 'Add' && (
								<>
									<img
										className='mt-3'
										src={
											props.singleval &&
											props.singleval.icon
										}
										alt='imag'
										height='100'
										width='100'
									/>
									<br />
								</>
							)}
							<Label className='mt-3'>Category Image</Label>
							<Input
								type='file'
								required={props.btnTitle == 'Add'}
								onChange={e =>
									setCategoryFile(e.target.files[0])
								}
							/>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button
							className='mr-3'
							color='danger'
							disabled={catLoader}
							onClick={props.handleClose}
						>
							Close
						</Button>
						{props.btnTitle == 'Add' ? (
							<Button
								disabled={catLoader}
								color='primary'
								type='submit'
							>
								{catLoader ? <Spinner size='sm' /> : 'Add'}
							</Button>
						) : (
							<Button
								disabled={catLoader}
								color='primary'
								type='submit'
							>
								{catLoader ? <Spinner size='sm' /> : 'Edit'}
							</Button>
						)}
					</ModalFooter>
				</Form>
			</Modal>
		</>
	);
};

export default CategoryModal;
