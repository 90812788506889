import React, { useState, useRef } from "react";
// import food from 'assets/img/download.jpg';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Input,
  Label,
  FormGroup,
  Spinner,
} from "reactstrap";
// import PromotionCard from 'components/PromotionCard';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  addPopUp,
  getHeader,
  addHeader,
  deleteHeader,
  addNews,
  getNews,
  deleteNews,
  updateHeaderURL,
  updateNewsURL,
  getPopUp,
  updatePopUpURL,
} from "store/actions/promotionActions";
import { articleAction } from "store/actions/articleAction";
import {
  getBestSellers,
  removeBestSeller,
  addBestSeller,
} from "store/actions/bestSellerAction";

import ToggleSwitch from "./helper/ToggleSwitch";

import "./PromotionSection.css";
import { getSpecialSellers } from "store/actions/specialSellerAction";
import { addSpecialSeller } from "store/actions/specialSellerAction";
import { removeSpecialSeller } from "store/actions/specialSellerAction";
import { addImagePopUp } from "store/actions/promotionActions";
const PromotionSection = (props) => {
  const dispatch = useDispatch();
  const { popup } = useSelector((state) => state.promotion);
  const { addpopup } = useSelector((state) => state.promotion);
  const { header } = useSelector((state) => state.promotion);
  const { news } = useSelector((state) => state.promotion);
  const { articles } = useSelector((state) => state?.articles);
  const { blId } = useSelector((state) => state.blId);
  const { bestSeller } = useSelector((state) => state.bestseller);
  const { specialSeller } = useSelector((state) => state.specialseller);
  const { loader_popup, loader_news, loader_header } = useSelector(
    (state) => state.promotion
  );
  const [PopupImage, setPopupImage] = useState("");
  const [check, setChecked] = useState(true);
  const [viewURLModal, setViewURLModal] = useState(false);
  const [updateURL, setUpdateURL] = useState("");
  const [updateType, setUpdateType] = useState({ type: "", id: "" }); //popup, header, news
  const [formInvalid, setFormInvalid] = useState(false);
  const fileInput = useRef(null);
  const headerFileInput = useRef(null);
  const popupFileInput = useRef(null);

  const onChangeHandler = (value) => {
    //for checkbox
    let obj = { file: popup.popupImage, popup: !value, blId: blId };
    dispatch(addPopUp(obj));
  };

  const handleNewPopupImage = (imageData) => {
    setPopupImage(imageData);
    let obj = {
      file: imageData,
      popup: check,
      blId: blId,
    };

    if (imageData !== "") dispatch(addImagePopUp(obj));
  };

  const handleNewHeaderImage = (imageData) => {
    // console.log(imageData)
    let obj = {
      file: imageData,
      blId: blId,
    };
    if (imageData !== "" && header.length < 4) dispatch(addHeader(obj));
    if (header.length === 4) {
      // console.log('max headers');
      alert("You can add only 4 headers");
    }
  };

  const handleNewNewsImage = (imageData) => {
    // console.log(imageData)
    let obj = {
      file: imageData,
      blId: blId,
    };
    if (imageData !== "" && news.length < 4) dispatch(addNews(obj));
    if (news.length === 4) alert("You can add only 4 news");
  };

  useEffect(() => {
    dispatch(getPopUp(blId));
    // console.log(popup.PopupImage)
  }, []);

  useEffect(() => {
    dispatch(getHeader(blId));
  }, []);

  useEffect(() => {
    dispatch(getNews(blId));
  }, []);

  useEffect(() => {
    dispatch(articleAction(blId));
  }, [blId]);

  useEffect(() => {
    dispatch(getBestSellers(blId));
  }, []);

  useEffect(() => {
    dispatch(getSpecialSellers(blId));
  }, []);

  const onPickFile = (e) => {
    e.preventDefault();
    fileInput.current.click();
  };

  const onHeaderPickFile = (e) => {
    e.preventDefault();
    headerFileInput.current.click();
  };

  const onPopupPickFile = (e) => {
    e.preventDefault();
    popupFileInput.current.click();
  };

  const onFilePicked = async (event) => {
    handleNewNewsImage(event.target.files[0]);
  };

  const onHeaderFilePicked = async (event) => {
    handleNewHeaderImage(event.target.files[0]);
  };

  const onPopupFilePicked = async (event) => {
    handleNewPopupImage(event.target.files[0]);
  };

  // console.log('spcseller', articles);

  // console.log("header", header)
  // console.log("news",news)
  const handleDelete = (id) => {
    //delete header
    // console.log('test delete header', id);
    dispatch(deleteHeader(id, blId));
  };
  const handleNewsDelete = (id) => {
    //delete news
    // console.log('test delete news', id);
    dispatch(deleteNews(id, blId));
  };
  const onRemoveHandler = (id) => {
    dispatch(removeBestSeller(id, blId));
  };

  const onRemoveSpecialSellerHandler = (id) => {
    dispatch(removeSpecialSeller(id, blId));
  };

  const handleNewSeller = (e) => {
    let data = e.target.value;
    var tempArr = data.split("*"); //spliting because only one string can be passed in value attribute of option
    let obj = {
      blId: blId,
      article_name: tempArr[0],
      article_sku: tempArr[1],
      price: tempArr[2],
    };
    dispatch(addBestSeller(obj));
  };

  const handleNewSpecialSeller = (e) => {
    let data = e.target.value;
    var tempArr = data.split("*"); //spliting because only one string can be passed in value attribute of option

    let obj = {
      blId: blId,
      group: tempArr[2],
      id: tempArr[3],
      name: tempArr[0],
      price: tempArr[4],
      sku: tempArr[1],
    };

    dispatch(addSpecialSeller(obj));
  };

  const handleURLUpdate = () => {
    const payload = { id: updateType?.id, url: updateURL };
    if (updateType?.type === "popup") {
      dispatch(updatePopUpURL(payload));
    } else if (updateType?.type === "header") {
      dispatch(updateHeaderURL(payload));
    } else {
      dispatch(updateNewsURL(payload));
    }
    setViewURLModal(false);
    setUpdateType({ type: "", id: "" });
    setFormInvalid(false);
  };

  return (
    <>
      <div className="mb-5">
        <div className="d-flex align-items-center">
          <h5 className="mb-0 text-black">{props.title}</h5>
          <Button color="primary" className="ml-auto">
            Save
          </Button>
        </div>
        {/* <Row>
	<Col xs="12" sm="12" md="6" lg="6">
		<div className="card"></div>
	</Col>
</Row> */}
        <div className="mt-3 ">
          <Row className="mt-2">
            <Col lg="6" md="6" sm="12" xs="12" className="card">
              <Row>
                {/* <Col lg="6" md="6" sm="12" xs="12" className="border">
                <small className="font-weight-bold">Color suggestions</small>
                <div className="p-4">
                  <div className=" mt-1 d-flex align-items-center">
                    <i className="fas fa-square text-danger"></i>
                    <select className="rounded-pill ml-2 px-2 ">
                      <option value="Select item">Select item</option>
                      <option value="a">a</option>
                      <option value="a">a</option>
                    </select>
                  </div>
                  <div className="mt-1 d-flex align-items-center ">
                    <i className="fas fa-square text-primary"></i>
                    <select className="rounded-pill ml-2 px-2">
                      <option value="Select item">Select item</option>
                      <option value="a">a</option>
                      <option value="a">a</option>
                    </select>
                  </div>
                  <div className="mt-1 d-flex align-items-center">
                    <i className="fas fa-square text-secondary"></i>
                    <select className="rounded-pill ml-2 px-2">
                      <option value="Select item">Select item</option>
                      <option value="a">a</option>
                      <option value="a">a</option>
                    </select>
                  </div>
                  <div className="mt-1 d-flex align-items-center">
                    <i className="fas fa-square text-warning"></i>
                    <select className="rounded-pill ml-2 px-2">
                      <option value="Select item">Select item</option>
                      <option value="a">a</option>
                      <option value="a">a</option>
                    </select>
                  </div>
                  <div className="mt-1 d-flex align-items-center">
                    <i className="fas fa-square text-info"></i>
                    <select className="rounded-pill ml-2 px-2">
                      <option value="Select item">Select item</option>
                      <option value="a">a</option>
                      <option value="a">a</option>
                    </select>
                  </div>
                  <div className="mt-1 d-flex align-items-center">
                    <i className="fas fa-square text-success"></i>
                    <select className="rounded-pill ml-2 px-2">
                      <option value="Select item">Select item</option>
                      <option value="a">a</option>
                      <option value="a">a</option>
                    </select>
                  </div>{" "}
                  <div className="mt-1 mb-1 d-flex align-items-center">
                    <i className="fas fa-square text-danger"></i>
                    <select className="rounded-pill ml-2 px-2">
                      <option value="Select item">Select item</option>
                      <option value="a">a</option>
                      <option value="a">a</option>
                    </select>
                  </div>
                </div>
              </Col> */}
                <Col lg="6" md="6" sm="12" xs="12" className="border ">
                  <small className="font-weight-bold">Special</small>
                  <div className="w-100 ">
                    <div className="d-flex justify-content-center">
                      <select
                        onChange={(e) => handleNewSpecialSeller(e)}
                        className=" rounded-pill w-75 mt-4 p-1"
                      >
                        <option value="select item">select item</option>
                        {articles &&
                          articles.map((val) => {
                            const idd = val.id;
                            const grp = val.group;
                            return val.items.map((item) => {
                              return (
                                <option
                                  value={
                                    item.productName +
                                    "*" +
                                    item.sku +
                                    "*" +
                                    grp +
                                    "*" +
                                    idd +
                                    "*" +
                                    item.productPrice
                                  }
                                >
                                  {item.productName}
                                </option>
                              );
                            });
                          })}
                        {/* <option value="selecet">select</option> */}
                      </select>
                    </div>
                    {specialSeller &&
                      specialSeller.map((val) => {
                        return (
                          <>
                            <div className="d-flex flex-row justify-content-center mt-4">
                              <button
                                onClick={() =>
                                  onRemoveSpecialSellerHandler(val._id)
                                }
                                className="RemoveButton"
                              >
                                <i className="fas fa-times-circle text-primary mx-2 mt--1"></i>
                              </button>
                              <h6>{val.name}</h6>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </Col>
                <Col lg="6" md="6" sm="12" xs="12" className="border ">
                  <small className="font-weight-bold">Best seller</small>
                  <div className="w-100 ">
                    <div className="d-flex justify-content-center">
                      <select
                        onChange={(e) => handleNewSeller(e)}
                        className=" rounded-pill w-75 mt-4 p-1"
                      >
                        <option value="select item">select item</option>
                        {articles &&
                          articles.map((val) => {
                            return val.items.map((item) => {
                              return (
                                <option
                                  value={
                                    item.productName +
                                    "*" +
                                    item.sku +
                                    "*" +
                                    item.productPrice
                                  }
                                >
                                  {item.productName}
                                </option>
                              );
                            });
                          })}
                        {/* <option value="selecet">select</option> */}
                      </select>
                    </div>
                    {bestSeller &&
                      bestSeller.map((val) => {
                        return (
                          <>
                            <div className="d-flex flex-row justify-content-center mt-4">
                              <button
                                onClick={() => onRemoveHandler(val._id)}
                                className="RemoveButton"
                              >
                                <i className="fas fa-times-circle text-primary mx-2 mt--1"></i>
                              </button>
                              <h6>{val.name}</h6>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg="6" md="6" sm="12" xs="12" className="card">
              <Row>
                <Col lg="4" md="4" sm="12" xs="12">
                  <div className="border mt-2 pl-2 pr-2 pb-4">
                    <small className="font-weight-bold">Pop up</small>
                    <div>
                      <Row>
                        <Col md="12" lg="12" sm="12" xs="12">
                          {/* <form onSubmit={onSubmitHandler}> */}
                          <div className="d-flex my-2 mt-2">
                            {/* <form onSubmit={onSubmitHandler}> */}
                            <input
                              // onChange={(event) =>
                              //   handleNewNewsImage(event.target.files[0])
                              // }
                              onChange={onPopupFilePicked}
                              type="file"
                              ref={popupFileInput}
                              accept="image/png, image/gif, image/jpeg, image/jpg"
                              style={{
                                width: "100%",
                                height: "40%",
                                opacity: 0,
                                position: "absolute",
                                top: "0px",
                                left: "0px",
                                display: "none",
                              }}
                            ></input>
                            <i
                              onClick={onPopupPickFile}
                              className="fas fa-plus-circle ml-auto text-lg"
                            ></i>
                            {/* </form> */}
                          </div>
                          {loader_popup ? (
                            <Spinner size="md" />
                          ) : (
                            <img
                              src={popup.popupImage}
                              alt={"popup not found"}
                              className="img-fluid w-100 h-75 mt-2"
                              onClick={() => {
                                setViewURLModal(true);
                                setUpdateType({ type: "popup", id: popup._id });
                              }}
                            />
                          )}
                          <br></br>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <ToggleSwitch
                    Check={popup.popup}
                    onChangeHandler={onChangeHandler}
                  />
                </Col>
                <Col lg="4" md="4" sm="12" xs="12">
                  <div className="border mt-2 pl-2 pr-2 pb-4">
                    <small className="font-weight-bold">Header</small>
                    <div>
                      <Row>
                        <Col md="12" lg="12" sm="12" xs="12">
                          <div className="d-flex my-2 mt-2">
                            {/* <form onSubmit={onSubmitHandler}> */}
                            <input
                              // onChange={(event) =>
                              //   handleNewNewsImage(event.target.files[0])
                              // }
                              onChange={onHeaderFilePicked}
                              type="file"
                              ref={headerFileInput}
                              accept="image/png, image/gif, image/jpeg, image/jpg"
                              style={{
                                width: "100%",
                                height: "40%",
                                opacity: 0,
                                position: "absolute",
                                top: "0px",
                                left: "0px",
                                display: "none",
                              }}
                            ></input>
                            <i
                              onClick={onHeaderPickFile}
                              className="fas fa-plus-circle ml-auto text-lg"
                            ></i>
                            {/* </form> */}
                          </div>
                          {loader_header ? (
                            <Spinner />
                          ) : (
                            header &&
                            header.map((image, index) => {
                              return (
                                <div key={index} className="d-flex my-2 mt-2">
                                  <img
                                    src={image.image}
                                    alt="header not found"
                                    className="img-fluid"
                                    onClick={() => {
                                      setViewURLModal(true);
                                      setUpdateType({
                                        type: "header",
                                        id: image._id,
                                      });
                                    }}
                                  ></img>
                                  <button
                                    className="NewsButton"
                                    onClick={() => handleDelete(image._id)}
                                  >
                                    <i className="fas fa-times-circle ml-auto text-sm"></i>
                                  </button>
                                </div>
                              );
                            })
                          )}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
                <Col lg="4" md="4" sm="12" xs="12">
                  <div className="border mt-2 pl-2 pr-2 pb-4">
                    <small className="font-weight-bold">News</small>
                    <div>
                      <Row>
                        <Col md="12" lg="12" sm="12" xs="12">
                          <div className="d-flex my-2 mt-2">
                            {/* <form onSubmit={onSubmitHandler}> */}
                            <input
                              // onChange={(event) =>
                              //   handleNewNewsImage(event.target.files[0])
                              // }
                              onChange={onFilePicked}
                              type="file"
                              ref={fileInput}
                              accept="image/png, image/gif, image/jpeg, image/jpg"
                              style={{
                                width: "100%",
                                height: "40%",
                                opacity: 0,
                                position: "absolute",
                                top: "0px",
                                left: "0px",
                                display: "none",
                              }}
                            ></input>
                            <i
                              onClick={onPickFile}
                              className="fas fa-plus-circle ml-auto text-lg"
                            ></i>
                            {/* </form> */}
                          </div>
                          {loader_news ? (
                            <Spinner />
                          ) : (
                            news &&
                            news.map((image, index) => {
                              return (
                                <div key={index} className="d-flex my-2 mt-2">
                                  <img
                                    src={image.image}
                                    alt="news not found"
                                    className="img-fluid"
                                    onClick={() => {
                                      setViewURLModal(true);
                                      setUpdateType({
                                        type: "news",
                                        id: image._id,
                                      });
                                    }}
                                  ></img>
                                  <button
                                    className="NewsButton"
                                    onClick={() => handleNewsDelete(image._id)}
                                  >
                                    <i className="fas fa-times-circle ml-auto text-sm"></i>
                                  </button>
                                </div>
                              );
                            })
                          )}

                          {/* <img src={image} alt={imageAlt} className='img-fluid w-100 h-75 mt-2'/> */}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        isOpen={viewURLModal}
        toggle={() => {
          setViewURLModal(false);
          setUpdateType({ type: "", id: "" });
          setFormInvalid(false);
        }}
      >
        <ModalHeader
          toggle={() => {
            setViewURLModal(false);
            setUpdateType({ type: "", id: "" });
            setFormInvalid(false);
          }}
        >
          Update URL
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="exampleEmail">URL</Label>
              <Input
                id="url"
                name="url"
                placeholder="Enter URL Here"
                type="url"
                required
                invalid={formInvalid}
                onChange={(e) => {
                  setUpdateURL(e.target.value);
                }}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            color="primary"
            onClick={() => {
              const regex = new RegExp(
                "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
              ); // var t = 'www.google.com';
              if (updateURL !== "" && regex.test(updateURL)) {
                handleURLUpdate();
              } else {
                setFormInvalid(true);
              }
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default PromotionSection;
