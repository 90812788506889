import CategoryModal from 'components/CategoryModal';
import PageTitle from 'components/PageTitle';
import React, { useState, useEffect } from 'react';
import { Col, Row, Spinner, Table } from 'reactstrap';
// import { CategoriesData } from '../../../components/DummyData/Categories';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCategories } from 'store/actions/categoryActions';
import { getAllAllergens } from 'store/actions/allergenAction';
import { deleteCategory } from 'store/actions/categoryActions';
import AllergenModal from 'components/AllergenModal';
import { deleteAllergen } from 'store/actions/allergenAction';
const Categories = () => {
	const dispatch = useDispatch();
	const [show, setShow] = useState(false);
	const [allergenShow, setAllergenShow] = useState(false);
	const [title, setTitle] = useState('');
	const [allergenData, setAllergenData] = useState([]);
	const [categoriesData, setCategoriesData] = useState([]);
	const [singleitem, setSingleitem] = useState();
	const [allergenMode, setAllergenMode] = useState(true);
	const [btnTitle, setBtnTitle] = useState('');
	const { categories, getCatLoader } = useSelector(state => state.category);
	const { allergens, getAllLoader } = useSelector(state => state.allergen);

	useEffect(() => {
		dispatch(getAllCategories());
		dispatch(getAllAllergens());
	}, []);

	const handleClose = () => {
		setShow(false);
		setTitle('');
		setSingleitem();
		setBtnTitle('');
	};

	const handleAllergenClose = () => {
		setAllergenShow(false);
		setTitle('');
		setSingleitem();
		setBtnTitle('');
	};

	const handleDelete = id => {
		dispatch(deleteCategory(id));
	};

	const handleAllergenDelete = id => {
		dispatch(deleteAllergen(id));
	};

	return (
		<>
			<div className='card p-2 border border-light rounded w-50'>
				<PageTitle title='Allergens' icon='/img/sidebar/tag.png' />
			</div>
			<Row>
				<Col lg='12' md='12' sm='12' xs='12'>
					<div className='d-flex justify-content-end '>
						<button
							type='button'
							onClick={() => {
								setAllergenShow(true);
								setTitle('Add New Allergen');
								setAllergenMode(true);
								setBtnTitle('Add');
							}}
							className='btn btn-primary '
						>
							<i className='fa fa-plus-circle'></i>
						</button>
					</div>
					{allergenShow === true ? (
						<AllergenModal
							title={title}
							handleAllergenClose={handleAllergenClose}
							allergenShow={allergenShow}
							check={'1'}
							btnTitle={btnTitle}
							allergenData={allergenData}
							setAllergenData={setAllergenData}
						/>
					) : (
						''
					)}
				</Col>
				<Col lg='12' md='12' sm='12' xs='12'>
					<div className='mt-3 card'>
						{getAllLoader ? (
							<Spinner
								className='d-flex mx-auto mt-5'
								size='lg'
								color='primary'
							/>
						) : (
							<Table striped responsive>
								<thead>
									<tr>
										<th>#</th>

										<th>Allergens</th>
										<th>Image</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{allergens &&
										allergens.map((category, index) => {
											return (
												<tr key={index}>
													<th scope='row'>
														{index + 1}
													</th>
													<td>{category?.title}</td>
													<td>
														{category.icon && (
															<img
																style={{
																	width: '50px',
																	height: '50px',
																}}
																src={
																	category.icon
																}
																alt=''
															/>
														)}
													</td>
													<td>
														<button
															className='btn'
															onClick={() => {
																setAllergenShow(
																	true
																);
																setTitle(
																	'Edit Allergen'
																);
																setAllergenMode(
																	true
																);
																setSingleitem(
																	category
																);
															}}
														>
															<i className='fa fa-pen fa-sm'></i>
														</button>
														<button className='btn'>
															<i
																className='fa fa-trash fa-sm'
																onClick={() =>
																	handleAllergenDelete(
																		category._id
																	)
																}
															></i>
														</button>
													</td>
												</tr>
											);
										})}
								</tbody>
							</Table>
						)}
					</div>
				</Col>
			</Row>
			<div className='card p-2 border border-light rounded w-50 mt-4'>
				<PageTitle title='Categories' icon='/img/sidebar/tag.png' />
			</div>
			<Row>
				<Col lg='12' md='12' sm='12' xs='12'>
					<div className='d-flex justify-content-end '>
						<button
							type='button'
							onClick={() => {
								setShow(true);
								setTitle('Add New Category');
								setAllergenMode(false);
								setBtnTitle('Add');
							}}
							className='btn btn-primary '
						>
							<i className='fa fa-plus-circle'></i>
						</button>
					</div>
					{show === true ? (
						<CategoryModal
							title={title}
							handleClose={handleClose}
							show={show}
							check={'1'}
							btnTitle={btnTitle}
							allergenData={allergenData}
							setAllergenData={setAllergenData}
						/>
					) : (
						''
					)}
				</Col>
				<Col lg='12' md='12' sm='12' xs='12'>
					<div className='mt-3 card'>
						{getCatLoader ? (
							<Spinner
								className='d-flex mx-auto mt-5'
								size='lg'
								color='primary'
							/>
						) : (
							<Table striped responsive>
								<thead>
									<tr>
										<th>#</th>

										<th>catetory name</th>
										<th>Image</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{categories &&
										categories.map((category, index) => {
											return (
												<tr key={index}>
													<th scope='row'>
														{index + 1}
													</th>
													<td>{category?.title}</td>
													<td>
														{category.icon && (
															<img
																style={{
																	width: '50px',
																	height: '50px',
																}}
																src={
																	category.icon
																}
																alt=''
															/>
														)}
													</td>
													<td>
														<button
															className='btn'
															onClick={() => {
																setShow(true);
																setTitle(
																	'Edit Category'
																);
																setAllergenMode(
																	false
																);
																setSingleitem(
																	category
																);
															}}
														>
															<i className='fa fa-pen fa-sm'></i>
														</button>
														<button className='btn'>
															<i
																className='fa fa-trash fa-sm'
																onClick={() =>
																	handleDelete(
																		category._id
																	)
																}
															></i>
														</button>
													</td>
												</tr>
											);
										})}
								</tbody>
							</Table>
						)}
					</div>
				</Col>
			</Row>
			{show === true ? (
				<CategoryModal
					title={title}
					btnTitle={btnTitle}
					handleClose={handleClose}
					show={show}
					check={'0'}
					singleval={singleitem && singleitem}
					setShow={setShow}
					setTitle={setTitle}
					setSingleitem={setSingleitem}
					allergenData={allergenData}
					setAllergenData={setAllergenData}
					categoriesData={categoriesData}
					setCategoriesData={setCategoriesData}
					allergenMode={allergenMode}
				/>
			) : (
				''
			)}
			{allergenShow === true ? (
				<AllergenModal
					title={title}
					btnTitle={btnTitle}
					handleAllergenClose={handleAllergenClose}
					allergenShow={allergenShow}
					check={'0'}
					singleval={singleitem && singleitem}
					setAllergenShow={setAllergenShow}
					setTitle={setTitle}
					setSingleitem={setSingleitem}
					allergenData={allergenData}
					setAllergenData={setAllergenData}
					categoriesData={categoriesData}
					setCategoriesData={setCategoriesData}
					allergenMode={allergenMode}
				/>
			) : (
				''
			)}
		</>
	);
};

export default Categories;
