import { RepositoryFactory } from '../../repository/RepositoryFactory';
var ItemIngredientsRepository = RepositoryFactory.get('item_ingredients');

export const getItemIngredientsLoader = val => async dispatch => {
	dispatch({
		type: 'GET_ITEM_INGREDIENTS_LOADER',
		payload: val,
	});
};
export const ItemIngredientsLoader = val => async dispatch => {
	dispatch({
		type: 'ITEM_INGREDIENTS_LOADER',
		payload: val,
	});
};

export const getItemIngredients = id => async dispatch => {
	dispatch(getItemIngredientsLoader(true));
	let { data } = await ItemIngredientsRepository.getAllItemIngredients(id);
	try {
		if (data.success) {
			dispatch({
				type: 'GET_ITEM_INGREDIENTS',
				payload: data.data,
			});
			dispatch(getItemIngredientsLoader(false));
		} else {
			dispatch(getItemIngredientsLoader(false));
		}
	} catch (e) {
		dispatch({
			type: 'GET_ITEM_INGREDIENTS',
			payload: [],
		});
		dispatch(getItemIngredientsLoader(false));
	}
};

export const assignItemIngredients =
	(payload, onSuccess = () => {}) =>
	async dispatch => {
		dispatch(ItemIngredientsLoader(true));
		try {
			let { data } = await ItemIngredientsRepository.createItemIngredient(
				payload
			);
			if (data.success) {
				// alert('Ingredient Added');
				onSuccess();
				dispatch(getItemIngredients(payload.item_id));
				dispatch(ItemIngredientsLoader(false));
			} else {
				dispatch(ItemIngredientsLoader(false));
			}
		} catch (e) {
			dispatch(ItemIngredientsLoader(false));
		}
	};

export const removeItemIngredients = (id, item_id) => async dispatch => {
	dispatch(ItemIngredientsLoader(true));
	try {
		let { data } = await ItemIngredientsRepository.removeItemIngredients(
			id
		);
		if (data.success) {
			// alert('Ingredient Removed');
			dispatch(getItemIngredients(item_id));
			dispatch(ItemIngredientsLoader(false));
		} else {
			dispatch(ItemIngredientsLoader(false));
		}
	} catch (e) {
		dispatch(ItemIngredientsLoader(false));
	}
};
