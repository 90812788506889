let initState = {
	getAllLoader: false,
	allergens: [],
	allergenLoader: false,
};
export default function (state = initState, action) {
	switch (action.type) {
		case 'GET_ALLERGEN':
			return {
				...state,
				allergens: action.payload,
			};
		case 'GET_ALLERGEN_LOADER':
			return {
				...state,
				getAllLoader: action.payload,
			};
		case 'ALLERGEN_LOADER':
			return {
				...state,
				allergenLoader: action.payload,
			};
		default:
			return { ...state };
	}
}
