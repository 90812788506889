import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from 'store/actions/authAction';

const Logout = () => {
	let dispatch = useDispatch();
	useEffect(async () => {
		localStorage.clear();
		await dispatch(logout());
	});
	return <></>;
};

export default Logout;
