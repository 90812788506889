import Repository from "./Repository";
const STATIONS = "/stations";
export default {
  getAllStations() {
    return Repository.get(`${STATIONS}`);
  },
  createStations(payload) {
    let form = new FormData();
    form.append("title", payload.title);
    return Repository.post(`${STATIONS}`, form);
  },
  updateStations(payload) {
    let form = new FormData();
    form.append("title", payload.title);
    return Repository.put(`${STATIONS}/${payload.id}`, form);
  },
  deleteStations(_id) {
    return Repository.delete(`${STATIONS}/${_id}`, {});
  },
};
