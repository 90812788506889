import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import { updateAppColors } from "store/actions/settingsAction";
import { fontfamily, fontSize } from "./fontcustomize";

const AppFontSize = () => {
  const { blId } = useSelector((state) => state.blId);
  let storeType = null;

  const businessID = useSelector((state) => state?.settings.allSettings._id);
  let pageLayoutSettings = useSelector(
    (state) => state?.settings?.allSettings?.page_layout
  );

  if (window.location.pathname === "/store/takeaway-delivery/settings") {
    storeType = "takeaway_delivery";
    // currentSettings = currentSettings?.takeaway_delivery;
  } else if (window.location.pathname === "/store/in-store/settings") {
    storeType = "instore";
    // currentSettings = currentSettings?.instore;
  }
  const [currentData, setCurrentData] = useState();
  const [updatedData, setUpdatedData] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    //initialize updatedData structure
    let obj = [
      {
        page: "welcome",
        updatedKeys: {},
      },
      {
        page: "menu",
        updatedKeys: {},
      },
      {
        page: "bill",
        updatedKeys: {},
      },
      {
        page: "cart",
        updatedKeys: {},
      },
      {
        page: "guest_wall",
        updatedKeys: {},
      },
      {
        page: "service",
        updatedKeys: {},
      },
      {
        page: "footer",
        updatedKeys: {},
      },
    ];
    setUpdatedData(obj);
  }, []);

  useEffect(() => {
    if (storeType === "instore")
      setCurrentData(pageLayoutSettings?.instore)
    else if (storeType === "takeaway_delivery")
      setCurrentData(pageLayoutSettings?.takeaway_delivery)
  }, [storeType, pageLayoutSettings])

  const handleSubmit = () => {
    const payload = {
      blID: blId,
      type: storeType.toString(),
      updated_section: [...updatedData],
    };
    dispatch(updateAppColors(payload, businessID));
    // dispatch(dispatch(getBusinessSettings({
    //   buid: blId,
    //   table_no: 20
    // })))
  };

  const handleChange = (page, keyVal) => {
    switch (page) {
      case "welcome": {
        let original = [...updatedData];
        let obj = { ...updatedData[0] };
        obj = { ...obj, updatedKeys: { ...obj.updatedKeys, ...keyVal } };
        original[0] = { ...obj };
        setUpdatedData(original);

        let section = { ...currentData[page] };
        section = { ...section, ...keyVal };
        let current = { ...currentData };
        current[page] = section;
        setCurrentData(current);
        break;
      }
      case "menu": {
        let original = [...updatedData];
        let obj = { ...updatedData[1] };
        obj = { ...obj, updatedKeys: { ...obj.updatedKeys, ...keyVal } };
        original[1] = { ...obj };
        setUpdatedData(original);

        let section = { ...currentData[page] };
        section = { ...section, ...keyVal };
        let current = { ...currentData };
        current[page] = section;
        setCurrentData(current);
        break;
      }
      case "bill": {
        let original = [...updatedData];
        let obj = { ...updatedData[2] };
        obj = { ...obj, updatedKeys: { ...obj.updatedKeys, ...keyVal } };
        original[2] = { ...obj };
        setUpdatedData(original);

        let section = { ...currentData[page] };
        section = { ...section, ...keyVal };
        let current = { ...currentData };
        current[page] = section;
        setCurrentData(current);
        break;
      }
      case "cart": {
        let original = [...updatedData];
        let obj = { ...updatedData[3] };
        obj = { ...obj, updatedKeys: { ...obj.updatedKeys, ...keyVal } };
        original[3] = { ...obj };
        setUpdatedData(original);

        let section = { ...currentData[page] };
        section = { ...section, ...keyVal };
        let current = { ...currentData };
        current[page] = section;
        setCurrentData(current);
        break;
      }
      case "guest_wall": {
        let original = [...updatedData];
        let obj = { ...updatedData[4] };
        obj = { ...obj, updatedKeys: { ...obj.updatedKeys, ...keyVal } };
        original[4] = { ...obj };
        setUpdatedData(original);

        let section = { ...currentData[page] };
        section = { ...section, ...keyVal };
        let current = { ...currentData };
        current[page] = section;
        setCurrentData(current);
        break;
      }
      case "service": {
        let original = [...updatedData];
        let obj = { ...updatedData[5] };
        obj = { ...obj, updatedKeys: { ...obj.updatedKeys, ...keyVal } };
        original[5] = { ...obj };
        setUpdatedData(original);

        let section = { ...currentData[page] };
        section = { ...section, ...keyVal };
        let current = { ...currentData };
        current[page] = section;
        setCurrentData(current);
        break;
      }
      case "footer": {
        let original = [...updatedData];
        let obj = { ...updatedData[6] };
        obj = { ...obj, updatedKeys: { ...obj.updatedKeys, ...keyVal } };
        original[6] = { ...obj };
        setUpdatedData(original);

        let section = { ...currentData[page] };
        section = { ...section, ...keyVal };
        let current = { ...currentData };
        current[page] = section;
        setCurrentData(current);
        break;
      }
      default: {
        console.log("invalid page");
        break;
      }
    }
  };

  // const fonts = [
  //   {
  //     name: "Main Font",
  //   },
  //   {
  //     name: "Description font",
  //   },
  //   {
  //     name: "Title font",
  //   },
  //   {
  //     name: "Price font",
  //   },
  //   {
  //     name: "Article title font",
  //   },
  //   {
  //     name: "Menu font",
  //   },
  //   {
  //     name: "Footer color",
  //   },
  //   ,
  //   {
  //     name: "Bottom menu back color",
  //   },
  //   ,
  //   {
  //     name: "Background color",
  //   },
  // ];
  return (
    <>
      <div className="shadow bg-white rounded mt-5 pb-2">
        <div className="wrapper p-3">
          <div className="title d-flex align-items-center">
            <img
              src="/road-with-broken-line.png"
              alt="clock"
              height="20px"
              className="mr-3"
            />
            <span>
              <h4 className="text-black mb-0">App Fonts</h4>
            </span>
          </div>
        </div>
        {/* Join Table Color */}
        <div className="heading-colors-group p-3">
          <h4 className="text-dark">Join Table</h4>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              Primary Title Font Size
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.welcome?.font_size_primary_title}
              onChange={(e) => {
                handleChange("welcome", {
                  font_size_primary_title: e.target.value,
                });
              }}
            >
              {fontSize.map((fs, index) => {
                return (
                  <>
                    <option value={fs.value}>{fs.label}</option>
                  </>
                );
              })}
            </select>
          </div>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              Primary Title Font Family
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.welcome?.font_family_primary_title}
              onChange={(e) => {
                handleChange("welcome", {
                  font_family_primary_title: e.target.value,
                });
              }}
            >
              {fontfamily.map((ff, index) => {
                return <option value={ff.value}>{ff.label}</option>;
              })}
            </select>
          </div>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              Secondary Title Font Size
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.welcome?.font_size_secondary_title}
              onChange={(e) => {
                handleChange("welcome", {
                  font_size_secondary_title: e.target.value,
                });
              }}
            >
              {fontSize.map((fs, index) => {
                return (
                  <>
                    <option value={fs.value}>{fs.label}</option>
                  </>
                );
              })}
            </select>
          </div>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              Secondary Title Font Family
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.welcome?.font_family_secondary_title}
              onChange={(e) => {
                handleChange("welcome", {
                  font_family_secondary_title: e.target.value,
                });
              }}
            >
              {fontfamily.map((ff, index) => {
                return <option value={ff.value}>{ff.label}</option>;
              })}
            </select>
          </div>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              Create Table Font Size
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.welcome?.font_size_create_table_btn}
              onChange={(e) => {
                handleChange("welcome", {
                  font_size_create_table_btn: e.target.value,
                });
              }}
            >
              {fontSize.map((fs, index) => {
                return (
                  <>
                    <option value={fs.value}>{fs.label}</option>
                  </>
                );
              })}
            </select>
          </div>
          <div className="d-flex align-items-center mt-2">
            <small className="font-weight-semi-bold">
              Create Table Font Family
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.welcome?.font_family_create_table_btn}
              onChange={(e) => {
                handleChange("welcome", {
                  font_family_create_table_btn: e.target.value,
                });
              }}
            >
              {fontfamily.map((ff, index) => {
                return <option value={ff.value}>{ff.label}</option>;
              })}
            </select>
          </div>
          <div className="d-flex align-items-center mt-2">
            <small className="font-weight-semi-bold">
              Join Table Font Size
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.welcome?.font_size_join_table_btn}
              onChange={(e) => {
                handleChange("welcome", {
                  font_size_join_table_btn: e.target.value,
                });
              }}
            >
              {fontSize.map((fs, index) => {
                return (
                  <>
                    <option value={fs.value}>{fs.label}</option>
                  </>
                );
              })}
            </select>
          </div>
          <div className="d-flex align-items-center mt-2">
            <small className="font-weight-semi-bold">
              Join Table Font Family
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.welcome?.font_family_join_table_btn}
              onChange={(e) => {
                handleChange("welcome", {
                  font_family_join_table_btn: e.target.value,
                });
              }}
            >
              {fontfamily.map((ff, index) => {
                return <option value={ff.value}>{ff.label}</option>;
              })}
            </select>
          </div>
        </div>
        {/* Menu Font Family */}
        <div className="heading-colors-group p-3">
          <h4 className="text-dark">Menu Page</h4>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              Special Title Font Size
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.menu?.font_size_summer_specials_title}
              onChange={(e) => {
                handleChange("menu", {
                  font_size_summer_specials_title: e.target.value,
                });
              }}
            >
              {fontSize.map((fs, index) => {
                return (
                  <>
                    <option value={fs.value}>{fs.label}</option>
                  </>
                );
              })}
            </select>
          </div>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              Special Title Font Family
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.menu?.font_family_summer_specials_title}
              onChange={(e) => {
                handleChange("menu", {
                  font_family_summer_specials_title: e.target.value,
                });
              }}
            >
              {fontfamily.map((ff, index) => {
                return <option value={ff.value}>{ff.label}</option>;
              })}
            </select>
          </div>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              Vegan Title Font Size
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.menu?.font_size_vegan_title}
              onChange={(e) => {
                handleChange("menu", { font_size_vegan_title: e.target.value });
              }}
            >
              {fontSize.map((fs, index) => {
                return (
                  <>
                    <option value={fs.value}>{fs.label}</option>
                  </>
                );
              })}
            </select>
          </div>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              Vegan Title Font Family
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.menu?.font_family_vegan_title}
              onChange={(e) => {
                handleChange("menu", {
                  font_family_vegan_title: e.target.value,
                });
              }}
            >
              {fontfamily.map((ff, index) => {
                return <option value={ff.value}>{ff.label}</option>;
              })}
            </select>
          </div>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              General Title Font Size
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.menu?.font_size_general_title}
              onChange={(e) => {
                handleChange("menu", {
                  font_size_general_title: e.target.value,
                });
              }}
            >
              {fontSize.map((fs, index) => {
                return (
                  <>
                    <option value={fs.value}>{fs.label}</option>
                  </>
                );
              })}
            </select>
          </div>
          <div className="d-flex align-items-center mt-2">
            <small className="font-weight-semi-bold">
              General Title Font Family
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.menu?.font_family_general_title}
              onChange={(e) => {
                handleChange("menu", {
                  font_family_general_title: e.target.value,
                });
              }}
            >
              {fontfamily.map((ff, index) => {
                return <option value={ff.value}>{ff.label}</option>;
              })}
            </select>
          </div>
          <div className="d-flex align-items-center mt-2">
            <small className="font-weight-semi-bold">
              Best Seller Font Size
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.menu?.font_size_best_seller_title}
              onChange={(e) => {
                handleChange("menu", {
                  font_size_best_seller_title: e.target.value,
                });
              }}
            >
              {fontSize.map((fs, index) => {
                return (
                  <>
                    <option value={fs.value}>{fs.label}</option>
                  </>
                );
              })}
            </select>
          </div>
          <div className="d-flex align-items-center mt-2">
            <small className="font-weight-semi-bold">
              Best Seller Font Family
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.menu?.font_family_best_seller_title}
              onChange={(e) => {
                handleChange("menu", {
                  font_family_best_seller_title: e.target.value,
                });
              }}
            >
              {fontfamily.map((ff, index) => {
                return <option value={ff.value}>{ff.label}</option>;
              })}
            </select>
          </div>
          <div className="d-flex align-items-center mt-2">
            <small className="font-weight-semi-bold">
              Search Button Font Family
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.menu?.font_family_search_btn}
              onChange={(e) => {
                handleChange("menu", {
                  font_family_search_btn: e.target.value,
                });
              }}
            >
              {fontfamily.map((ff, index) => {
                return <option value={ff.value}>{ff.label}</option>;
              })}
            </select>
          </div>
          <div className="d-flex align-items-center mt-2">
            <small className="font-weight-semi-bold">
              Search Button Font Size
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.menu?.font_size_search_btn}
              onChange={(e) => {
                handleChange("menu", { font_size_search_btn: e.target.value });
              }}
            >
              {fontSize.map((fs, index) => {
                return (
                  <>
                    <option value={fs.value}>{fs.label}</option>
                  </>
                );
              })}
            </select>
          </div>
          <div className="d-flex align-items-center mt-2">
            <small className="font-weight-semi-bold">
              Personalize Button Font Family
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.menu?.font_family_personalize_btn}
              onChange={(e) => {
                handleChange("menu", {
                  font_family_personalize_btn: e.target.value,
                });
              }}
            >
              {fontfamily.map((ff, index) => {
                return <option value={ff.value}>{ff.label}</option>;
              })}
            </select>
          </div>
          <div className="d-flex align-items-center mt-2">
            <small className="font-weight-semi-bold">
              Personalize button Font Size
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.menu?.font_size_personalize_btn}
              onChange={(e) => {
                handleChange("menu", {
                  font_size_personalize_btn: e.target.value,
                });
              }}
            >
              {fontSize.map((fs, index) => {
                return (
                  <>
                    <option value={fs.value}>{fs.label}</option>
                  </>
                );
              })}
            </select>
          </div>
          <div className="d-flex align-items-center mt-2">
            <small className="font-weight-semi-bold">
              Order Button Font Family
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.menu?.font_family_order_btn}
              onChange={(e) => {
                handleChange("menu", { font_family_order_btn: e.target.value });
              }}
            >
              {fontfamily.map((ff, index) => {
                return <option value={ff.value}>{ff.label}</option>;
              })}
            </select>
          </div>
          <div className="d-flex align-items-center mt-2">
            <small className="font-weight-semi-bold">
              Order Button Font Size
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.menu?.font_size_order_btn}
              onChange={(e) => {
                handleChange("menu", { font_size_order_btn: e.target.value });
              }}
            >
              {fontSize.map((fs, index) => {
                return (
                  <>
                    <option value={fs.value}>{fs.label}</option>
                  </>
                );
              })}
            </select>
          </div>
          <div className="d-flex align-items-center mt-2">
            <small className="font-weight-semi-bold">
              Menu Item Title Font Size
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.menu?.font_size_menu_item_title}
              onChange={(e) => {
                handleChange("menu", { font_size_menu_item_title: e.target.value });
              }}
            >
              {fontSize.map((fs, index) => {
                return (
                  <>
                    <option value={fs.value}>{fs.label}</option>
                  </>
                );
              })}
            </select>
          </div>
          <div className="d-flex align-items-center mt-2">
            <small className="font-weight-semi-bold">
              Menu Item Price Font Size
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.menu?.font_size_menu_item_price}
              onChange={(e) => {
                handleChange("menu", { font_size_menu_item_price: e.target.value });
              }}
            >
              {fontSize.map((fs, index) => {
                return (
                  <>
                    <option value={fs.value}>{fs.label}</option>
                  </>
                );
              })}
            </select>
          </div>
          <div className="d-flex align-items-center mt-2">
            <small className="font-weight-semi-bold">
              Menu Item Title Font Family
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.menu?.font_family_menu_item_title}
              onChange={(e) => {
                handleChange("menu", { font_family_menu_item_title: e.target.value });
              }}
            >
              {fontfamily.map((ff, index) => {
                return <option value={ff.value}>{ff.label}</option>;
              })}
            </select>
          </div>
          <div className="d-flex align-items-center mt-2">
            <small className="font-weight-semi-bold">
              Menu Item Price Font Family
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.menu?.font_family_menu_item_price}
              onChange={(e) => {
                handleChange("menu", { font_family_menu_item_price: e.target.value });
              }}
            >
              {fontfamily.map((ff, index) => {
                return <option value={ff.value}>{ff.label}</option>;
              })}
            </select>
          </div>
        </div>
        {/* Bill Font  */}
        <div className="heading-colors-group p-3">
          <h4 className="text-dark">Bill Page</h4>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              Pay All Button Title Font Size
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.bill?.font_size_pay_all_btn}
              onChange={(e) => {
                handleChange("bill", { font_size_pay_all_btn: e.target.value });
              }}
            >
              {fontSize.map((fs, index) => {
                return (
                  <>
                    <option value={fs.value}>{fs.label}</option>
                  </>
                );
              })}
            </select>
          </div>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              Pay All Button Title Font Family
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.bill?.font_family_pay_all_btn}
              onChange={(e) => {
                handleChange("bill", {
                  font_family_pay_all_btn: e.target.value,
                });
              }}
            >
              {fontfamily.map((ff, index) => {
                return <option value={ff.value}>{ff.label}</option>;
              })}
            </select>
          </div>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              Pay Divide Button Font Size
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.bill?.font_size_pay_divide_btn}
              onChange={(e) => {
                handleChange("bill", {
                  font_size_pay_divide_btn: e.target.value,
                });
              }}
            >
              {fontSize.map((fs, index) => {
                return (
                  <>
                    <option value={fs.value}>{fs.label}</option>
                  </>
                );
              })}
            </select>
          </div>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              Pay Divide Button Font Family
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.bill?.font_family_pay_divide_btn}
              onChange={(e) => {
                handleChange("bill", {
                  font_family_pay_divide_btn: e.target.value,
                });
              }}
            >
              {fontfamily.map((ff, index) => {
                return <option value={ff.value}>{ff.label}</option>;
              })}
            </select>
          </div>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              Pay Selected Button Font Size
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.bill?.font_size_pay_selected_btn}
              onChange={(e) => {
                handleChange("bill", {
                  font_size_pay_selected_btn: e.target.value,
                });
              }}
            >
              {fontSize.map((fs, index) => {
                return (
                  <>
                    <option value={fs.value}>{fs.label}</option>
                  </>
                );
              })}
            </select>
          </div>
          <div className="d-flex align-items-center mt-2">
            <small className="font-weight-semi-bold">
              Pay Selected Button Font Family
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.bill?.font_family_pay_selected_btn}
              onChange={(e) => {
                handleChange("bill", {
                  font_family_pay_selected_btn: e.target.value,
                });
              }}
            >
              {fontfamily.map((ff, index) => {
                return <option value={ff.value}>{ff.label}</option>;
              })}
            </select>
          </div>
          <div className="d-flex align-items-center mt-2">
            <small className="font-weight-semi-bold">
              Empty Message Font Size
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.bill?.font_size_empty_message_title}
              onChange={(e) => {
                handleChange("bill", {
                  font_size_empty_message_title: e.target.value,
                });
              }}
            >
              {fontSize.map((fs, index) => {
                return (
                  <>
                    <option value={fs.value}>{fs.label}</option>
                  </>
                );
              })}
            </select>
          </div>
          <div className="d-flex align-items-center mt-2">
            <small className="font-weight-semi-bold">
              Empty Message Font Family
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.bill?.font_family_empty_message_title}
              onChange={(e) => {
                handleChange("bill", {
                  font_family_empty_message_title: e.target.value,
                });
              }}
            >
              {fontfamily.map((ff, index) => {
                return <option value={ff.value}>{ff.label}</option>;
              })}
            </select>
          </div>
        </div>
        {/* Cart Font  */}
        <div className="heading-colors-group p-3">
          <h4 className="text-dark">Cart Page</h4>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              Place Order Button Font Size
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.cart?.font_size_place_order_btn}
              onChange={(e) => {
                handleChange("cart", {
                  font_size_place_order_btn: e.target.value,
                });
              }}
            >
              {fontSize.map((fs, index) => {
                return (
                  <>
                    <option value={fs.value}>{fs.label}</option>
                  </>
                );
              })}
            </select>
          </div>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              Place Order Button Font Family
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.cart?.font_family_place_order_btn}
              onChange={(e) => {
                handleChange("cart", {
                  font_family_place_order_btn: e.target.value,
                });
              }}
            >
              {fontfamily.map((ff, index) => {
                return <option value={ff.value}>{ff.label}</option>;
              })}
            </select>
          </div>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              Course Title Font Size
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.cart?.font_size_course_title}
              onChange={(e) => {
                handleChange("cart", {
                  font_size_course_title: e.target.value,
                });
              }}
            >
              {fontSize.map((fs, index) => {
                return (
                  <>
                    <option value={fs.value}>{fs.label}</option>
                  </>
                );
              })}
            </select>
          </div>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              Course Title Font Family
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.cart?.font_family_course_title}
              onChange={(e) => {
                handleChange("cart", {
                  font_family_course_title: e.target.value,
                });
              }}
            >
              {fontfamily.map((ff, index) => {
                return <option value={ff.value}>{ff.label}</option>;
              })}
            </select>
          </div>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              Course Item Font Size
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.cart?.font_size_course_items_title}
              onChange={(e) => {
                handleChange("cart", {
                  font_size_course_items_title: e.target.value,
                });
              }}
            >
              {fontSize.map((fs, index) => {
                return (
                  <>
                    <option value={fs.value}>{fs.label}</option>
                  </>
                );
              })}
            </select>
          </div>
          <div className="d-flex align-items-center mt-2">
            <small className="font-weight-semi-bold">
              Course Item Title Font Family
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.cart?.font_family_course_items_title}
              onChange={(e) => {
                handleChange("cart", {
                  font_family_course_items_title: e.target.value,
                });
              }}
            >
              {fontfamily.map((ff, index) => {
                return <option value={ff.value}>{ff.label}</option>;
              })}
            </select>
          </div>
        </div>
        {/* Guest of Wall Font  */}
        <div className="heading-colors-group p-3">
          <h4 className="text-dark">Wall of Guest Page</h4>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              Wall of Fame Title Font Size
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.guest_wall?.font_size_wall_of_fame_title}
              onChange={(e) => {
                handleChange("guest_wall", {
                  font_size_wall_of_fame_title: e.target.value,
                });
              }}
            >
              {fontSize.map((fs, index) => {
                return (
                  <>
                    <option value={fs.value}>{fs.label}</option>
                  </>
                );
              })}
            </select>
          </div>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              Wall of Fame Title Font Family
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={
                currentData?.guest_wall?.font_family_primary_wall_of_fame_title
              }
              onChange={(e) => {
                handleChange("guest_wall", {
                  font_family_primary_wall_of_fame_title: e.target.value,
                });
              }}
            >
              {fontfamily.map((ff, index) => {
                return <option value={ff.value}>{ff.label}</option>;
              })}
            </select>
          </div>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              Guest Entry Title Font Size
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.guest_wall?.font_size_guestbook_entry_title}
              onChange={(e) => {
                handleChange("guest_wall", {
                  font_size_guestbook_entry_title: e.target.value,
                });
              }}
            >
              {fontSize.map((fs, index) => {
                return (
                  <>
                    <option value={fs.value}>{fs.label}</option>
                  </>
                );
              })}
            </select>
          </div>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              Guest Entry Title Font Family
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.guest_wall?.font_family_guestbook_entry_title}
              onChange={(e) => {
                handleChange("guest_wall", {
                  font_family_guestbook_entry_title: e.target.value,
                });
              }}
            >
              {fontfamily.map((ff, index) => {
                return <option value={ff.value}>{ff.label}</option>;
              })}
            </select>
          </div>
        </div>
        {/* Service of Wall Font  */}
        <div className="heading-colors-group p-3">
          <h4 className="text-dark">Service Page</h4>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              Service Title Font Size
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.service?.font_size_service_title}
              onChange={(e) => {
                handleChange("service", {
                  font_size_service_title: e.target.value,
                });
              }}
            >
              {fontSize.map((fs, index) => {
                return (
                  <>
                    <option value={fs.value}>{fs.label}</option>
                  </>
                );
              })}
            </select>
          </div>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              Service Title Font Family
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.service?.font_family_service_title}
              onChange={(e) => {
                handleChange("service", {
                  font_family_service_title: e.target.value,
                });
              }}
            >
              {fontfamily.map((ff, index) => {
                return <option value={ff.value}>{ff.label}</option>;
              })}
            </select>
          </div>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              Help Title Font Size
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.service?.font_size_help_title}
              onChange={(e) => {
                handleChange("service", {
                  font_size_help_title: e.target.value,
                });
              }}
            >
              {fontSize.map((fs, index) => {
                return (
                  <>
                    <option value={fs.value}>{fs.label}</option>
                  </>
                );
              })}
            </select>
          </div>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              Help Title Font Family
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.service?.font_family_help_title}
              onChange={(e) => {
                handleChange("service", {
                  font_family_help_title: e.target.value,
                });
              }}
            >
              {fontfamily.map((ff, index) => {
                return <option value={ff.value}>{ff.label}</option>;
              })}
            </select>
          </div>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              Description Title Font Size
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.service?.font_size_description_title}
              onChange={(e) => {
                handleChange("service", {
                  font_size_description_title: e.target.value,
                });
              }}
            >
              {fontSize.map((fs, index) => {
                return (
                  <>
                    <option value={fs.value}>{fs.label}</option>
                  </>
                );
              })}
            </select>
          </div>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              Description Title Font Family
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.service?.font_family_description_title}
              onChange={(e) => {
                handleChange("service", {
                  font_family_description_title: e.target.value,
                });
              }}
            >
              {fontfamily.map((ff, index) => {
                return <option value={ff.value}>{ff.label}</option>;
              })}
            </select>
          </div>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              Confirm Button Font Size
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.service?.font_size_confirm_btn}
              onChange={(e) => {
                handleChange("service", {
                  font_size_confirm_btn: e.target.value,
                });
              }}
            >
              {fontSize.map((fs, index) => {
                return (
                  <>
                    <option value={fs.value}>{fs.label}</option>
                  </>
                );
              })}
            </select>
          </div>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              Confirm Button Font Family
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.service?.font_family_confirm_btn}
              onChange={(e) => {
                handleChange("service", {
                  font_family_confirm_btn: e.target.value,
                });
              }}
            >
              {fontfamily.map((ff, index) => {
                return <option value={ff.value}>{ff.label}</option>;
              })}
            </select>
          </div>
        </div>

        {/* Footer Font  */}
        <div className="heading-colors-group p-3">
          <h4 className="text-dark">Footer</h4>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              Primary Text Font Size
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.footer?.font_size_primary_text_title}
              onChange={(e) => {
                handleChange("footer", {
                  font_size_primary_text_title: e.target.value,
                });
              }}
            >
              {fontSize.map((fs, index) => {
                return (
                  <>
                    <option value={fs.value}>{fs.label}</option>
                  </>
                );
              })}
            </select>
          </div>
          <div className="d-flex align-items-center  mt-2">
            <small className="font-weight-semi-bold">
              Primary Text Font Family
            </small>
            <select
              type="select"
              className="ml-auto"
              style={{ minWidth: 140 }}
              value={currentData?.footer?.primary_text_font_family}
              onChange={(e) => {
                handleChange("footer", {
                  primary_text_font_family: e.target.value,
                });
              }}
            >
              {fontfamily.map((ff, index) => {
                return <option value={ff.value}>{ff.label}</option>;
              })}
            </select>
          </div>
        </div>

        <div className="d-flex p-3">
          <Button
            color="primary"
            className="text-uppercase px-4"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default AppFontSize;
