import { GET_MENU_LOADER } from "store/types";
import { GET_SINGLE_MENU } from "store/types";
import { GET_SINGLE_MENU_ITEMS } from "store/types";
import { GET_MENU } from "store/types";
import { MENU_LOADER } from "store/types";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
var menuRepository = RepositoryFactory.get("menu");
export const menuLoader = (val) => async (dispatch) => {
  dispatch({
    type: MENU_LOADER,
    payload: val,
  });
};
export const getMenuLoader = (val) => async (dispatch) => {
  dispatch({
    type: GET_MENU_LOADER,
    payload: val,
  });
};
export const getAlleArtikelenLoader = (val) => async (dispatch) => {
  dispatch({
    type: "GET_ALLE_ARTIKELENA_LOADER",
    payload: val,
  });
};
export const addMenu =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(menuLoader(true));
    try {
      let { data } = await menuRepository.addMenu(payload);
      if (data.success) {
        alert("Menu Added");
        dispatch(menuLoader(false));
        dispatch(getAllMenus(payload.blId));
        dispatch(getAllTakeAwayMenus(payload.blId));
        dispatch(getAllKioskMenu(payload.blId));
        onSuccess();
      } else {
        dispatch(menuLoader(false));
      }
    } catch (e) {
      alert(e.message);
      dispatch(menuLoader(false));
    }
  };

export const getAllAlleArtikelen = (id) => async (dispatch) => {
  dispatch(getAlleArtikelenLoader(true));
  let { data } = await menuRepository.getAllAlleArtikelen(id);
  if (data.success) {
    dispatch({
      type: "GET_ALLE_ARTIKELENA",
      payload: data.data,
    });
    dispatch(getAlleArtikelenLoader(false));
  } else {
    dispatch(getAlleArtikelenLoader(false));
  }
};

export const getAllMenus = (id) => async (dispatch) => {
  dispatch(getMenuLoader(true));
  try {
    let { data } = await menuRepository.getAllMenus(id);
    if (data.success) {
      dispatch({
        type: GET_MENU,
        payload: data.data,
      });
      dispatch(getMenuLoader(false));
    } else {
      dispatch(getMenuLoader(false));
    }
  } catch (e) {
    alert(e.message);
    dispatch(getMenuLoader(false));
  }
};
export const getAllCacheMenus = (id) => async (dispatch) => {
  dispatch(getMenuLoader(true));
  try {
    let { data } = await menuRepository.getAllCacheMenus(id);
    if (data.success) {
      dispatch({
        type: GET_MENU,
        payload: data.data,
      });
      dispatch(getMenuLoader(false));
    } else {
      dispatch(getMenuLoader(false));
    }
  } catch (e) {
    alert(e.message);
    dispatch(getMenuLoader(false));
  }
};
export const getAllTakeAwayMenus = (id) => async (dispatch) => {
  dispatch(getMenuLoader(true));
  try {
    let { data } = await menuRepository.getAllTakeAwayMenus(id);
    if (data.success) {
      dispatch({
        type: "GET_TAKE_AWAY_MENU",
        payload: data.data,
      });
      dispatch(getMenuLoader(false));
    } else {
      dispatch(getMenuLoader(false));
    }
  } catch (e) {
    alert(e.message);
    dispatch(getMenuLoader(false));
  }
};
export const getAllKioskMenu = (id) => async (dispatch) => {
  dispatch(getMenuLoader(true));
  try {
    let { data } = await menuRepository.getAllKioskMenu(id);
    if (data.success) {
      dispatch({
        type: "GET_KIOSK_MENU",
        payload: data.data,
      });
      dispatch(getMenuLoader(false));
    } else {
      dispatch(getMenuLoader(false));
    }
  } catch (e) {
    alert(e.message);
    dispatch(getMenuLoader(false));
  }
};

export const getSingleMenuItems = (payload) => async (dispatch) => {
  dispatch(getMenuLoader(true));
  try {
    let { data } = await menuRepository.getSingleMenuItems(payload);
    if (data.success) {
      dispatch({
        type: GET_SINGLE_MENU_ITEMS,
        payload: data.data,
      });
      dispatch(getMenuLoader(false));
    } else {
      dispatch({
        type: "GET_SINGLE_MENU_ITEMS_FAILED",
        payload: [],
      });
      dispatch(getMenuLoader(false));
    }
  } catch (e) {
    dispatch({
      type: "GET_SINGLE_MENU_ITEMS_FAILED",
      payload: [],
    });
    dispatch(getMenuLoader(false));
  }
};
export const createMenuItem = (payload) => async (dispatch) => {
  dispatch(menuLoader(true));
  try {
    let { data } = await menuRepository.createMenuItem(payload);
    if (data.success) {
      alert("Menu Saved");
      dispatch(getSingleMenuItems(payload.menu_id));
      dispatch(menuLoader(false));
    } else {
      dispatch(menuLoader(false));
    }
  } catch (e) {
    alert(e.message);
    dispatch(menuLoader(false));
  }
};
export const deleteMenu = (payload, blId) => async (dispatch) => {
  dispatch(menuLoader(true));
  try {
    let { data } = await menuRepository.deleteMenu(payload);
    if (data.success) {
      alert("Menu Removed");
      dispatch(getAllMenus(blId));
      dispatch(getAllTakeAwayMenus(blId));
      dispatch(getAllKioskMenu(blId));
      dispatch(menuLoader(false));
    } else {
      dispatch(menuLoader(false));
    }
  } catch (e) {
    alert(e.message);
    dispatch(menuLoader(false));
  }
};
export const positionDragItem = (payload, blId) => async (dispatch) => {
  //to change positions of drag items
  try {
    let { data } = await menuRepository.positionDragItem(payload, blId);
    if (data.success) {
      console.log("success", data);
      dispatch(getAllMenus(blId));
      dispatch(getAllKioskMenu(blId));
      dispatch(getAllTakeAwayMenus(blId));
    }
  } catch (e) {
    console.log(e.message);
  }
};
