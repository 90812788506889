let initState = {
	getArticleMenusLoader: false,
	articleMenusLoader: false,
	article_menus: [],
};
export default function (state = initState, action) {
	switch (action.type) {
		case 'GET_ARTICLE_MENUS':
			return {
				...state,
				article_menus: action.payload,
			};
		case 'ARTICLE_MENUS_LOADER':
			return {
				...state,
				articleMenusLoader: action.payload,
			};
		case 'GET_ARTICLE_MENUS_LOADER':
			return {
				...state,
				getArticleMenusLoader: action.payload,
			};
		default:
			return { ...state };
	}
}
