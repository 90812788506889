import { SET_ARTICLES } from "../types";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
var articleRepository = RepositoryFactory.get("articles");

export const getArticleLoader = (val) => async (dispatch) => {
  dispatch({
    type: "SET_ARTICLE_LOADING",
    payload: val,
  });
};

export const getArticleLoader2 = (val) => async (dispatch) => {
  dispatch({
    type: "SET_ARTICLE_LOADING_2",
    payload: val,
  });
};

export const articleAction = (payload) => async (dispatch) => {
  dispatch(getArticleLoader(true));
  try {
    let { data } = await articleRepository.getArticles(payload);
    dispatch({
      type: SET_ARTICLES,
      payload: data.data,
    });
    dispatch(getArticleLoader(false));
  } catch (err) {
    dispatch(getArticleLoader(false));
    alert("Something went wrong try again");
  }
};

export const getArticles = (payload) => async (dispatch) => {
  dispatch(getArticleLoader2(true));
  try {
    let { data } = await articleRepository.getArticles(payload);
    dispatch({
      type: SET_ARTICLES,
      payload: data.data,
    });
    dispatch(getArticleLoader2(false));
  } catch (err) {
    alert("Something went wrong try again");
    dispatch(getArticleLoader2(false));
  }
};

export const changeItemStatusAct = (payload) => async (dispatch) => {
  dispatch(getArticleLoader2(true));
  //   dispatch(getArticleLoader2(true));
  let { data } = await articleRepository.changeItemStatusRep(payload);

  try {
    if (data.success) {
      dispatch({
        type: "GET_ARTICLE_DETAILS",
        payload: data.data,
      });
      dispatch(getArticles(payload.blId));
    }
  } catch (e) {
    console.log(e.message);
  }
};

export const changeGroupImageAct = (payload) => async (dispatch) => {
    dispatch(getArticleLoader2(true));
  let { data } = await articleRepository.changeGroupImageRep(payload);

  try {
    if (data.success) {
      dispatch({
        type: "GET_ARTICLE_DETAILS",
        payload: data.data,
      });
      dispatch(getArticles(payload.blId));
    }
  } catch (e) {
    console.log(e.message);
  }
};
export const getGroupImage = (payload) => async (dispatch) => {
  // console.log("[payload",payload)
  let { data } = await articleRepository.getGroupImage(payload);
  if (data.success) {
    // console.log(data)
    dispatch({
      type: "SET_IMAGE",
      payload: data.data,
    });
    dispatch(getArticles(payload.blId));
  }
}