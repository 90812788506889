import { GET_BUSINESSES } from 'store/types';
import { GET_BUSINESSES_LOADER } from 'store/types';
import { RepositoryFactory } from '../../repository/RepositoryFactory';
var businessesRepository = RepositoryFactory.get('businesses');
export const getLoader = val => async dispatch => {
	dispatch({
		type: GET_BUSINESSES_LOADER,
		payload: val,
	});
};
export const getBusinesses = () => async dispatch => {
	dispatch(getLoader(true));
	try {
		let { data } = await businessesRepository.getBusinesses();
		if (data.success) {
			dispatch({
				type: GET_BUSINESSES,
				payload: data.data,
			});
			dispatch(getLoader(false));
		} else {
			dispatch(getLoader(false));
		}
	} catch (e) {
		dispatch(getLoader(false));
	}
};
