/*!

=========================================================
* Vapiano Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Elktech (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Elktech

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Article from 'views/Article';
import Login from 'views/auth/Login';
import Index from 'views/Index.js';
import Logout from 'views/Logout';
import Summary from 'views/store/Summary';
import Store from 'views/Stores';

var routes = [
	// Store Routes
	{
		path: '/stores/summary',
		name: 'Stores',
		icon: '/img/sidebar/store.png',
		icon_active: '/img/white/store.png',
		select_icon: '/img/white/store.png',
		component: Summary,
		layout: '/admin',
	},
	{
		path: '/stores/setting',
		name: 'Stores Settings',
		icon: '/img/sidebar/home.png',
		select_icon: '/img/white/home.png',
		component: Index,
		isMenu: false,
		layout: '/admin',
	},
	{
		path: '/stores',
		name: 'Stores',
		icon: '/img/sidebar/home.png',
		select_icon: '/img/white/home.png',
		component: Store,
		isMenu: false,
		layout: '/admin',
	},
	// Store Routes Ended
	{
		path: '/article',
		name: 'Articles',
		icon: '/img/sidebar/spoon.png',
		icon_active: '/img/white/spoon.png',
		select_icon: '/img/white/spoon.png',
		layout: '/admin',
		component: Article,
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
		layout: '/auth',
		isMenu: false,
	},
	{
		path: '/logout',
		name: 'Logout',
		icon: '/img/sidebar/logout.png',
		icon_active: '/img/white/logout.png',
		select_icon: '/img/white/logout.png',

		component: Logout,
		layout: '/admin',
	},
];
export default routes;
