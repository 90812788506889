import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  CustomInput,
  Input,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "reactstrap";
import DropdownEditArticle from "./DropdownEditArticle";
import PageTitle from "./PageTitle";
import SettingsModal from "./SettingsModal";
import { getIngredients } from "store/actions/ingredientActions";
import { useDispatch, useSelector } from "react-redux";
import CustomSwitch from "./helper/CustomSwitch";
import { getAllCategories } from "store/actions/categoryActions";
import { Autocomplete, Chip, TextField } from "@mui/material";
import { getAllAllergens } from "store/actions/allergenAction";
import { getItemIngredients } from "store/actions/itemIngredientActions";
import { getAllCourses } from "store/actions/courseAction";
import { addDetailsInArticle } from "store/actions/article_detailsAction";
import ReactHtmlParser from "react-html-parser";

import {
  getDetailsByArticle,
  getArticleDescription,
} from "store/actions/article_detailsAction";
import { articleAction } from "store/actions/articleAction";
import { getAllStations } from "store/actions/stationActions";
import ProductMain from "../assets/img/PlatenKnife.png";
import { getAllCacheMenus } from "store/actions/menuActions";

const ArticalModal = (props) => {
  const [articleImage, setArticleImage] = useState("");
  const [finalTitle, setFinalTitle] = useState("");
  const [finalDesc, setFinalDesc] = useState("");
  const [finalPrice, setFinalPrice] = useState("");
  const [preparation_time, setPreparation_Time] = useState();
  const { categories } = useSelector((state) => state.category);
  const { allergens } = useSelector((state) => state.allergen);
  const dispatch = useDispatch();
  const { ingredients, getIngrLoader } = useSelector(
    (state) => state.ingredient
  );
  const { articles } = useSelector((state) => state.articles);
  const { item_ingredients } = useSelector((state) => state.item_ingredients);
  const { courses } = useSelector((state) => state.course);
  const {
    article_details,
    articleDetailsLoader,
    getArticleDetailsLoader,
    article_description,
  } = useSelector((state) => state.article_detail);
  const initial_list = useSelector(
    (state) => state.stationReducers.initial_list
  );
  const { blId } = useSelector((state) => state.blId);

  const { menus } = useSelector((state) => state.menu);
  //Course
  const [ingredientsData, setIngredientsData] = useState([]);

  //Course
  const [course, setCourse] = useState("");

  //Stations
  const [stations, setStations] = useState("");

  //Categories AutoComplete
  const fixedOptions = [categories];
  const [value, setValue] = useState([]);

  //Allergens AutoComplete
  const allergenFixedOptions = [allergens];
  const [allergenValue, setAllergenValue] = useState([]);

  //Menus AutoComplete

  const menuType = ["In Store", "TakeAway & Delivery", "Kiosk-Menu"];

  const menusFixedOptions = [menuType];
  const [menusValue, setMenusValue] = useState([]);

  //Ingredients group toggle
  const [checkedState, setCheckedState] = useState();
  //ingredeint-group id at ingredient-index and -1 for unselected
  const [checkedGroupID, setCheckedGroupID] = useState();
  const [mandatoryGroupID, setMandatoryGroupID] = useState();

  const toggleHandler = (position, id) => {
    // const newChecked = [...checkedState];
    // newChecked[position] = !checkedState[position];

    // setCheckedState(newChecked);
    // const updatedGroupID = [...checkedGroupID];

    // newChecked[position]
    //   ? (updatedGroupID[position] = id)
    //   : (updatedGroupID[position] = -1);
    // setCheckedGroupID(updatedGroupID);
    let newArray;
    newArray = [...checkedGroupID, id];
    if (checkedGroupID && checkedGroupID.some((a) => a === id)) {
      newArray = newArray.filter((b) => b !== id);
    }
    setCheckedGroupID(newArray);
  };

  useEffect(() => {
    //load existing mandatory data
    if (article_details?.mandatory?.length > 0) {
      let arr = [...article_details.mandatory];
      setMandatoryGroupID(arr);
    } else {
      setMandatoryGroupID([]);
    }
  }, [article_details?.mandatory]);

  const handleMandatoryToggle = (id) => {
    let newArray = [...mandatoryGroupID];

    if (newArray && newArray.some((a) => a === id)) {
      //already exists so uncheck
      newArray = newArray.filter((b) => b !== id);
    } else {
      newArray = [...newArray, id];
    }
    setMandatoryGroupID(newArray);
  };

  //Related Products AutoComplete

  // let idx =
  //   articles &&
  //   articles.length > 0 &&
  //   articles.findIndex((article) => article.group === props?.group);
  // LOGIC FOR SELECTED GROUP RELATED ITEMS
  // let relatedProductsData =
  // articles &&
  // articles.length > 0 &&
  // idx != -1 &&
  // articles[idx].items?.filter(
  //   (data) => data.productName !== props?.single?.productName
  // );
  // let arrs = [];
  // articles?.forEach((item) => {
  //   item.items
  //     .filter((data) => data.productName !== props?.single?.productName)
  //     .forEach((itm) => {
  //       arrs.push(itm);
  //     });
  // });

  //to add duplicate property of productName based on old structure
  let menuArr = menus
    ?.map((item) => {
      return {
        ...item,
        productName: item?.name,
      };
    })
    ?.filter(
      (data) =>
        data.productName !== props?.single?.productName &&
        data.menu_tags === "In Store"
    );

  // let relatedProductsData = arrs;
  let relatedProductsData = menuArr;

  let handlePreparationTimeChange = (e) => {
    e.preventDefault();
    let sign = Math.sign(e.target.value);
    if (sign == -1) {
      alert("Negative Value is not Allowed...!");
    } else {
      setPreparation_Time(e.target.value);
    }
  };

  const relatedProductsFixedOptions = [menuArr];
  // const relatedProductsFixedOptions = [
  //   articles &&
  //     articles.length > 0 &&
  //     idx != -1 &&
  //     articles[idx].items?.filter(
  //       (data) => data.productName !== props?.single?.productName
  //     ),
  // ];
  const [relatedProductsValue, setRelatedProductsValue] = useState([]);

  useEffect(() => {
    setFinalTitle(props?.single?.productName);
    // if (props?.single?.prices?.length > 0) {
    // 	setFinalPrice(props?.single?.prices[0].amount);
    // } else {
    // 	setFinalPrice(0);
    // }
    if (props?.single?.productPrice) {
      setFinalPrice(props?.single?.productPrice);
    } else {
      setFinalPrice(0);
    }

    if (props?.single?.sku) {
      dispatch(getIngredients(blId, props.single.sku));
    }

    if (props?.single?.sku) {
      dispatch(getArticleDescription(props.single.sku, blId));
    }

    if (categories && categories.length === 0) {
      dispatch(getAllCategories());
    }
    if (allergens && allergens.length === 0) {
      dispatch(getAllAllergens());
    }
    if (courses && courses.length === 0) {
      dispatch(getAllCourses());
    }
    if (articles && articles.length === 0) {
      // let ls = localStorage.getItem('blId');
      dispatch(articleAction(blId));
    }
    if (initial_list && initial_list.length === 0) {
      dispatch(getAllStations());
    }
    // dispatch(getItemIngredients(props?.single?.sku));
    if (props?.single?.sku) {
      dispatch(getDetailsByArticle(props?.single?.sku, blId));
    }
  }, [props.single]);

  useEffect(() => {
    //load exising single ingredient items
    if (article_details?.single_only?.length > 0) {
      var checked = new Array(ingredients?.length).fill(false);
      var ids = new Array(ingredients?.length).fill(-1);

      for (var i = 0; i < article_details?.single_only?.length; i++) {
        if (article_details?.single_only[i] !== -1) {
          checked[i] = true;
          ids[i] = article_details?.single_only[i];
        }
      }
      setCheckedGroupID(ids);
      setCheckedState(checked);
    } else {
      const checked = new Array(ingredients?.length).fill(false);
      const ids = new Array(ingredients?.length).fill(-1);
      setCheckedGroupID(ids);
      setCheckedState(checked);
    }
  }, [article_details.single_only, ingredients]);

  useEffect(() => {
    dispatch(getAllCacheMenus(blId));
  }, []);

  const data = [
    {
      day: "Saturday",
      date: "11:00-21:00",
      icon: <img src="/pen.png" alt="pen" height="10px" />,
    },
    {
      day: "Sunday",
      date: "11:00-21:00",
      icon: <img src="/pen.png" alt="pen" height="10px" />,
    },
    {
      day: "Monday",
      date: "11:00-21:00",
      icon: <img src="/pen.png" alt="pen" height="10px" />,
    },
    {
      day: "Wednesday",
      date: "11:00-21:00",
      icon: <img src="/pen.png" alt="pen" height="10px" />,
    },
    {
      day: "Thursday",
      date: "11:00-21:00",
      icon: <img src="/pen.png" alt="pen" height="10px" />,
    },
    {
      day: "Friday",
      date: "11:00-21:00",
      icon: <img src="/pen.png" alt="pen" height="10px" />,
    },
  ];
  const [show, setShowModal] = useState(false);
  const handleClose = () => {
    setShowModal(false);
    setCheckedGroupID([]);
    setCheckedState([]);
  };
  //Add Every Menu
  const handleEveryMenuClick = (e) => {
    e.preventDefault();
    setMenusValue(menuType);
  };

  //Add Data to DB
  const handleSubmit = (e) => {
    e.preventDefault();
    let obj = {
      blId: blId,
      article_id: props?.single?.sku,
      categories: value,
      allergens: allergenValue,
      menus: menusValue,
      course: course,
      related_products: relatedProductsValue,
      station: stations,
      ingredients: ingredientsData,
      image: articleImage,
      description: finalDesc,
      single_only: checkedGroupID.filter((data) => data != -1),
      prep_time: preparation_time,
      mandatory: mandatoryGroupID,
    };

    dispatch(
      addDetailsInArticle(obj, () => {
        setMenusValue([]);
        setAllergenValue([]);
        setValue([]);
        setFinalDesc("");
        setRelatedProductsValue([]);
        setCourse("");
        setStations("");
        setIngredientsData([]);
        props.setShowModal(-123);
        setCheckedGroupID([]);
        setCheckedState([]);
        setPreparation_Time();
        setMandatoryGroupID([]);
      })
    );
  };
  const handleImageChangeModal = (imageData) => {
    setArticleImage(imageData);
  };

  //Set Values
  useEffect(() => {
    if (
      article_description &&
      article_description.descriptions &&
      article_description.descriptions.length > 0 &&
      article_description.descriptions[1].description != ""
    ) {
      setFinalDesc(article_description?.descriptions[1].description);
    } else if (
      article_description &&
      article_description.descriptions &&
      article_description.descriptions.length > 0 &&
      article_description.descriptions[0].description != ""
    ) {
      setFinalDesc(article_description?.descriptions[0].description);
    } else {
      setFinalDesc("");
    }
    if (
      article_details &&
      article_details.menus &&
      article_details.menus.length > 0
    ) {
      setMenusValue(article_details.menus);
    } else {
      setMenusValue([]);
    }
    if (
      article_details &&
      article_details.allergens &&
      article_details.allergens.length > 0
    ) {
      setAllergenValue(article_details.allergens);
    } else {
      setAllergenValue([]);
    }
    if (
      article_details &&
      article_details.categories &&
      article_details.categories.length > 0
    ) {
      setValue(article_details.categories);
    } else {
      setValue([]);
    }
    if (
      article_details &&
      article_details.related_products &&
      article_details.related_products.length > 0
    ) {
      setRelatedProductsValue(article_details.related_products);
    } else {
      setRelatedProductsValue([]);
    }
    if (article_details && article_details.course) {
      setCourse(article_details.course);
    } else {
      setCourse("");
    }
    if (article_details && article_details.station) {
      setStations(article_details.station);
    } else {
      setStations("");
    }
    if (
      article_details &&
      article_details.ingredients &&
      article_details.ingredients.length > 0
    ) {
      setIngredientsData(article_details.ingredients);
    } else {
      setIngredientsData([]);
    }
    if (article_details && article_details.prep_time) {
      setPreparation_Time(article_details.prep_time);
    } else {
      setPreparation_Time("");
    }
  }, [article_details, article_description]);

  const handleArticalClose = () => {
    // setMenusValue([]);
    // setAllergenValue([]);
    // setValue([]);
    // setRelatedProductsValue([]);
    // setCourse("");
    // setStations("");
    // setIngredientsData([]);
    props.setShowModal(-123);
  };

  // console.log('XYZ', finalDesc);

  return (
    <>
      <Modal
        centered
        isOpen={props.showModal === props?.single.sku}
        className="modal-xl w-100"
        toggle={handleArticalClose}
      >
        <ModalBody
          style={{
            minHeight: "650px",
          }}
        >
          {/* <div className='d-flex align-items-center '>
						<PageTitle
							icon='/img/sidebar/spoon.png'
							title='Edit Article '
						/>
					</div> */}
          {getArticleDetailsLoader ? (
            <div
              style={{
                position: "absolute",
                top: "50%",
                right: "50%",
                transform: "translate(50%,-50%)",
              }}
            >
              <Spinner color="primary" />
              {/* <p>Please Wait, We are Fetching Your Data..</p> */}
            </div>
          ) : (
            <>
              <Row>
                <Col lg="6" md="6">
                  {" "}
                  <div className="d-flex align-items-center ">
                    <PageTitle
                      icon="/img/sidebar/spoon.png"
                      title="Edit Article "
                    />
                  </div>
                </Col>
                <Col lg="6" md="6">
                  {" "}
                  <div className="d-flex align-items-center justify-content-end ">
                    <Button color="primary" onClick={handleSubmit}>
                      {articleDetailsLoader ? <Spinner size="sm" /> : "save"}
                    </Button>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs="12" sm="12" md="12" lg="6">
                  <div className="d-flex flex-row  justify-content-between mt-3">
                    <div className="d-flex flex-column flex-wrap">
                      <label>
                        <b>Article Name</b>
                      </label>

                      <input
                        type="text"
                        className="form-control border-top-0 border-right-0 border-left-0 bg-transparent w-100"
                        value={finalTitle}
                        onChange={(e) => setFinalTitle(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-column ml-3 ">
                      <label>
                        <b>Price</b>
                        <i className="fa fa-money ml-2"></i>
                      </label>
                      <input
                        type="text"
                        className="form-control border-top-0 border-right-0 border-left-0 bg-transparent w-50"
                        value={finalPrice}
                      />
                    </div>
                    <div className="d-flex flex-column ml--5 ">
                      <label>
                        <b>Discount Price</b>
                        <i className="fa fa-money ml-2"></i>
                      </label>
                      <input
                        type="text"
                        className="form-control border-top-0 border-right-0 border-left-0 bg-transparent w-50"
                      />
                    </div>
                    <div></div>
                  </div>
                  <div className="mt-4">
                    <input
                      type="text"
                      className="form-control border-top-0 border-right-0 border-left-0 bg-transparent w-75"
                      placeholder="Print name"
                      size="sm"
                    />
                  </div>
                  <div className="mt-4">
                    <label>
                      <b>Description</b>
                    </label>
                    {/* <input
                      type="text"
                      className="form-control border-top-0 border-right-0 border-left-0 bg-transparent w-100"
                      placeholder="Description"
                      onChange={(e) => setFinalDesc(e.target.value)}
                      value={finalDescription}
                      size="sm"
                    /> */}
                    <div className="ml-2 border-top-0 border-right-0 border-left-0 bg-transparent w-100">
                      {ReactHtmlParser(finalDesc)}
                    </div>
                  </div>
                  <div className="mt-4">
                    <Input
                      type="select"
                      name="select"
                      className="form-control border-top-0 border-right-0 border-left-0 "
                      id="option"
                    >
                      <option selected>Article</option>
                      <option>Option 1</option>
                      <option>Option 2</option>
                      <option>Option 3</option>
                    </Input>
                  </div>
                  <div className="d-flex flex-row mt-4">
                    <Input
                      type="select"
                      name="select"
                      className="form-control border-top-0 border-right-0 border-left-0 w-50"
                      id="option"
                    >
                      <option selected>0%</option>
                      <option>Option 1</option>
                      <option>Option 2</option>
                      <option>Option 3</option>
                    </Input>
                    <input
                      type="text"
                      className="form-control border-top-0 border-right-0 border-left-0 bg-transparent w-50"
                      placeholder="Weight"
                      size="sm"
                    />
                  </div>
                  <div className="mt-4">
                    <input
                      type="text"
                      className="form-control border-top-0 border-right-0 border-left-0 bg-transparent w-75"
                      placeholder="Minimum order amount required, 0.00$"
                      size="sm"
                    />
                  </div>
                  <div className="mt-4">
                    <input
                      onChange={(event) =>
                        handleImageChangeModal(event.target.files[0])
                      }
                      className="form-control border-top-0 border-right-0 border-left-0 bg-transparent w-75"
                      placeholder="Upload image"
                      size="sm"
                      type="file"
                      accept="image/png, image/gif, image/jpeg, image/jpg"
                    ></input>
                  </div>

                  {article_details?.image ? (
                    <img
                      className="article__img"
                      alt="des"
                      src={article_details?.image}
                    />
                  ) : (
                    <img className="article__img" alt="des" src={ProductMain} />
                  )}
                  <div className="mt-4">
                    <label className="d-flex justify-content-center">
                      <b>Open hours</b>
                    </label>
                    {data.map((d, i) => {
                      return (
                        <div className=" d-flex justify-content-between mt-2 p-2">
                          <div className="col-sm">{d.day}</div>
                          <div className="col-sm">{d.date}</div>
                          <div className="col-sm">
                            <div className="col-sm">
                              <i
                                className="fa fa-pencil"
                                onClick={() => {
                                  setShowModal(true);
                                }}
                              ></i>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Col>
                {/* right side */}
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg="6"
                  className="ArticleModalRightCol"
                  style={{ overflow: "auto" }}
                >
                  <label className="mt-3">
                    <b>Allergens</b>
                  </label>
                  <Autocomplete
                    multiple
                    id="fixed-tags-demo"
                    value={allergenValue}
                    onChange={(event, newValue) => {
                      setAllergenValue([
                        // ...fixedOptions,

                        ...newValue.filter(
                          (option) =>
                            allergenFixedOptions.indexOf(option) === -1
                        ),
                      ]);
                    }}
                    options={
                      allergens &&
                      allergens.filter((item) => {
                        // console.log(allergens)
                        return !allergenValue.find((el) => {
                          return el.title === item.title;
                        });
                      })
                    }
                    getOptionLabel={(option) => option.title}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          label={option.title}
                          {...getTagProps({ index })}
                          disabled={allergenFixedOptions.indexOf(option) !== -1}
                        />
                      ))
                    }
                    style={{ width: 450 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label=''
                        placeholder="Select Allergens"
                      />
                    )}
                  />
                  <label className="mt-5">
                    <b>Categories</b>
                  </label>
                  <Autocomplete
                    multiple
                    id="fixed-tags-demo"
                    value={value}
                    onChange={(event, newValue) => {
                      setValue([
                        // ...fixedOptions,
                        ...newValue.filter(
                          (option) => fixedOptions.indexOf(option) === -1
                        ),
                      ]);
                    }}
                    options={
                      categories &&
                      categories.filter((item) => {
                        return !value.find((el) => {
                          return el.title === item.title;
                        });
                      })
                    }
                    getOptionLabel={(option) => option.title}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          label={option.title}
                          {...getTagProps({ index })}
                          disabled={fixedOptions.indexOf(option) !== -1}
                        />
                      ))
                    }
                    style={{ width: 450 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label=''
                        placeholder="Select Categories"
                      />
                    )}
                  />
                  {/* Every Station */}
                  <div className="mt-6">
                    <label className="mt-3">
                      <b>Stations</b>
                    </label>
                    <Input
                      type="select"
                      name="select"
                      className="form-control border-top-0 border-right-0 border-left-0 inputs-group__articlemodal"
                      id="option"
                      value={stations}
                      onChange={(e) => setStations(e.target.value)}
                    >
                      <option>Select Station</option>
                      {initial_list &&
                        initial_list.map((data) => {
                          return (
                            <option value={data.title}>{data.title}</option>
                          );
                        })}
                    </Input>
                  </div>
                  {/* subGroup */}
                  <div className="mt-6 inputs-group__articlemodal">
                    <label className="mt-3">
                      <b>Preparation Time(in seconds)</b>
                    </label>
                    <input
                      placeholder="Preparation Time in seconds"
                      type="number"
                      name="select"
                      className="form-control border-top-0 border-right-0 border-left-0 inputs-group__articlemodal"
                      value={preparation_time}
                      onChange={(e) => handlePreparationTimeChange(e)}
                    />
                  </div>
                  {/* Every Menu */}
                  <div className="mt-6">
                    <label className="mt-3">
                      <b>Menu</b>
                    </label>
                    <Autocomplete
                      multiple
                      id="fixed-tags-demo"
                      value={menusValue}
                      onChange={(event, newValue) => {
                        setMenusValue([
                          // ...fixedOptions,
                          ...newValue.filter(
                            (option) => menusFixedOptions.indexOf(option) === -1
                          ),
                        ]);
                      }}
                      options={menuType}
                      getOptionLabel={(option) => option}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            label={option}
                            {...getTagProps({ index })}
                            disabled={menusFixedOptions.indexOf(option) !== -1}
                          />
                        ))
                      }
                      style={{ width: 450 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // label=''
                          placeholder="Select Menus"
                        />
                      )}
                    />

                    <button
                      onClick={handleEveryMenuClick}
                      type="button"
                      className="btn btn-light btn-sm mt-2"
                    >
                      Every Menu
                    </button>
                  </div>
                  {/* Every Course */}
                  <div className="mt-6 inputs-group__articlemodal">
                    <label className="mt-3">
                      <b>Courses</b>
                    </label>
                    <Input
                      type="select"
                      name="select"
                      className="form-control border-top-0 border-right-0 border-left-0 "
                      id="option"
                      value={course}
                      onChange={(e) => setCourse(e.target.value)}
                    >
                      <option>Select Course</option>
                      {courses &&
                        courses.map((data) => {
                          return (
                            <option value={data.title}>{data.title}</option>
                          );
                        })}
                    </Input>
                  </div>
                  {/* Every Related Products */}
                  <div className="mt-6">
                    <label className="mt-3">
                      <b>Related Products</b>
                    </label>
                    <Autocomplete
                      multiple
                      id="fixed-tags-demo"
                      value={relatedProductsValue}
                      onChange={(event, newValue) => {
                        if (newValue.length > 2) {
                          alert("Maximum Limit Reached");
                        } else {
                          setRelatedProductsValue([
                            // ...fixedOptions,
                            ...newValue.filter(
                              (option) =>
                                relatedProductsFixedOptions.indexOf(
                                  option?.productName
                                ) === -1
                            ),
                          ]);
                        }
                      }}
                      options={
                        // relatedProductsData
                        relatedProductsData &&
                        relatedProductsData?.filter((data) => {
                          return !relatedProductsValue.find((el) => {
                            return el.productName === data.productName;
                          });
                        })
                      }
                      getOptionLabel={(option) => option?.productName}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            label={option?.productName}
                            {...getTagProps({ index })}
                            disabled={
                              relatedProductsFixedOptions.indexOf(option) !== -1
                            }
                          />
                        ))
                      }
                      style={{ width: 450 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // label=''
                          placeholder="Select Products"
                        />
                      )}
                    />
                  </div>
                  {/* Open Article */}
                  <div className="mt-6 inputs-group__articlemodal">
                    <DropdownEditArticle
                      title={"Open Article"}
                      selectedValue={"Select"}
                    />

                    <button type="button" className="btn btn-light btn-sm mt-2">
                      Create New
                    </button>
                  </div>
                  <br />
                  <div className="mt-2 form-group itemIngredients">
                    {getIngrLoader ? (
                      <Spinner
                        className="d-flex mx-auto mt-5"
                        size="lg"
                        color="primary"
                      />
                    ) : (
                      <>
                        {ingredients.map((val, ind) => {
                          return (
                            <>
                              {/* <CustomSwitch value={val.group} /> */}
                              <div className="d-flex flex-row">
                                <h6>{val?.group}</h6>
                                &nbsp; &nbsp;
                                <label>Selective: &nbsp;</label>
                                <CustomInput
                                  type="switch"
                                  id={val.id}
                                  checked={checkedGroupID?.some(
                                    (v) => v === val.id
                                  )}
                                  // checked={checkedState[ind]}
                                  onChange={() => {
                                    toggleHandler(ind, val.id);
                                  }}
                                />
                                <label>Mandatory: &nbsp;</label>
                                <CustomInput
                                  type="switch"
                                  id={val.id + 100}
                                  checked={mandatoryGroupID?.some(
                                    (v) => v === val.id
                                  )}
                                  onChange={() => {
                                    handleMandatoryToggle(val.id);
                                  }}
                                />
                              </div>
                              <div className="ml-4">
                                {val.items.map((item, index) => {
                                  let obj = {
                                    ingredient_name: item.productName,
                                    ingredient_sku: item.sku,
                                    ingredient_price: item.productPrice,
                                    ingredient_group: val.group,
                                    group_id: val.id,
                                  };
                                  return (
                                    <CustomSwitch
                                      value={obj}
                                      check={ingredientsData}
                                      setIngredientsData={setIngredientsData}
                                    />
                                  );
                                })}
                              </div>
                            </>
                          );
                        })}
                      </>
                    )}
                  </div>
                </Col>
                <SettingsModal show={show} handleClose={handleClose} />
              </Row>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default ArticalModal;
