import React from 'react';
import PromotionSection from 'components/PromotionSection';
import PageTitle from 'components/PageTitle';
import { Col, Row } from 'reactstrap';
import PromotionSectionTakeaway from 'components/PromotionSectionTakeaway';

const Promotions = () => {
	return (
		<>
		<Row className='p-3'>
			<Col xs="12" md="6" sm="12" lg="6" >
		<div className=' card border border-light rounded  head p-2 '>
			<PageTitle icon='/img/sidebar/mike.png' title='Promotions' />
		</div>
		</Col>
		<Col xs="12" md="12" sm="12" lg="12" >
		<div className=' p-2 pb-1'>
			<PromotionSection title={'Instore'} />
			<PromotionSectionTakeaway title={'Takeaway & Delivery'} />
		</div>
		</Col>
		</Row>
		</>
	);
};
export default Promotions;
