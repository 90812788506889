import PageTitle from "components/PageTitle";
import React from "react";
import { Col, Row } from "reactstrap";
const GuestRegistration = () => {
  return (
    <>
      <Row className="p-3">
        <Col lg="6" sm="12" md="6" xs="12">
          <div className="card p-2 border border-light rounded">
            <PageTitle
              icon={"/img/sidebar/pen.png"}
              title="Guest Registration"
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default GuestRegistration;
