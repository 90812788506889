import { RepositoryFactory } from '../../repository/RepositoryFactory';
var courseRepository = RepositoryFactory.get('course');

export const getCourseLoader = val => async dispatch => {
	dispatch({
		type: 'GET_COURSE_LOADER',
		payload: val,
	});
};

export const getAllCourses = () => async dispatch => {
	dispatch(getCourseLoader(true));
	const { data } = await courseRepository.getAllCourses();
	try {
		if (data.success) {
			dispatch({
				type: 'GET_COURSE',
				payload: data.data,
			});
			dispatch(getCourseLoader(false));
		} else {
			dispatch(getCourseLoader(false));
		}
	} catch (e) {
		dispatch({
			type: 'GET_COURSE',
			payload: [],
		});
		dispatch(getCourseLoader(false));
	}
};
