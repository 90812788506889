import React,{useState,useEffect} from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { Button, Col, Row } from "reactstrap";
import PageTitle from "./PageTitle";
import ChangingProgressProvider from "./helper/AnimationProgressProvider";
const ReportCard = ({headerTitle  }) => {
  const [percentage, setPercentage]= useState(100);
  useEffect(()=>{
    setPercentage( Math.floor(Math.random() * 100));

  },[percentage])
  const [hoverIcon, setHoverIcon] = useState(-1);
  return (
    <>
      <Row className="mt-4 d-flex justify-content-center">
      <Col lg='6'>
      <Row className='gutter-1'>
      <Col xs='7' sm='8'>
            {/* sotres total */}
            <div className="card p-3 border border-light rounded w-100 h-100">
              <PageTitle
                icon={"/img/sidebar/profit.png"}
                title={headerTitle}
              />
              <div className=" d-flex justify-content-center font-size-70">
                <h1 className="gradient-green">5000</h1>
                <h1 className="euro text-muted">€</h1>
              </div>
              <div
                className=" d-flex justify-content-center"
                style={{ height: "150px" }}
              >
                <ChangingProgressProvider values={[0, percentage]}>
                  {(value) => (
                    <CircularProgressbar
                      className="mb-2"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      value={value}
                      text={`${percentage}%`}
                      styles={buildStyles({
                        textColor: "#73d383",
                        pathColor: "#73d383",
                        trailColor: "#f8f8ff",
                        textSize: "20px",
                        pathTransition:
                          percentage === 0
                            ? "none"
                            : "stroke-dashoffset 0.5s ease 0s",
                      })}
                    />
                  )}
                </ChangingProgressProvider>
              </div>
              <div className="d-flex justify-content-center mt-2">
                <Button className="btn-darkblue" color="primary">
                  COMPARE
                </Button>
              </div>
            </div>
            </Col>
            {/* Total Income  Fin*/}

            {/* Stats Start */}
            <Col xs='5' sm='4'>
            <div className="card p-3 border border-light rounded ml-md-2 h-100">
              <div className="d-flex flex-row ">
                <div className="mr-2">
                  <img src="/img/sidebar/stats.png" alt="store" height="30px" />
                </div>
                <h4 className="mt-1 text-dark">
                  <b>Stats</b>
                </h4>
              </div>
              <div className="mt-3 text-left">
                <div className="d-flex flex-row justify-content-left">
                  <img
                    src="/green-circle.png"
                    alt="green"
                    height="10px"
                    className="mr-2 mt-1"
                  />
                  <h6 className="text-dark ">
                    <b>Last Week</b>
                  </h6>
                </div>
                <h2 className="text-dark ml-2">
                  <b>-7%</b>
                </h2>
              </div>
              <div className="mt-3 text-left">
                <div className="d-flex flex-row justify-content-left">
                  <img
                    src="/ellipse.png"
                    alt="ellipse"
                    height="10px"
                    className="mr-2 mt-1"
                  />
                  <h6 className="text-dark ">
                    <b>Forecast</b>
                  </h6>
                </div>
                <h2 className="text-dark ml-2">
                  <b>10%</b>
                </h2>
              </div>
              <div className="mt-3 text-left">
                <div className="d-flex flex-row justify-content-left">
                  <img
                    src="/purple-dot.png"
                    alt="ellipse"
                    height="10px"
                    className="mr-2 mt-1"
                  />
                  <h6 className="text-dark ">
                    <b>Yesterday</b>
                  </h6>
                </div>
                <h2 className="text-dark ml-2">
                  <b>+10%</b>
                </h2>
              </div>
            </div>
            </Col>
					</Row>
				</Col>
            {/* Stats Fin */}
          
        {/* </Col> */}
        <Col lg="6">
          <div className="text-center mt-4">
            <h5 className="text-dark">MAIN FILTERS</h5>
            <div className="d-flex flex-row justify-content-center">
                
                  <Button color="primary" className="btn-darkblue"
                     onMouseEnter={()=>setHoverIcon(1)}
                     onMouseLeave={()=>setHoverIcon(-1)}>
                    <div className="d-flex flex-row">
                      <img
                        src={hoverIcon === 1 ? "/img/white/coins.png":"/img/ReportPage/coins.png"}
                        alt="coins"
                        height="20px"
                      />
                      <small className="ml-2">REVENUE</small>
                    </div>
                  </Button>
                
                  <Button color="primary" className="btn-darkblue text-nowrap"
                   onMouseEnter={()=>setHoverIcon(2)}
                   onMouseLeave={()=>setHoverIcon(-1)}>
                    <img
        
                      src={hoverIcon === 2 ? "/img/white/invoice.png":"/img/ReportPage/invoice.png"}

                      alt="ticket"
                      height="20px"
                    />
                    <small className="ml-2">TICKETS</small>
                  </Button>
                
                  <Button
                    color="primary"
                    className="btn-darkblue text-nowrap"
                    onMouseEnter={()=>setHoverIcon(3)}
                    onMouseLeave={()=>setHoverIcon(-1)}
                  >
                    <img 
                      src={hoverIcon === 3 ? "/img/white/user-blank.png":"/img/ReportPage/user-blank.png"}
                      alt="coins"
                      height="18px"
                    />
                    <small className="ml-2 ">GUESTS</small>
                  </Button>
            </div>
          </div>
          <div className="text-center mt-2">
            <h5 className="text-dark">TIME FILTERS</h5>
            <div className="d-flex flex-row justify-content-center">
              <Button color="primary" className="btn-filter">
                WEEKLY
              </Button>
              <Button color="primary" className="btn-filter">
                MONTH
              </Button>
              <Button color="primary" className="btn-filter">
                YEAR
              </Button>
            </div>
            <div className="text-center mt-2">
              <div className="d-flex flex-row justify-content-center">
                <Button color="primary" className="btn-filter">
                  YESTERDAY
                </Button>
                <Button color="primary" className="btn-filter">
                  TODAY
                </Button>
                <Button color="primary" className="btn-filter">
                  TOMORROW
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ReportCard;
