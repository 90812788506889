import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
// import MButton from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { display } from "@mui/system";
import { Spinner, Button } from "reactstrap";
// import io from "socket.io-client";
// const socket = io.connect("https://vapiano-staging.herokuapp.com/");
const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  paddingBottom: 5,
};

export default function BasicModal({ open, setOpen, tableNo, _id }) {
  // const handleOpen = () => setOpen(true);
  const [value, setValue] = useState(null);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleClose = () => setOpen(false);

  const handleSubmit = () => {
    const msg = {
      // problemID: uuidv4(),
      tableNo: tableNo,
      sessionID: _id,
      message: value.trim(),
    };
    // socket.emit("Problem_", msg);

    setOpen(false);
    setValue("");
  };
  return (
    <div>
      {/* <Button onClick={OnClick}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            textAlign={"center"}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Tell us about your problam.
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "15px 0px",
            }}
          >
            <TextField
              id="outlined-multiline-flexible"
              label="Problam"
              multiline
              maxRows={4}
              value={value}
              onChange={handleChange}
            />
          </Box>
          <Button
            type="submit"
            className="btn btn-success btn-sm"
            onClick={handleSubmit}
            style={{ position: "absolute", right: 85 }}
          >
            Send
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
