import PageTitle from 'components/PageTitle';
import React, { useState } from 'react';
import {
	Button,
	Col,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
	Table,
} from 'reactstrap';
const Subgroups = () => {
	let [addSubgroupModal, setAddSubgroupModal] = useState(false);
	const toggleAddSubgroupModal = () => {
		setAddSubgroupModal(!addSubgroupModal);
	};
	return (
		<>
		<Row>
			<Col xs="12" sm="12" md="6" lg="6">
			<div className='card border border-light rounded p-2'>
				<PageTitle title='Subgroups' icon='/img/sidebar/object.png' />
				</div>
				</Col>
			<Col xs="12" sm="12" md="6" lg="6">

				<Button
					color='primary'
					className='mt-3 mt-sm-0 float-right'
					onClick={toggleAddSubgroupModal}
				>
					Add Subgroup
					<i className='ml-2 fa fa-plus-circle'></i>
				</Button>
			</Col>
			<Col xs="12" sm="12" md="12" lg="12">

			<Table responsive className='mt-3'>
				<thead>
					<tr>
						<th>ID</th>
						<th>Subgroup</th>
						<th>Visible</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>A</td>
						<td>A</td>
						<td>A</td>
					</tr>
					<tr>
						<td>A</td>
						<td>A</td>
						<td>A</td>
					</tr>
				</tbody>
			</Table>
			</Col>
			</Row>
			<Modal
				centered
				isOpen={addSubgroupModal}
				toggle={toggleAddSubgroupModal}
			>
				<ModalHeader toggle={toggleAddSubgroupModal}>
					Add Subgroup
				</ModalHeader>
				<Form>
					<ModalBody>
						<FormGroup>
							<Label>Subgroup Name</Label>
							<Input type='text' />
						</FormGroup>
					</ModalBody>
					<ModalFooter>
						<Button color='primary'>Save</Button>
					</ModalFooter>
				</Form>
			</Modal>
		</>
	);
};

export default Subgroups;
