import { RepositoryFactory } from 'repository/RepositoryFactory'
import * as settings from '../Types/settingsTypes'

let AppSetting = RepositoryFactory.get('appSettings')

export const generalSettingAction = val => async dispatch => {
    console.log(val, 'the value ....')
    dispatch({ type: settings.LOADING_SETTING })
    let { data } = await AppSetting.generalSettings(val);
    console.log(data, 'The Hitted Res')
    dispatch({
        type: settings.UPDATE_GENERAL_SETTING,
        payload: data,
    })

}

export const updateAppColors = (payload, id) => async dispatch => {
    try {
        dispatch({ type: settings.LOADING_SETTING })
        let response = await AppSetting.updateAppColor(payload, id)
        if (response?.data?.success) {
            dispatch({ type: settings.APP_COLOR_UPDATE, payload: response.data.data })
        }
    }
    catch (e) {
        console.log("Error updating App Colors", e)
        dispatch({ type: settings.LOADING_FAILED })
    }
}

export const updateRightIcons = (payload, id) => async dispatch => {
    try {
        dispatch({ type: settings.LOADING_SETTING })
        let response = await AppSetting.updateRightIcon(payload)
        if (response?.data?.success) {
            // console.log('icon update response, ', response)
            alert(response?.data?.message)
            let payload={ message: response?.data?.message, data: response?.data?.data }
            dispatch({ type: settings.RIGHT_ICON_UPDATE, payload:payload})
        }
    }
    catch (e) {
        console.log("Error updating icons", e)
        alert('Submission Failed')
        dispatch({ type: settings.LOADING_FAILED })
    }
}

export const getBusinessSettings = (payload) => async dispatch => {
    try {
        dispatch({ type: settings.LOADING_SETTING })
        let response = await AppSetting.getBusinessDetails(payload)
        if (response?.data?.success) {
            dispatch({ type: settings.FETCH_ALL_SETTINGS, payload: response.data.data })
        }
    }
    catch (e) {
        console.log("Error updating App Colors", e)
        dispatch({ type: settings.LOADING_FAILED })
    }
}
