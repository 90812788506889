import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Checkbox } from '@mui/material';
import { Button, Card, CardBody, Col, Collapse, Row, Table } from 'reactstrap';
import {
	OrderTakeAway,
	CollapseView,
} from '../components/DummyData/OrderTakeAwayData';
import { useLocation } from 'react-router-dom';
const columns = [
	{ field: 'id', headerName: 'ID', width: 70 },
	{ field: 'merchant', headerName: 'Merchant', width: 130 },
	{
		field: 'pos',
		headerName: 'POS',
		width: 130,
		cellClassName: ' text-danger',
	},
	{
		field: 'status',
		headerName: 'Status',
		width: 130,
		cellClassName: 'text-success',
	},
	{ field: 'paymentStatus', headerName: 'Payment Status', width: 130 },
	{ field: 'posAttemps', headerName: 'POS Attempts', width: 130 },
	{ field: 'posStatus', headerName: 'POS Status', width: 130 },
	{ field: 'typeTable', headerName: 'Type Table', width: 130 },
	{ field: 'total', headerName: 'Total', width: 130 },
	{ field: 'paymentMethod', headerName: 'Payment Method', width: 130 },
	{ field: 'createdAt', headerName: 'Created At', width: 130 },
	{ field: 'by', headerName: 'By', width: 130 },
];
const OrdersTable = ({ expand, toggle, setToggle }) => {
	const location = useLocation();
	const [CollapseDataView, setCollapseView] = useState([]);

	useEffect(() => {
		switch (location.pathname) {
			case '/store/order':
				setCollapseView(OrderTakeAway);
				// setCollapseView(CollapseView);
				break;
			case '/store/takeaway-delivery/orders':
				setCollapseView(OrderTakeAway);
				break;
			case '/store/in-store/orders':
				// setCollapseView(CollapseView);
				setCollapseView(OrderTakeAway);

				break;
			default:
				setCollapseView([]);
				break;
		}
	}, []);

	return (
		<>
			<div className='form-check p-3 ml-3 '>
				<input
					className='form-check-input'
					type='checkbox'
					value=''
					id='flexCheckChecked'
					checked
				/>
				<label className='form-check-label' for='flexCheckChecked'>
					Auto Refresh after 15 seconds
				</label>
			</div>
			<div>
				{CollapseDataView.map((data, i) => {
					return (
						<>
							<div className='card '>
								<div
									className='d-flex flex-row justify-content-between p-3 '
									onClick={() => {
										setToggle(toggle != i ? i : -1);
									}}
									style={{ cursor: 'pointer' }}
								>
									<div>{data.id}</div>
									<div>{data.date}</div>
									<div>{data.name}</div>
									<div>{data.Shop}</div>
									<div>{data.items.length} item(s)</div>
									<div>{data.Totaal}</div>
									<div style={{ marginBottom: '1rem' }}>
										<i className='fa fa-arrow-down'></i>
									</div>
								</div>
								<Collapse isOpen={expand ? true : toggle == i}>
									<Row>
										<Col sm='12' xs='12' md='6' lg='6'>
											<div
												style={{ fontSize: '12px' }}
												className='p-3'
											>
												<span>
													Bestelling afhalen om
													5:15:00 PM 4/8/2022
												</span>
												<br />
												<span>Naam: {data.name}</span>
												<br />
												<span>Email: {data.email}</span>
												<br />
												<span>
													Telefoon: {data.telephone}
												</span>
											</div>
										</Col>
										<Col
											sm='12'
											xs='12'
											md='6'
											lg='6'
											className='border-left'
										>
											<Table responsive>
												<thead>
													<th>Naam</th>
													<th>Prijs per stuk</th>
													<th>Hoeveelheid</th>
													<th>Totaal</th>
												</thead>
												<tbody>
													{data.items.map((dt, i) => {
														return (
															<tr>
																<td>
																	<div>
																		<span>
																			<b>
																				{
																					dt.naam
																				}
																			</b>
																		</span>
																		<br />
																		{dt?.options
																			? dt.options.map(
																					(
																						opt,
																						i
																					) => {
																						return (
																							<div>
																								<span className='text-muted'>
																									{
																										opt
																									}
																								</span>
																								<br />
																							</div>
																						);
																					}
																			  )
																			: ''}
																	</div>
																</td>
																<td>
																	<div>
																		<span>
																			<b>
																				{
																					dt.Prijs_per_stuk
																				}
																			</b>
																		</span>
																		<br />
																		{dt?.rates
																			? dt.rates.map(
																					(
																						rt,
																						i
																					) => {
																						return (
																							<div>
																								<span className='text-muted'>
																									{
																										rt
																									}
																								</span>
																								<br />
																							</div>
																						);
																					}
																			  )
																			: ''}
																	</div>
																</td>
																<td>
																	{
																		dt.Hoeveelheid
																	}
																</td>
																<td>
																	{dt.Totaal}
																</td>
															</tr>
														);
													})}
												</tbody>
											</Table>
										</Col>
									</Row>
								</Collapse>
							</div>
						</>
					);
				})}
			</div>
		</>
	);
};
export default OrdersTable;
