let initState = {
  getFloorPlansLoader: false,
  floorPlans: [],
  getSessionsLoader: false,
  sessions: [],
  removeSessionLoader: false,
};
export default function (state = initState, action) {
  switch (action.type) {
    case "GET_FLOOR_PLANS":
      return {
        ...state,
        floorPlans: action.payload,
      };
    case "GET_FLOOR_PLANS_LOADER":
      return {
        ...state,
        getFloorPlansLoader: action.payload,
      };
    case "GET_SESSIONS":
      return {
        ...state,
        sessions: action.payload,
      };
    case "GET_SESSIONS_LOADER":
      return {
        ...state,
        getSessionsLoader: action.payload,
      };
    case "REMOVE_SESSION_LOADER":
      return {
        ...state,
        removeSessionLoader: action.payload,
      };
    default:
      return { ...state };
  }
}
