import { TablePagination } from "@mui/material";
import PageTitle from "components/PageTitle";
import React, { useState } from "react";
import { Col, Modal, ModalBody, Row, Table } from "reactstrap";

const LimitedOrders = () => {
  const value = "Vapiano Düsseldorf Medienhafen";
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const toggle = () => setShow(!show);
  const TimingOrders = [
    {
      day: "Monday",
      from: "09:00am",
      upto: "06:00pm",
    },
    {
      day: "Tuesday",
      from: "09:00am",
      upto: "06:00pm",
    },
    {
      day: "Wednesday",
      from: "09:00am",
      upto: "06:00pm",
    },
    {
      day: "Thursday",
      from: "09:00am",
      upto: "06:00pm",
    },
    {
      day: "Friday",
      from: "09:00am",
      upto: "06:00pm",
    },
    {
      day: "Saturday",
      from: "-- : --am",
      upto: "-- : --pm",
    },
    {
      day: "Sunday",
      from: "-- : --am",
      upto: "-- : --pm",
    },
  ];

  return (
    <>
      <Row className="p-3">
        <Col xs="12" sm="6" md="6" lg="6">
          <div className="card p-2 border border-light rounded">
            <PageTitle title={`Settings - ${value}`} icon="" />
          </div>
        </Col>
        <Col xs="12" sm="12" md="12" lg="12">
          <div className="card mt-2 border border-light rounded">
            <Table responsive>
              <thead>
                <tr>
                  <th>Day</th>
                  <th>From time of day</th>
                  <th>Upto time of day</th>
                </tr>
              </thead>
              <tbody>
                {TimingOrders.map((t, index) => {
                  return (
                    <tr>
                      <td>{t.day}</td>
                      <td>{t.from}</td>
                      <td>{t.upto}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Col>
        <Col xs="12" sm="12" md="12" lg="12">
          <div className="mt-2 p-2">
            <button className="btn btn-primary " onClick={()=>{setShow(true)}}>New</button>
            <button className="btn btn-primary" >Save</button>
          </div>
        </Col>
      </Row>
      <Modal
       centered 
       isOpen={show}
       
       onHide={handleClose}  
       toggle={toggle}>
       
        <ModalBody >
            <div className="p-3">
        <div className="d-flex flex-row justify-content-around ">
            <div className="mt-3"> 
              <span>Day</span>
              </div>
            <div>
              <select className="form-control form-control-md mb-3 border-right-0 border-left-0 border-top-0">
                <option value="1">Monday</option>
                <option value="2">Tuesday</option>
                <option value="3">Wednesday</option>
                <option value="4">Thursday</option>
                <option value="5">Friday</option>
                <option value="6">Saturday</option>
                <option value="7">Sunday</option>
              </select>
            </div>
            </div>
            <div className="d-flex flex-row justify-content-around ">
            <div className="mt-3"> 
              <span>From time</span>
              </div>
            <div>
             <input type="time" className="form-control form-control-md border-right-0 border-left-0 border-top-0"/>
            </div>
            </div>
            <div className="d-flex flex-row justify-content-around ">
            <div className="mt-3"> 
              <span>Up time</span>
              </div>
            <div>
             <input type="time" className="form-control form-control-md border-right-0 border-left-0 border-top-0"/>
            </div>
            </div>
            <div className="d-flex flex-row justify-content-around ">
            <div className="mt-3 text-align-center"> 
              <span>Max takeaway orders</span>
              </div>
            <div>
             <input type="text" className="form-control form-control-md border-right-0 border-left-0 border-top-0"/>
            </div>
            </div>
            <div className="d-flex flex-row justify-content-around ">
            <div className="mt-3"> 
              <span>Max delivery orders</span>
              </div>
            <div>
             <input type="text" className="form-control form-control-md border-right-0 border-left-0 border-top-0"/>
            </div>
            </div>
          
            <div className="d-flex flex-row float-right mt-3">
            <input type="button" className="btn btn-mute m-2" value="DELETE" /> 
            <input type="button" className="btn btn-mute m-2" onClick={()=>{setShow(false)}} value="CANCEL" /> 
            <input type="button" className="btn btn-mute m-2 " disabled value="SAVE" /> 
            </div>
            </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default LimitedOrders;
