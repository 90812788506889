import CategoryModal from "components/CategoryModal";
import PageTitle from "components/PageTitle";
import React, { useState, useEffect } from "react";
import {
  Badge,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import food from "../../../assets/img/food.jpg";
import operate from "../../../assets/img/product-main.jpg";
// import { CategoriesData } from '../../../components/DummyData/Categories';
import axios from "axios";
import { useDispatch, useSelector, connect } from "react-redux";
import StationModal from "components/StationModal";
import { Autocomplete, Chip, TextField } from "@mui/material";
import { getAllStations } from "store/actions/stationActions";
import {
  getAllUsers,
  createUser,
  getUsersLoader,
  deleteUser,
  updateUser
} from "store/actions/userAction";
import { getAllCourses } from "store/actions/courseAction";
import { allergenLoader } from "store/actions/allergenAction";

const Users = () => {
  let [isEditModal, setisEditModal] = useState(false);
  let [isAddModal, setisAddModal] = useState(false);
  const [title, setTitle] = useState("");
  const [editPinCode, setEditPinCode] = useState("");
  const [addUserName, setAddUserName] = useState("");
  const [addUserPinCode, setAddUserPinCode] = useState("");
  const [Role , setRole] = useState("Manager")
  const [updatedRole , setUpdatedRole] = useState("")
  const { blId } = useSelector((state) => state.blId);
  const [editId, setEditId] = useState("");
  const [Edit , setEdit] = useState(false)

  const initial_list = useSelector(
    (state) => state.stationReducers.initial_list
  );
  const { users, getUsersLoader, addUserLoader, updateUserLoader } =
    useSelector((state) => state.user);
  const fixedOptions = [initial_list];
  const editFixedOptions = [initial_list];
  const [value, setValue] = useState([]);
  const [editValue, setEditValue] = useState([]);
  const [updatedUserName,setUpdatedUserName] = useState("")

  let editStations = (data) => {
    let finalData = [];
    finalData =
      initial_list &&
      initial_list.filter((item) => {
        return data.find((el) => {
          if (el.title) {
            return el.title === item.title;
          } else {
            return el === item.title;
          }
        });

      });
    return finalData;
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (initial_list && initial_list.length == 0) {
      dispatch(getAllStations());
    }
  }, [initial_list]);

  useEffect(() => {
    dispatch(getAllUsers(blId));
  }, []);

  const handleClose = () => {
    setisEditModal(false);
    setTitle("");
    setEditPinCode("");
    setEditValue([]);
    setRole("Manager");
  };
  const handleCloseAdd = () => {
    setisAddModal(false);
    setValue([]);
    setTitle("");
    setAddUserName("");
    setAddUserPinCode("");
    setRole("Manager");
  };
  function containsSpecialChars(str) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  }

  const handleSubmit = async (e) => {
    //edit modal form
    e.preventDefault();
    if (editPinCode.length <= 3)return alert("Pin Code should contain atleast 4 digits")
    if((containsSpecialChars(updatedUserName))) return alert("Please do not use special characters in Name");
    if((containsSpecialChars(editPinCode))) return alert("Please do not use special characters in Pin Code");
    let newArr = editValue.map((item) => item)
    let obj = {
      stations: typeof(newArr[0]) == "string" ? editValue.map((item) => item): editValue.map((item) => item.title),
      pin: editPinCode,
      name:updatedUserName,
      user_type:updatedRole,
    };
    if (editValue.length == 0) {
      alert("Please select atleast one station");
    } else {
      await dispatch(
        updateUser(obj, editId, blId, () => {
          setisEditModal((prevState) => !prevState);
          setEditValue([]);
          setEditPinCode();
          setRole("Manager");
        })
      );
    }
  };

  function containsSpecialChars(str) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  }
  const handleSubmitAdd = async (e) => {
    e.preventDefault();
    if (addUserPinCode.length <= 3) return alert("Pin Code should contain atleast 4 digits")
    if((containsSpecialChars(addUserName))) return alert("Please do not use special characters in Name");
    if((containsSpecialChars(addUserPinCode))) return alert("Please do not use special characters in Pin Code");
    let obj = {
      blId: blId,
      name: addUserName,
      user_type:Role,
      stations: value.map((item) => item.title),
      pin: addUserPinCode,
    };
    if (value.length == 0) {
      alert("Please select atleast one station");
    } else {
      await dispatch(
        createUser(obj, () => {
          setAddUserName("");
          setValue([]);
          setAddUserPinCode("");
          setisAddModal((prevState) => !prevState);
          setRole("Manager");
        })
      );
    }
  };
  const handleDelete = async(id)=>{
    await dispatch(deleteUser({id,blId}))
  }


  return (
    <>
      <div className="card p-2 border border-light rounded w-50">
        <PageTitle title="Users" icon="/img/sidebar/tag.png" />
      </div>
      <div className="d-flex justify-content-end ">
        <button
          onClick={() => {
            setisAddModal((prevState) => !prevState);
            setTitle("Add User");
          }}
          className="btn btn-primary "
        >
          <i className="fa fa-plus-circle"></i>
        </button>
      </div>

      {/* Add User Modal  */}

      <Modal
        className="rounded-0 bg-white"
        isOpen={isAddModal}
        toggle={handleCloseAdd}
      >
        <ModalHeader
          className=" border-0"
          toggle={handleCloseAdd}
        ></ModalHeader>
        <ModalBody className="pt-0 px-5 pb-5">
          <h3 className="font-weight-bold text-uppercase text-center">
            {title}
          </h3>
          <br />
          <Form role="form" onSubmit={handleSubmitAdd}>
            <FormGroup className="mb-0">
              <Label>
                Name <span className="text-danger font-weight-bold">*</span>
              </Label>
              <br />
              <Input
                className="border- league-form__input rounded-0"
                required
                value={addUserName}
                onChange={(e) => setAddUserName(e.target.value)}
              />
              <br />
              <Label>
                Station Access{" "}
                <span className="text-danger font-weight-bold">*</span>
              </Label>
              <br />

              <Autocomplete
                multiple
                style={{ width: 400 }}
                id="fixed-tags-demo"
                value={value}
                onChange={(event, newValue) => {
                  setEdit(!Edit)
                  setValue([
                    // ...fixedOptions,
                    ...newValue.filter(
                      (option) => fixedOptions.indexOf(option) === -1
                    ),
                  ]);
                 
                }}
                options={initial_list}
                getOptionLabel={(option) => option.title}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      label={option.title}
                      {...getTagProps({ index })}
                      disabled={fixedOptions.indexOf(option) !== -1}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // label=''
                    placeholder="Select Categories"
                  />
                )}
              />
              <br />
              <Label>Select</Label>
              <Input type="select" name="select"
              className="border- league-form__input rounded-0"
              required
              onChange={(e) => setRole(e.target.value)}
              >
              <option>Manager</option>
              <option>Employee</option>
              </Input>
              <br />
              <Label>
                Pincode <span className="text-danger font-weight-bold">*</span>
              </Label>
              <br />
              <Input
                className="border- league-form__input rounded-0"
                required
                value={addUserPinCode}
                onChange={(e) => setAddUserPinCode(e.target.value)}
                type="number"
              />
              <br />
            </FormGroup>
            <Button
              block
              className="rounded-0 w-100 font-weight-bold text-uppercase py-3 mt-3"
              color="primary"
              type="submit"
            >
              {addUserLoader ? <Spinner size="sm" /> : <span>Add User</span>}
            </Button>
          </Form>
        </ModalBody>
      </Modal>

      {/* Edit User Modal  */}
      <Modal
        className="rounded-0 bg-white"
        isOpen={isEditModal}
        toggle={handleClose}
      >
        <ModalHeader className=" border-0" toggle={handleClose}></ModalHeader>
        <ModalBody className="pt-0 px-5 pb-5">
          <h3 className="font-weight-bold text-uppercase text-center">
            {title}
          </h3>
          <br />
          <Form role="form" onSubmit={handleSubmit}>
            <FormGroup className="mb-0">
            <Label>
                Name <span className="text-danger font-weight-bold">*</span>
              </Label>
              <br />
              <Input
                className="border- league-form__input rounded-0"
                required
                value={updatedUserName}
                onChange={(e) => setUpdatedUserName(e.target.value)}
              />
              <br />
              <Label>
                Select your Station(s){" "}
                <span className="text-danger font-weight-bold">*</span>
              </Label>
              <br />
              <Autocomplete
                multiple
                style={{ width: 400 }}
                id="fixed-tags-demo"
                value={editStations(editValue)}
                onChange={(event, newValue) => {
                  setEditValue([
                    // ...fixedOptions,
                    ...newValue.filter(
                      (option) => editFixedOptions.indexOf(option) === -1
                    ),
                  ]);
                }}
                options={initial_list}
                getOptionLabel={(option) => option?.title}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      label={option?.title}
                      {...getTagProps({ index })}
                      disabled={editFixedOptions.indexOf(option) !== -1}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // label=''
                    placeholder="Select Categories"
                  />
                )}
              />
              <br/>
              <Label>
                Pincode <span className="text-danger font-weight-bold">*</span>
              </Label>
              <br />
              <Input
                className="border- league-form__input rounded-0"
                required
                value={editPinCode}
                onChange={(e) => setEditPinCode(e.target.value)}
                type="number"
              />
              <br />
              <Label>Select</Label>
                {updatedRole === "Manager" ?
               <Input type="select" name="select"
               className="border- league-form__input rounded-0"
               required
               onChange={(e) => setUpdatedRole(e.target.value)}
               >
               <option>Manager</option>
               <option>Employee</option>
               </Input> :  <Input type="select" name="select"
              className="border- league-form__input rounded-0"
              required
              onChange={(e) => setUpdatedRole(e.target.value)}
              >
              <option>Employee</option>
              <option>Manager</option>
              </Input>  
              
              }
              <br />
            </FormGroup>
            <Button
              block
              className="rounded-0 w-100 font-weight-bold text-uppercase py-3 mt-3"
              color="primary"
              type="submit"
            >
              {updateUserLoader ? <Spinner size="sm" /> : <span>Edit</span>}
            </Button>
          </Form>
        </ModalBody>
      </Modal>
      <div className="mt-3 card">
        {getUsersLoader ? (
          <Spinner
            className="d-flex mx-auto mt-5 mb-5"
            size="lg"
            color="primary"
          />
        ) : (
          <Table striped responsive>
            <thead>
              <tr>
                <th>Name</th>

                <th>Pincode</th>
                <th>Station Access</th>
                <th>User Type</th>
                {/* <th>Image</th> */}
                <th>Action</th>
                
              </tr>
            </thead>
            <tbody>
              <>
                {users && (
                  <>
                    {" "}
                    {users.map((data, ind) => {
                      return (
                        <tr key={ind}>
                          {data.name && <th scope="row">{data.name}</th>}
                          {!data.name && <th scope="row">test</th>}
                          <td>{data.pin} </td>
                          <td>
                            <div className="d-flex">
                              {data?.stations?.map((val) => {
                                return (
                                  <h6 className="ml-1">
                                    <Badge color="success">{val}</Badge>
                                  </h6>
                                );
                              })}
                            </div>
                          </td>
                          <td>{data.user_type}</td>
                          <td>
                            <button
                              className="btn"
                              onClick={() => {
                                setisEditModal((prevState) => !prevState);
                                setTitle("Edit User");
                                setUpdatedUserName(data.name)
                                setEditPinCode(data.pin);
                                setEditValue(data?.stations);
                                setEditId(data._id);
                                setUpdatedRole(data?.user_type && data.user_type)
                              }}
                            >
                              <i className="fa fa-pen fa-sm"></i>
                            </button>
                            <button
                              className="btn"
                              onClick={() => {
                                // setisEditModal((prevState) => !prevState);
                                // setTitle("Edit User");
                                // setEditPinCode(data.pin);
                                // setEditValue(data?.stations);
                                // setEditId(data._id);
                                // setUpdatedRole(data?.user_type && data.user_type)
                                handleDelete(data._id)
                              }}
                              // onClick={handleDelete}
                            >
                              <i className="fa fa-trash fa-sm"></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                )}
              </>
            </tbody>
          </Table>
        )}
      </div>
    </>
  );
};

export default Users;
