export const ArticleData = [
	{
		name: 'Insalate',
		id: 1,
		items: [
			{
				name: 'Insalata Mista',
				price: 2.95,
			},
			{
				name: 'Insalata Reef N Beef',
				price: 15.95,
			},
			{
				name: 'Insalata Burrata Con Verdure',
				price: 13.95,
			},
		],
	},
	{
		name: 'Kids',
		id: 2,
		items: [
			{
				name: 'Kids Pasta Pomodoro',
				price: 4.45,
			},
			{
				name: 'Kids Pasta Bolognese',
				price: 4.45,
			},
			{
				name: 'Kids Pinsa Margherita',
				price: 4.45,
			},
		],
	},
	{
		name: 'Risotto',
		id: 3,
		items: [
			{
				name: 'Risotto al Funghi',
				price: 4.45,
			},
			{
				name: 'Risotto Gamberetti',
				price: 4.45,
			},
			{
				name: 'Risotto Filetto di Manzo',
				price: 4.45,
			},
		],
	},

	{
		name: 'Pinsa',
		id: 4,
		items: [
			{
				name: 'Pinsa Trüffel',
				price: 15.9,
			},
			{
				name: 'Pinsa Margherita',
				price: 14.4,
			},

			{
				name: 'Pinsa Prosciutto e Funghi',
				price: 12.95,
			},
			{
				name: 'Pinsa Fico con noci',
				price: 15.45,
			},
		],
		name: 'Cafe Creme',
		id: 5,
		items: [
			{
				name: '1xZucker',
				price: 3,
			},
			{
				name: 'Kenien Sounderwunsch',
				price: 4,
			},

			{
				name: 'Süssstoff',
				price: 6,
			},

			{
				name: 'Hoing',
				price: 5,
			},
			{
				name: '2xZucker',
				price: 6,
			},
			{
				name: 'Vollmilch',
				price: 6,
			},
			{
				name: 'Entkoffeniert',
				price: 6,
			},

			{
				name: 'Laktosefreie Milch',
				price: 6,
			},
			{
				name: 'Sojamilch',
				price: 8,
			},
		],
	},
	{
		name: 'Pinsa',
		id: 6,

		items: [
			{
				name: 'Pinsa Trüffel',
				price: 15.9,
			},
			{
				name: 'Pinsa Margherita',
				price: 14.4,
			},

			{
				name: 'Pinsa Prosciutto e Funghi',
				price: 12.95,
			},
			{
				name: 'Pinsa Fico con noci',
				price: 15.45,
			},
		],
	},
	{
		name: 'AntiPasta',
		id: 7,

		items: [
			{
				name: 'Bruschetta Tomate',
				price: 4.45,
			},
			{
				name: 'Tomatensuppe',
				price: 9.95,
			},
			{
				name: 'Antipasti Misto Grande',
				price: 16.95,
			},
			{
				name: 'Antipasti Vitello Tonnato',
				price: 11.95,
			},
		],
	},
	{
		name: 'Pasta',
		id: 8,

		items: [
			{
				name: 'Pasta Pomodoro',
				price: 7.95,
			},
			{
				name: "Pasta All'Arrabbiata",
				price: 8.95,
			},
			{
				name: 'Pasta Aglio e Olio',
				price: 9.95,
			},
			{
				name: 'Pasta Gamberetti E Spinaci',
				price: 13.95,
			},
		],
	},
	{
		name: 'Drinks',
		id: 9,

		items: [
			{
				name: 'San bitter orange',
				price: 4.9,
			},
			{
				name: 'London Leaves',
				price: 4.9,
			},
		],
	},
];
