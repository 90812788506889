import { GET_INGREDIENTS, GET_INGREDIENTS_ALL } from 'store/types';
import { GET_INGREDIENTS_LOADER } from 'store/types';
import { RepositoryFactory } from '../../repository/RepositoryFactory';
var ingredientsRepository = RepositoryFactory.get('ingredients');
export const getLoader = val => async dispatch => {
	dispatch({
		type: GET_INGREDIENTS_LOADER,
		payload: val,
	});
};
export const getIngredients = (id, artId) => async dispatch => {
	dispatch(getLoader(true));
	let { data } = await ingredientsRepository.getIngredients(id, artId);
	// console.log('DT', data);
	if (data.success) {
		dispatch({
			type: GET_INGREDIENTS,
			payload: data.data,
		});
		dispatch(getLoader(false));
	} else {
		dispatch(getLoader(false));
	}
};

export const getAllIngredientsAct = payload => async dispatch => {
	dispatch(getLoader(true));
	let { data } = await ingredientsRepository.getAllIngredients(payload);

	if (data.success) {
		dispatch({
			type: GET_INGREDIENTS_ALL,
			payload: data.data,
		});
		dispatch(getLoader(false));
	} else {
		dispatch(getLoader(false));
	}
};
