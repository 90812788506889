export const OrderTakeAway = [
  {
    id: "T12",
    date: " 9:00:00 PM 4/11/2022",
    name: "Alex",
    Shop: (
      <div>
        Afgerond <i className="m-2 fa fa-shopping-bag"></i> Afhalen
      </div>
    ),
    items: [
      {
        naam: "Kids Pasta Bolognese",
        Prijs_per_stuk: "€ 4.45",
        Hoeveelheid: "1x",
        Totaal: "€ 4.45",
        options: ["Ohne Extra", "Keinen Sonderwunsch", " nur Grana Padano"],
      },
      {
        naam: "Pasta Reef N Beef",
        Prijs_per_stuk: "€ 15.95",
        Hoeveelheid: "1x",
        Totaal: "€ 15.95",
        options: [
          " Pastasorte Fusilli",
          "Keinen Sonderwunsch",
          "Nichts weglassen",
          "Ohne Extra",
          "Chili",
          "Knoblanch",
          " nur Grana Padano",
        ],
      },
      {
        naam: "Pasta Reef N Beef",
        Prijs_per_stuk: "€ 15.95",
        Hoeveelheid: "1x",
        Totaal: "€ 15.95",
        options: [
          " Pastasorte Fusilli",
          "Keinen Sonderwunsch",
          "Nichts weglassen",
          "Ohne Extra",
          "Chili",
          "Knoblanch",
          " nur Grana Padano",
        ],
      },
    ],
    Totaal: "€ 64.25",
    email: "info@frutech.de",
    telephone: "491606039378",
  },
  {
    id: "T10",
    date: "4:30:00 PM 4/11/2022",
    name: "Annina Paar",
    Shop: (
      <div>
        Afgerond <i className="m-2 fa fa-shopping-bag"></i>Afhalen
      </div>
    ),
    items: [
      {
        naam: "Pinsa Margherita vegan",
        Prijs_per_stuk: "€ 9.95",
        Hoeveelheid: "1x",
        Totaal: "€ 13.45",
        options: ["Ohne Extra", "Keinen Sonderwunsch", " nur Grana Padano"],
        rates:["+€ 0.50","+€ 0.50","+€ 2.50"]
      },
      {
        naam: "Ballo arancia, vegan",
        Prijs_per_stuk: "€ 1.80",
        Hoeveelheid: "1x",
        Totaal: "€ 1.80",
        
      },
    

    ],
    Totaal: "€ 15.25",
    email: " a13paar@gmail.com",
    telephone: "+491742747376",
  },

  {
    id: "T9",
    date: "3:15:00 PM 4/11/2022",
    name: "Mara Wolf",
    Shop: (
      <div>
        Afgerond <i className="m-2 fa fa-shopping-bag"></i>Afhalen
      </div>
    ),
    items: [
      {
        naam: "Pasta Filetto Di Manzo e",
        Prijs_per_stuk: "€ 14.45",
        Hoeveelheid: "1x",
        Totaal: "€ 14.45",
        options: ["Rucola", "Pastasorte Campanelle", " Ohne Lauchzwiebeln","Ohne Extra"," Viel knoblanch","Ciabatta & Grana Padano"],
       
      },
      {
        naam: "Pasta Carbonara",
        Prijs_per_stuk: "€ 11.95",
        Hoeveelheid: "1x",
        Totaal: "€ 11.95",
        options: ["Pastasorte Campanelle", " Nichts weglassen", "Ohne Extra","  Wenig chili","Knoblanch","Ciabatta & Grana Padano"],
      },
    

    ],
    Totaal: "€ 26.40",
    email: " a13paar@gmail.com",
    telephone: "+491742747376",
  },
  {
    id: "T8",
    date: "3:00:00 PM 4/11/2022",
    name: "Rico Fischer",
    Shop: (
      <div>
        Afgerond <i className="m-2 fa fa-shopping-bag"></i>Afhalen
      </div>
    ),
    items: [
      {
        naam: "Pasta Bolognese",
        Prijs_per_stuk: "€ 11.95",
        Hoeveelheid: "1x",
        Totaal: "€ 11.95",
        options: [ "Pastasorte Campanelle", "Nichts weglassen","Ohne Extra","Chili","Ciabatta & Grana Padano"],
       
      },
      
    ],
    Totaal: "€ 11.95",
    email: "ricofischer@me.com",
    telephone: "+491778485930",
  },
  {
    id: "T7",
    date: "1:00:00 AM 4/11/2022",
    name: "Jeremie Bootz",
    Shop: (
      <div>
        Afgerond <i className="m-2 fa fa-shopping-bag"></i>Afhalen
      </div>
    ),
    items: [
      {
        naam: "Pinsa Margherita",
        Prijs_per_stuk: "€ 8.95",
        Hoeveelheid: "2x",
        Totaal: "€ 17.90",
        options: [ " Pinsateig Klassisch","Ohne Extra",],
       
      },
     
    ],
    Totaal: "€ 17.90",
    email: "jeremiebootz@hotmail.de",
    telephone: "+4917630451118",
  },
  {
    id: "T6",
    date: "1:00:00 AM 4/11/2022",
    name: " Manuel Cueva",
    Shop: (
      <div>
        Afgerond <i className="m-2 fa fa-shopping-bag"></i>Afhalen
      </div>
    ),
    items: [
      
      {
        naam: "Insalata Mista",
        Prijs_per_stuk: "€ 9.95",
        Hoeveelheid: "1x",
        Totaal: "€ 9.95",
        options: ["Nizza dressing", " Nichts weglassen", "Ohne Extra","Viel Knoblanch","Ciabatta & Grana Padano"],
      },
    ],
    Totaal: "€ 9.95",
    email: "vmcueva+vapiano@gmail.com",
    telephone: "+4916094673326",
  },
  {
    id: "T5",
    date: "11:45:00 PM 4/10/2022",
    name: " Giourouk Moustafa",
    Shop: (
      <div>
        Afgerond <i className="m-2 fa fa-shopping-bag"></i>Afhalen
      </div>
    ),
    items: [
      
      {
        naam: "Insalata Mista",
        Prijs_per_stuk: "€ 10.95",
        Hoeveelheid: "2x",
        Totaal: "€ 28.80",
        options: ["Pastasorte Fusilli", " Nichts weglassen","Hähnchen", "Ohne Extra","Keinen Sonderwunsch"," nur Grana Padano"],
      },
    ],
    Totaal: "€ 9.95",
    email: " moustafa.giourouk@giourouk-gmbh.de",
    telephone: "+491727322848",
  },
  {
    id: "T4",
    date: "10:00:00 PM 4/10/2022",
    name: "Marie Etti",
    Shop: (
      <div>
        Afgerond <i className="m-2 fa fa-shopping-bag"></i>Afhalen
      </div>
    ),
    items: [
      
      {
        naam: "Insalata Cesare Gross",
        Prijs_per_stuk: "€ 9.95",
        Hoeveelheid: "2x",
        Totaal: "€ 9.95",
        options: ["Cesare dressing"],
      },
      {
        naam: "Insalata Cesare Gross",
        Prijs_per_stuk: "€ 9.95",
        Hoeveelheid: "2x",
        Totaal: "€ 9.95",
        options: ["Cesare dressing"," 1 xGarnelen"," Kirschtomaten","Hähnchen"],
      },
      {
        naam: "Insalata Nizza",
        Prijs_per_stuk: "€ 11.95",
        Hoeveelheid: "1x",
        Totaal: "€ 11.95",
        options: ["Nizza dressing","  Ohne gurke"],
      },
      {
        naam: "Insalata Nizza",
        Prijs_per_stuk: "€ 11.95",
        Hoeveelheid: "1x",
        Totaal: "€ 11.95",
        options: ["Nizza dressing"],
      },
      {
        naam: "Pinsa Prosciutto e Funghi",
        Prijs_per_stuk: "€ 12.95",
        Hoeveelheid: "1x",
        Totaal: "€ 12.95",
        options: ["Pinsateig Klassisch","Salami Ventricina"],
      },
      {
        naam: "Pinsa Burrata",
        Prijs_per_stuk: "€ 14.95",
        Hoeveelheid: "1x",
        Totaal: "€ 14.95",
        options: ["Pinsateig Klassisch"],
      },
    ],
    Totaal: "€ 83.05",
    email: "dinimed@web.de",
    telephone: "+491727322848",
  },
];
export const CollapseView = [
	{
		id: '5666594',
		date: '4/42022 PM,4:57:26',
		name: 'Vapiano Düsseldorf Medienhafen.',
		Shop: "",
		items: [
			{
				naam: 'Pasta Bolognese',
				Prijs_per_stuk: '',
				Hoeveelheid: '1x',
				Totaal: '€ 11.95',
			},
			{
				naam: 'Pasta Chicken Alfredo',
				Prijs_per_stuk: '',
				Hoeveelheid: '1x',
				Totaal: '€ 13.95',
			},
		],
		Totaal: '€ 25.90',
		email: '',
		telephone: '',
	},

		{
			id: '5666593',
			date: '4/11/2022, 8:36:28 PM',
			name: 'Vapiano Düsseldorf Medienhafen.	',
			Shop: (
				" "
			),

			items: [
				{
					naam: 'Pasta Bolognese	',
					Prijs_per_stuk: '',
					Hoeveelheid: '1x',
					Totaal: '€ 11.95',
				},
				{
					naam: 'Pasta Chicken Alfredo',
					Prijs_per_stuk: '',
					Hoeveelheid: '1x',
					Totaal: '€ 13.95',
				},
				{
					naam: 'Coca Cola 0,33L',
					Prijs_per_stuk: '',
					Hoeveelheid: '1x',
					Totaal: '€ 3.50',
				},
				{
					naam: 'Coca Cola Zero 0,33L',
					Prijs_per_stuk: '',
					Hoeveelheid: '1x',
					Totaal: '€ 3.50',
				},
			],
			Totaal: '€ 32.90',
			email: '',
			telephone: '',
		},
		{
			id: '5666558',
			date: '4/11/2022, 8:33:46 PM',
			name: 'Vapiano Düsseldorf Medienhafen.	',
			Shop: "",
			items: [
				{
					naam: 'Insalata Mista',
					Prijs_per_stuk: '',
					Hoeveelheid: '1x',
					Totaal: '€ 9.95',
				},
			],
			Totaal: '€ 18.05',
			email: '',
			telephone: '',
		},

		{
			id: '5666546',
			date: '4/11/2022, 8:33:09 PM',
			name: 'Vapiano Düsseldorf Medienhafen.',
			Shop:"",
			items: [
				{
					naam: 'Pamelas Pasta Speciale',
					Prijs_per_stuk: '',
					Hoeveelheid: '1x',
					Totaal: '€ 12.95',
				},
				{
					naam: 'Pasta Pomodoro',
					Prijs_per_stuk: '',
					Hoeveelheid: '1x',
					Totaal: '€ 7.95',
				},
				{
					naam: 'Pasta Gamberetti E Spinaci',
					Prijs_per_stuk: '',
					Hoeveelheid: '1x',
					Totaal: '€ 13.95',
				},
			],
			Totaal: '€34.50',
			email: '--',
			telephone: '--',
		},
		{
			id: '5666544',
			date: '4/11/2022, 8:32:58 PM',
			name: 'Vapiano Düsseldorf Medienhafen.',
			Shop:"",
			items: [
				{
					naam: 'Pamelas Pasta Speciale',
					Prijs_per_stuk: '',
					Hoeveelheid: '1x',
					Totaal: '€ 12.95',
				},
				{
					naam: '	Maracujaschorle',
					Prijs_per_stuk: '',
					Hoeveelheid: '1x',
					Totaal: '€ 4.95',
				},
			],
			Totaal: '€71.30',
			email: '',
			telephone: '',
		},
		{
			id: '5666519',
			date: '4/11/2022, 8:31:33 PM',
			name: 'Vapiano Düsseldorf Medienhafen.',
			Shop: "",
			items: [
				{
					naam: 'Kids Pasta Burro e Formag',
					Prijs_per_stuk: '',
					Hoeveelheid: '1x',
					Totaal: '€10.95',
				},
			],
			Totaal: '€10.95',
			email: '',
			telephone: '',
		},{
			id: '5666431',
			date: '4/11/2022, 8:24:55 PM',
			name: 'Vapiano Düsseldorf Medienhafen.',
			Shop: "",
			items: [
				{
					naam: 'Pasta Crema di Funghi',
					Prijs_per_stuk: '',
					Hoeveelheid: '1x',
					Totaal: '€ 17.90',
				},
			],
			Totaal: '€17.90',
			email: '',
			telephone: '',
		},
		{
			id: '5666364',
			date: '4/11/2022, 8:20:46 PM',
			name: 'Vapiano Düsseldorf Medienhafen.',
			Shop: "",
			items: [
				{
					naam: '	Pasta Gamberetti E Spinaci',
					Prijs_per_stuk: '',
					Hoeveelheid: '1x',
					Totaal: '€13.95',
				},
			],
			Totaal: '€13.95',
			email: '',
			telephone: '',
		},
		{
			id: '5666361',
			date: '4/11/2022, 8:20:36 PM',
			name: 'Vapiano Düsseldorf Medienhafen.',
			Shop: "",
			items: [
				{
					naam: '	Pasta Gamberetti E Spinaci',
					Prijs_per_stuk: '',
					Hoeveelheid: '1x',
					Totaal: '€13.95',
				},
			],
			Totaal: '€13.95',
			email: '',
			telephone: '',
		},
		{
			id: '5666361',
			date: '4/11/2022, 8:20:36 PM',
			name: 'Vapiano Düsseldorf Medienhafen.',
			Shop: "",
			items: [
				{
					naam: '	Pasta Gamberetti E Spinaci',
					Prijs_per_stuk: '',
					Hoeveelheid: '1x',
					Totaal: '€13.95',
				},
				{
					naam: 'Insalata Pollo Con Bulgur',
					Prijs_per_stuk: '',
					Hoeveelheid: '1x',
					Totaal: '€12.95',
				},
				{
					naam: '	Lemon Prickly Ice Tea 0,5L',
					Prijs_per_stuk: '',
					Hoeveelheid: '1x',
					Totaal: '€ 4.50',
				},	{
					naam: 'Pomegranate Ice Tea 0,5L',
					Prijs_per_stuk: '',
					Hoeveelheid: '1x',
					Totaal: '€ 4.50',
				},
			],
			Totaal: '€ 35.90',
			email: '',
			telephone: '',
		},
		
  ];
