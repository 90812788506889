import Repository from './Repository';
const GET_GUEST = './guest-wall/back-office';
const GUEST = './guest-wall';

export default {
	getAllGuestWalls(id) {
		return Repository.get(`${GET_GUEST}/${id}`);
	},
	createGuestWall(payload) {
		return Repository.post(`${GUEST}`, payload);
	},
	updateGuestWall(payload) {
		console.log(payload);
		const obj = {
			status: 'approved',
		};
		return Repository.put(`${GUEST}/${payload}`, obj);
	},
	deleteGuestWall(payload) {
		return Repository.delete(`${GUEST}/${payload}`);
	},
};
