import { GET_BUSINESSES_LOADER } from 'store/types';
import { GET_BUSINESSES } from 'store/types';

let initState = {
	getLoader: false,
	businesses: [],
};
export default function (state = initState, action) {
	switch (action.type) {
		case GET_BUSINESSES:
			return {
				...state,
				businesses: action.payload,
			};
		case GET_BUSINESSES_LOADER:
			return {
				...state,
				getLoader: action.payload,
			};
		default:
			return { ...state };
	}
}
