// Root Reducer

import { combineReducers } from 'redux';
import authReducer from './authReducer';
import authUser from './authUser';
import articleReducer from './articleReducer';
import businessReducers from './businessReducers';
import ingredientsReducer from './ingredientsReducer';
import menuReducers from './menuReducers';
import categoryReducers from './categoryReducers';
import allergenReducer from './allergenReducer';
import itemIngredients from './itemIngredients';
import floorPlansReducer from './floorPlansReducers';
import articleMenuReducers from './articleMenuReducers';
import courseReducer from './courseReducer';
import articleDetailReducers from './articleDetailReducers';
import stationReducers from './stationReducers';
import summaryReducers from './summaryReducers';
import userReducers from './userReducer';
import promotionReducers from './promotionReducer';
import guestReducers from './guestReducer';
import storeSettingReducers from './storeSettingReducer';
import bestSellerReducer from './bestSellerReducer';
import specialSellerReducer from './specialSellerReducer';
import settingsReducer from './settingsReducer';
export let rootReducer = combineReducers({
	auth: authReducer,
	authUser: authUser,
	articles: articleReducer,
	business: businessReducers,
	ingredient: ingredientsReducer,
	menu: menuReducers,
	category: categoryReducers,
	allergen: allergenReducer,
	item_ingredients: itemIngredients,
	floor_plans: floorPlansReducer,
	article_menu: articleMenuReducers,
	course: courseReducer,
	article_detail: articleDetailReducers,
	stationReducers,
	blId: summaryReducers,
	user: userReducers,
	promotion: promotionReducers,
	guest: guestReducers,
	store_setting: storeSettingReducers,
	bestseller: bestSellerReducer,
	specialseller: specialSellerReducer,
	settings:settingsReducer,
});

export default rootReducer;
