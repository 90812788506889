import React from 'react';
import { Col, Row } from 'reactstrap';
import { useState } from 'react';
const PromotionCard = ({ image, imageAlt = '' }) => {
	const [images, setImages] = useState([]);
	const[singleImage, setSingleImage] = useState('');
	// console.log(images)
	// const onSubmitHandler = (event) => {
	// 	event.preventDefault();
		
	// 	console.log(event.target.value)
	// 	setImages([...images, event.target.value]);
	// 	console.log(images)
	// }
	const handleDelete = (index) => {
		setImages(oldArray => {
			return oldArray.filter((value, i) => i !== index)
		})
	}
	return (
		<>
		<Row>
			<Col md="12" lg="12" sm="12" xs="12">
					<div className='d-flex my-2 mt-2'>
						{/* <form onSubmit={onSubmitHandler}> */}
						<input   onChange={(event)=>setImages([...images,event.target.value])}  type="file" accept="image/png, image/gif, image/jpeg, image/jpg" style={{ width: "100%", height: "50%", opacity: 0  ,position: "absolute", top: "0px", left: "0px" }}></input>
							<i className='fas fa-plus-circle ml-auto text-lg'></i>
						{/* </form> */}
					</div>
					{images && images.map((image, index) => {
						return (
							<div key={index} className='d-flex my-2 mt-2'>
								<img src={image} alt={imageAlt} className='img-fluid'></img>
								<button onClick={handleDelete(index)}><i className='fas fa-times-circle ml-auto text-lg'></i></button>
							</div>
						)
					})}


			{/* <img src={image} alt={imageAlt} className='img-fluid w-100 h-75 mt-2'/> */}
			</Col>
			</Row>
		</>
	);
};

export default PromotionCard;