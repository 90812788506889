import React from 'react';
import { NavLink } from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';
const NavItem = ({
	to,
	text,
	hasChildren,
	icon,
	icon_active,
	handleClick,
	isActive,
	className,
}) => {
	let location = useLocation();
	return (
		<div className={`nav-item ${className}`}>
			<NavLink
				to={to}
				className={`nav-link d-flex align-items-center p-0`}
				activeClassName={`active`}
				onClick={handleClick}
			>
				<img
					src={icon}
					className='mb-1 ml-2 nav-link-icon'
					alt='icon'
					height='20px'
				/>
				{icon_active ? (
					<img
						src={icon_active}
						className='mb-1 ml-2 nav-link-icon-active'
						alt='icon'
						height='20px'
					/>
				) : (
					''
				)}
				<span className='ml-2'>{text}</span>
				{hasChildren && (
					<span className='ml-auto icon-cont'>
						<i className={`fa fa-angle-right`}></i>
					</span>
				)}
			</NavLink>
		</div>
	);
};

export default NavItem;
