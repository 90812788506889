import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import AdminLayout from 'layouts/Admin.js';
import AuthLayout from 'layouts/Auth.js';

const App = () => {
	let { uid } = useSelector(state => state.authUser);
	return (
		<BrowserRouter>
			{uid ? (
				<Switch>
					<Route
						path='/store'
						render={props => (
							<AdminLayout {...props} lc_layout='store' />
						)}
					/>
					<Route
						path='/admin'
						render={props => (
							<AdminLayout {...props} lc_layout='admin' />
						)}
					/>
					<Redirect from='*' to='/admin/stores/summary' />
				</Switch>
			) : (
				<Switch>
					<Route
						path='/auth'
						render={props => <AuthLayout {...props} />}
					/>
					<Redirect from='*' to='/auth/login' />
				</Switch>
			)}
		</BrowserRouter>
	);
};

export default App;
