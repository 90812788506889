import React from "react";
import { Input } from "reactstrap";
const DropdownEditArticle =(props)=>{
    return(
        <>
        <div className="d-flex flex-row  justify-content-between mt-3w">
                <div className="d-flex flex-column w-100 ">
                  <label>
                    <b>{props.title}</b>
                  </label>

                  <Input
                    type="select"
                    name="select"
                    className="form-control border-top-0 border-right-0 border-left-0 "
                    id="option"
                  >
                    <option selected>{props.selectedValue}</option>
                    <option>Option 1</option>
                    <option>Option 2</option>
                    <option>Option 3</option>
                  </Input>
                </div>
              </div>
        </>
    )
}

export default DropdownEditArticle;