import Repository from './Repository';
const ITEM_INGREDIENTS = '/item-ingredient';
export default {
	getAllItemIngredients(payload) {
		return Repository.get(`${ITEM_INGREDIENTS}/${payload}`);
	},
	createItemIngredient(payload) {
		return Repository.post(`${ITEM_INGREDIENTS}`, payload);
	},
	removeItemIngredients(payload) {
		return Repository.delete(`${ITEM_INGREDIENTS}/${payload}`);
	},
};
