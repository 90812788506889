import axios from "axios";
// const baseDomain = 'http://localhost:5000';
// const baseDomain = 'https://vapiano-test.herokuapp.com';
const baseDomain = "https://vapiano-staging.herokuapp.com";
const baseURL = `${baseDomain}`;

let axiosObj;
axiosObj = axios.create({
  baseURL,
});

export default axiosObj;
