import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from "reactstrap";
// import { getBusinessSettings } from "store/actions/settingsAction";
import { updateRightIcons } from "store/actions/settingsAction";

const AppIconUpload = ({ businessID }) => {
  const dispatch = useDispatch();

  let storeType = null
  let pageLayoutSettings = useSelector(
    (state) => state?.settings?.allSettings?.page_layout
  );
  const [currentSettings, setCurrentSettings] = useState('')

  if (window.location.pathname === "/store/takeaway-delivery/settings") {
    storeType = 'takeaway_delivery'
    // currentSettings = currentSettings?.takeaway_delivery;
  } else if (window.location.pathname === "/store/in-store/settings") {
    storeType = 'instore'
    // currentSettings = currentSettings?.instore;
  } else if (window.location.pathname === "/store/kiosk/settings") {
    storeType = 'kiosk'
    // currentSettings = currentSettings?.kiosk;
  }

  useEffect(() => {
    if (storeType === "instore")
      setCurrentSettings(pageLayoutSettings?.instore)
    else if (storeType === "kiosk")
      setCurrentSettings(pageLayoutSettings?.kiosk)
    else if (storeType === "takeaway_delivery")
      setCurrentSettings(pageLayoutSettings?.takeaway_delivery)
  }, [storeType, pageLayoutSettings])


  const [iconLoading, setIconLoading] = useState({
    bill: false,
    cart: false,
    guest_wall: false,
    service: false,
    footer: false,
    menu: false,
  });
  const [iconSave, setIconSave] = useState({
    bill: false,
    cart: false,
    guest_wall: false,
    service: false,
    footer: false,
    menu: false,
  });
  const [iconUploads, setIconUploads] = useState({
    bill: null,
    cart: null,
    guest_wall: null,
    service: null,
    footer: null,
    menu: null,
  });

  const getImageInput = async (e) => {
    // console.log(e.target.name);
    let iconName = await e.target.name;

    // console.log(e);

    if (iconName === "footer") {
      setIconLoading({ ...iconLoading, footer: true });
    } else if (iconName === "bill") {
      setIconLoading({ ...iconLoading, bill: true });
    } else if (iconName === "service") {
      setIconLoading({ ...iconLoading, service: true });
    } else if (iconName === "cart") {
      setIconLoading({ ...iconLoading, cart: true });
    } else if (iconName === "guest_wall") {
      setIconLoading({ ...iconLoading, guest_wall: true });
    } else if (iconName === "menu") {
      setIconLoading({ ...iconLoading, menu: true });
    }

    if (!e) {
      document.getElementById("ImageInput").click();
      console.log("not an event");
    } else {
      // console.log("its an event");
      if (e.target.files && e.target.files[0]) {
        let img = await e.target.files[0];
        // let reader = await new FileReader();

        if (iconName === "footer") {
          setIconUploads({ ...iconUploads, footer: img });
          setIconLoading({ ...iconLoading, footer: false });
        } else if (iconName === "bill") {
          setIconUploads({ ...iconUploads, bill: img });
          setIconLoading({ ...iconLoading, bill: false });
        } else if (iconName === "service") {
          setIconUploads({ ...iconUploads, service: img });
          setIconLoading({ ...iconLoading, service: false });
        } else if (iconName === "cart") {
          setIconUploads({ ...iconUploads, cart: img });
          setIconLoading({ ...iconLoading, cart: false });
        } else if (iconName === "guest_wall") {
          setIconUploads({ ...iconUploads, guest_wall: img });
          setIconLoading({ ...iconLoading, guest_wall: false });
        } else if (iconName === "menu") {
          setIconUploads({ ...iconUploads, menu: img });
          setIconLoading({ ...iconLoading, menu: false });
        }
        // reader.onload = (e) => {
        //   if (e !== null) {
        //     console.log("loading reader");
        //      Icon = reader.result;
        //     console.log(Icon, "Icon");
        //     // setIconUploads({...iconUploads, footer : Icon})
        //     // setIconUploads({...iconUploads, [e.target.name] : Icon})

        //     console.log("test", iconName);

        //   }

        //   console.log(reader.result, "reader.result");
        // };
        // console.log(reader.readAsDataURL(img), "img");
        // console.log(reader.result, "img");
        // console.log(Icon, "Icon");
      }
    }

    // setTimeout(() => {
    //   console.log(iconUploads, "iconUploads");
    // }, 5000);
  };

  const handleIconUploads = async (e) => {
    let iconName = await e.target.name;

    // console.log(e.target.name, "iconName");

    if (iconName === "footer") {
      setIconSave({ ...iconSave, footer: true });
    } else if (iconName === "bill") {
      setIconSave({ ...iconSave, bill: true });
    } else if (iconName === "service") {
      setIconSave({ ...iconSave, service: true });
    } else if (iconName === "cart") {
      setIconSave({ ...iconSave, cart: true });
    } else if (iconName === "guest_wall") {
      setIconSave({ ...iconSave, guest_wall: true });
    } else if (iconName === "menu") {
      setIconSave({ ...iconSave, menu: true });
    }

    const iconPayload = {
      blId: businessID,
      type: storeType.toString(),
      sub_page: iconName.toString(),
      file: null,
    };

    if (iconName === "bill") {
      iconPayload.file = await iconUploads.bill;
      setIconSave({ ...iconSave, bill: false });
    } else if (iconName === "service") {
      iconPayload.file = await iconUploads.service;
      setIconSave({ ...iconSave, service: false });
    } else if (iconName === "cart") {
      iconPayload.file = await iconUploads.cart;
      setIconSave({ ...iconSave, cart: false });
    } else if (iconName === "guest_wall") {
      iconPayload.file = await iconUploads.guest_wall;
      setIconSave({ ...iconSave, guest_wall: false });
    } else if (iconName === "footer") {
      iconPayload.file = await iconUploads.footer;
      setIconSave({ ...iconSave, footer: false });
    } else if (iconName === "menu") {
      iconPayload.file = await iconUploads.menu;
      setIconSave({ ...iconSave, menu: false });
    } else if (iconName === "menu_top_icon") {
      iconPayload.file = await iconUploads.menu_top_icon;
      setIconSave({ ...iconSave, menu_top_icon: false });
    }

    // setTimeout(() => {
    // console.log(iconPayload, "handleIconUploads");
    dispatch(updateRightIcons(iconPayload));
    // dispatch(getBusinessSettings({
    //   buid: businessID,
    //   table_no: businessID === 660076343853236 ? 20 : null, //no table_no for Boname
    // }))
    // }, 3000);
  };

  return (
    <>
      {/*================ Menu Top Kiosk Settings========================== */}
      {window.location.pathname === "/store/kiosk/settings" ? (
        <Col xs="12">
          <div className="shadow bg-white rounded mt-5">
            <div className="wrapper p-3 ">
              <div className="title d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <img
                    src="/suitcase.png"
                    alt="clock"
                    height="20px"
                    className="mr-3"
                  />

                  <span>
                    <h4 className="mb-0 text-black">
                      {" "}
                      Top Kiosk Icon{" "}
                      {(pageLayoutSettings?.menu_top_icon !== '' || pageLayoutSettings?.menu_top_icon !== undefined) && (
                        <img
                          alt="menu top icon"
                          width={60}
                          height={50}
                          src={pageLayoutSettings?.menu_top_icon}
                        />
                      )}
                    </h4>
                  </span>
                </div>
                <div className="d-flex ml-auto">
                  {iconSave.menu_top_icon ? (
                    <span
                      className="spinner-border spinner-border-lg text-info text-center"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <Button
                      disabled={
                        iconUploads.menu_top_icon !== null || undefined
                          ? false
                          : true
                      }
                      color="primary"
                      className="text-uppercase px-4"
                      name="menu_top_icon"
                      onClick={(e) => handleIconUploads(e)}
                    >
                      Save
                    </Button>
                  )}
                </div>
              </div>
              <Row className="mt-3">
                <Col md="12" className="mt-4 mt-md-0">
                  <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center w-100">
                      <div className="d-flex flex-column flex-shrink-0">
                        <small className="font-weight-semi-bold flex-shrink-0">
                          Background
                        </small>
                        <small className="font-weight-semi-bold flex-shrink-0">
                          Max 195x67
                        </small>
                      </div>
                      <div className="w-100 border ml-4 p-2">
                        <span className="text-muted d-flex align-items-center pl-4">
                          {iconLoading.menu_top_icon ? (
                            <span
                              className="spinner-border spinner-border-lg text-info text-center"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <label
                              class="custom-file-upload w-100"
                              htmlFor="menu_top_icon"
                            >
                              <input
                                disabled={iconLoading.menu_top_icon}
                                type="file"
                                className="w-100"
                                alt="image_upload"
                                id="menu_top_icon"
                                name="menu_top_icon"
                                placeholder="bkiljkl"
                                onChange={(e) => getImageInput(e)}
                                hidden={true}
                              />
                              {iconUploads.menu_top_icon === null || undefined
                                ? "Update Image"
                                : `Image Selected: ${iconUploads.menu_top_icon !== null ||
                                  undefined
                                  ? iconUploads?.menu_top_icon?.name
                                  : "Uknown"
                                }`}
                              <i className="fa fa-plus ml-3 "></i>
                            </label>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      )
        :
        (
          // {/* ================Special Icon============================= */}
          <>
            <Col xs="12">
              <div className="shadow bg-white rounded mt-5">
                <div className="wrapper p-3 ">
                  <div className="title d-flex align-items-center">
                    <div className="d-flex align-items-center">
                      <img
                        src="/suitcase.png"
                        alt="clock"
                        height="20px"
                        className="mr-3"
                      />

                      <span>
                        <h4 className="mb-0 text-black">
                          {" "}
                          Special Icon{" "}
                          {currentSettings?.menu?.right_icon_url ? (
                            <img
                              alt="current_icon"
                              width={60}
                              height={50}
                              src={currentSettings?.menu?.right_icon_url}
                            />
                          ) : null}
                        </h4>
                      </span>
                    </div>
                    <div className="d-flex ml-auto">
                      {iconSave.menu ? (
                        <span
                          className="spinner-border spinner-border-lg text-info text-center"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <Button
                          disabled={
                            iconUploads.menu !== null || undefined ? false : true
                          }
                          color="primary"
                          className="text-uppercase px-4"
                          name="menu"
                          onClick={(e) => handleIconUploads(e)}
                        >
                          Save
                        </Button>
                      )}
                      {/* <Button color="secondary" className="text-uppercase px-4">
                  Reset
                </Button> */}
                    </div>
                  </div>
                  <Row className="mt-3">
                    <Col md="12" className="mt-4 mt-md-0">
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center w-100">
                          <div className="d-flex flex-column flex-shrink-0">
                            <small className="font-weight-semi-bold flex-shrink-0">
                              Icon
                            </small>
                            <small className="font-weight-semi-bold flex-shrink-0">
                              Max 85x85
                            </small>
                          </div>
                          <div className="w-100 border ml-4 p-2">
                            <span className="text-muted d-flex align-items-center pl-4">
                              {iconLoading.menu ? (
                                <span
                                  className="spinner-border spinner-border-lg text-info text-center"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                <label class="custom-file-upload w-100">
                                  <input
                                    type="file"
                                    disabled={iconLoading.menu}
                                    className="w-100"
                                    alt="image_upload"
                                    id="menu"
                                    name="menu"
                                    placeholder=""
                                    onChange={(e) => getImageInput(e)}
                                    hidden={true}
                                  />
                                  {iconUploads.menu === null || undefined
                                    ? "Update Image"
                                    : `Image Selected: ${iconUploads.menu !== null || undefined
                                      ? iconUploads?.menu?.name
                                      : "Uknown"
                                    }`}
                                  <i className="fa fa-plus ml-3 "></i>
                                </label>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            {/*================ Bill Page Icon ========================== */}
            <Col xs="12">
              <div className="shadow bg-white rounded mt-5">
                <div className="wrapper p-3 ">
                  <div className="title d-flex align-items-center">
                    <div className="d-flex align-items-center">
                      <img
                        src="/suitcase.png"
                        alt="clock"
                        height="20px"
                        className="mr-3"
                      />

                      <span>
                        <h4 className="mb-0 text-black">
                          {" "}
                          Bill Page Icon{" "}
                          {currentSettings?.bill?.right_icon_url ? (
                            <img
                              alt="current_icon"
                              width={60}
                              height={50}
                              src={currentSettings?.bill?.right_icon_url}
                            />
                          ) : null}
                        </h4>
                      </span>
                    </div>
                    <div className="d-flex ml-auto">
                      {iconSave.bill ? (
                        <span
                          className="spinner-border spinner-border-lg text-info text-center"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <Button
                          disabled={
                            iconUploads.bill !== null || undefined ? false : true
                          }
                          color="primary"
                          className="text-uppercase px-4"
                          name="bill"
                          onClick={(e) => handleIconUploads(e)}
                        >
                          Save
                        </Button>
                      )}
                      {/* <Button color="secondary" className="text-uppercase px-4">
                  Reset
                </Button> */}
                    </div>
                  </div>
                  <Row className="mt-3">
                    <Col md="12" className="mt-4 mt-md-0">
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center w-100">
                          <div className="d-flex flex-column flex-shrink-0">
                            <small className="font-weight-semi-bold flex-shrink-0">
                              Icon
                            </small>
                            <small className="font-weight-semi-bold flex-shrink-0">
                              Max 85x85
                            </small>
                          </div>
                          <div className="w-100 border ml-4 p-2">
                            <span className="text-muted d-flex align-items-center pl-4">
                              {iconLoading.bill ? (
                                <span
                                  className="spinner-border spinner-border-lg text-info text-center"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                <label class="custom-file-upload w-100">
                                  <input
                                    type="file"
                                    disabled={iconLoading.bill}
                                    className="w-100"
                                    alt="image_upload"
                                    id="bill"
                                    name="bill"
                                    placeholder=""
                                    onChange={(e) => getImageInput(e)}
                                    hidden={true}
                                  />
                                  {iconUploads.bill === null || undefined
                                    ? "Update Image"
                                    : `Image Selected: ${iconUploads.bill !== null || undefined
                                      ? iconUploads?.bill?.name
                                      : "Uknown"
                                    }`}
                                  <i className="fa fa-plus ml-3 "></i>
                                </label>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            {/*================ Cart Heading ========================== */}
            <Col xs="12">
              <div className="shadow bg-white rounded mt-5">
                <div className="wrapper p-3 ">
                  <div className="title d-flex align-items-center">
                    <div className="d-flex align-items-center">
                      <img
                        src="/suitcase.png"
                        alt="clock"
                        height="20px"
                        className="mr-3"
                      />

                      <span>
                        <h4 className="mb-0 text-black">
                          {" "}
                          Cart Page Icon{" "}
                          {currentSettings?.cart?.right_icon_url ? (
                            <img
                              alt="current_icon"
                              width={60}
                              height={50}
                              src={currentSettings?.cart?.right_icon_url}
                            />
                          ) : null}
                        </h4>
                      </span>
                    </div>
                    <div className="d-flex ml-auto">
                      {iconSave.cart ? (
                        <span
                          className="spinner-border spinner-border-lg text-info text-center"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <Button
                          disabled={
                            iconUploads.cart !== null || undefined ? false : true
                          }
                          color="primary"
                          name="cart"
                          className="text-uppercase px-4"
                          onClick={(e) => handleIconUploads(e)}
                        >
                          Save
                        </Button>
                      )}
                      {/* <Button color="secondary" className="text-uppercase px-4">
                  Reset
                </Button> */}
                    </div>
                  </div>
                  <Row className="mt-3">
                    <Col md="12" className="mt-4 mt-md-0">
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center w-100">
                          <div className="d-flex flex-column flex-shrink-0">
                            <small className="font-weight-semi-bold flex-shrink-0">
                              Icon
                            </small>
                            <small className="font-weight-semi-bold flex-shrink-0">
                              Max 120x60
                            </small>
                          </div>
                          <div className="w-100 border ml-4 p-2">
                            <span className="text-muted d-flex align-items-center pl-4">
                              {iconLoading.cart ? (
                                <span
                                  className="spinner-border spinner-border-lg text-info text-center"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                <label class="custom-file-upload w-100">
                                  <input
                                    type="file"
                                    disabled={iconLoading.cart}
                                    className="w-100"
                                    alt="image_upload"
                                    name="cart"
                                    placeholder=""
                                    onChange={(e) => getImageInput(e)}
                                    id="cart"
                                    hidden={true}
                                  />
                                  {iconUploads.cart === null || undefined
                                    ? "Update Image"
                                    : `Image Selected: ${iconUploads.cart !== null || undefined
                                      ? iconUploads?.cart?.name
                                      : "Uknown"
                                    }`}
                                  <i className="fa fa-plus ml-3 "></i>
                                </label>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            {/*================ Wall of fame Page Heading ========================== */}
            <Col xs="12">
              <div className="shadow bg-white rounded mt-5">
                <div className="wrapper p-3 ">
                  <div className="title d-flex align-items-center">
                    <div className="d-flex align-items-center">
                      <img
                        src="/suitcase.png"
                        alt="clock"
                        height="20px"
                        className="mr-3"
                      />

                      <span>
                        <h4 className="mb-0 text-black">
                          {" "}
                          Wall of Fame Page Icon{" "}
                          {currentSettings?.guest_wall?.right_icon_url ? (
                            <img
                              alt="current_icon"
                              width={60}
                              height={50}
                              src={currentSettings?.guest_wall?.right_icon_url}
                            />
                          ) : null}
                        </h4>
                      </span>
                    </div>
                    <div className="d-flex ml-auto">
                      {iconSave.guest_wall ? (
                        <span
                          className="spinner-border spinner-border-lg text-info text-center"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <Button
                          disabled={
                            iconUploads.guest_wall !== null || undefined
                              ? false
                              : true
                          }
                          color="primary"
                          name="guest_wall"
                          className="text-uppercase px-4"
                          onClick={(e) => handleIconUploads(e)}
                        >
                          Save
                        </Button>
                      )}
                      {/* <Button color="secondary" className="text-uppercase px-4">
                  Reset
                </Button> */}
                    </div>
                  </div>
                  <Row className="mt-3">
                    <Col md="12" className="mt-4 mt-md-0">
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center w-100">
                          <div className="d-flex flex-column flex-shrink-0">
                            <small className="font-weight-semi-bold flex-shrink-0">
                              Icon
                            </small>
                            <small className="font-weight-semi-bold flex-shrink-0">
                              Max 70x52
                            </small>
                          </div>
                          <div className="w-100 border ml-4 p-2">
                            <span className="text-muted d-flex align-items-center pl-4">
                              {iconLoading.guest_wall ? (
                                <span
                                  className="spinner-border spinner-border-lg text-info text-center"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                <label class="custom-file-upload w-100">
                                  <input
                                    type="file"
                                    disabled={iconLoading.guest_wall}
                                    className="w-100"
                                    alt="image_upload"
                                    name="guest_wall"
                                    id="guest_wall"
                                    placeholder=""
                                    onChange={(e) => getImageInput(e)}
                                    hidden={true}
                                  />
                                  {iconUploads.guest_wall === null || undefined
                                    ? "Update Image"
                                    : `Image Selected: ${iconUploads.guest_wall !== null || undefined
                                      ? iconUploads?.guest_wall?.name
                                      : "Uknown"
                                    }`}
                                  <i className="fa fa-plus ml-3 "></i>
                                </label>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            {/*================ Service Page Heading ========================== */}
            <Col xs="12">
              <div className="shadow bg-white rounded mt-5">
                <div className="wrapper p-3 ">
                  <div className="title d-flex align-items-center">
                    <div className="d-flex align-items-center">
                      <img
                        src="/suitcase.png"
                        alt="clock"
                        height="20px"
                        className="mr-3"
                      />

                      <span>
                        <h4 className="mb-0 text-black">
                          {" "}
                          Service Page Icon{" "}
                          {currentSettings?.service?.right_icon_url ? (
                            <img
                              alt="current_icon"
                              width={60}
                              height={50}
                              src={currentSettings?.service?.right_icon_url}
                            />
                          ) : null}
                        </h4>
                      </span>
                    </div>
                    <div className="d-flex ml-auto">
                      {iconSave.service ? (
                        <span
                          className="spinner-border spinner-border-lg text-info text-center"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <Button
                          disabled={
                            iconUploads.service !== null || undefined ? false : true
                          }
                          color="primary"
                          name="service"
                          className="text-uppercase px-4"
                          onClick={(e) => handleIconUploads(e)}
                        >
                          Save
                        </Button>
                      )}
                      {/* <Button color="secondary" className="text-uppercase px-4">
                  Reset
                </Button> */}
                    </div>
                  </div>
                  <Row className="mt-3">
                    <Col md="12" className="mt-4 mt-md-0">
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center w-100">
                          <div className="d-flex flex-column flex-shrink-0">
                            <small className="font-weight-semi-bold flex-shrink-0">
                              Icon
                            </small>
                            <small className="font-weight-semi-bold flex-shrink-0">
                              Max 60x152
                            </small>
                          </div>
                          <div className="w-100 border ml-4 p-2">
                            <span className="text-muted d-flex align-items-center pl-4">
                              {iconLoading.service ? (
                                <span
                                  className="spinner-border spinner-border-lg text-info text-center"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                <label class="custom-file-upload w-100">
                                  <input
                                    disabled={iconLoading.service}
                                    type="file"
                                    className="w-100"
                                    alt="image_upload"
                                    name="service"
                                    id="service"
                                    placeholder=""
                                    onChange={(e) => getImageInput(e)}
                                    hidden={true}
                                  />
                                  {iconUploads.service === null || undefined
                                    ? "Update Image"
                                    : `Image Selected: ${iconUploads.service !== null || undefined
                                      ? iconUploads?.service?.name
                                      : "Uknown"
                                    }`}
                                  <i className="fa fa-plus ml-3 "></i>
                                </label>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            {/*================ Footer Settings========================== */}
            <Col xs="12">
              <div className="shadow bg-white rounded mt-5">
                <div className="wrapper p-3 ">
                  <div className="title d-flex align-items-center">
                    <div className="d-flex align-items-center">
                      <img
                        src="/suitcase.png"
                        alt="clock"
                        height="20px"
                        className="mr-3"
                      />

                      <span>
                        <h4 className="mb-0 text-black">
                          {" "}
                          Footer Icon{" "}
                          {currentSettings?.footer?.right_icon_url ? (
                            <img
                              alt="current_icon"
                              width={60}
                              height={50}
                              src={currentSettings?.footer?.right_icon_url}
                            />
                          ) : null}
                        </h4>
                      </span>
                    </div>
                    <div className="d-flex ml-auto">
                      {iconSave.footer ? (
                        <span
                          className="spinner-border spinner-border-lg text-info text-center"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <Button
                          disabled={
                            iconUploads.footer !== null || undefined ? false : true
                          }
                          color="primary"
                          className="text-uppercase px-4"
                          name="footer"
                          onClick={(e) => handleIconUploads(e)}
                        >
                          Save
                        </Button>
                      )}
                    </div>
                  </div>
                  <Row className="mt-3">
                    <Col md="12" className="mt-4 mt-md-0">
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center w-100">
                          <div className="d-flex flex-column flex-shrink-0">
                            <small className="font-weight-semi-bold flex-shrink-0">
                              Background
                            </small>
                            <small className="font-weight-semi-bold flex-shrink-0">
                              Max 195x67
                            </small>
                          </div>
                          <div className="w-100 border ml-4 p-2">
                            <span className="text-muted d-flex align-items-center pl-4">
                              {iconLoading.footer ? (
                                <span
                                  className="spinner-border spinner-border-lg text-info text-center"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                <label
                                  class="custom-file-upload w-100"
                                  htmlFor="footer"
                                >
                                  <input
                                    disabled={iconLoading.footer}
                                    type="file"
                                    className="w-100"
                                    alt="image_upload"
                                    id="footer"
                                    name="footer"
                                    placeholder="bkiljkl"
                                    onChange={(e) => getImageInput(e)}
                                    hidden={true}
                                  />
                                  {iconUploads.footer === null || undefined
                                    ? "Update Image"
                                    : `Image Selected: ${iconUploads.footer !== null || undefined
                                      ? iconUploads?.footer?.name
                                      : "Uknown"
                                    }`}
                                  <i className="fa fa-plus ml-3 "></i>
                                </label>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </>
        )}
    </>
  );
};

export default AppIconUpload;
