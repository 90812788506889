/* eslint-disable import/no-anonymous-default-export */
import * as types from "../Types/settingsTypes"

const initState = {
	loading: false,
	gsetting: [],
	allSettings:[],
	message: null
}
export default function (state = initState, action) {
	switch (action.type) {
		case types.LOADING_SETTING:
			return {
				...state,
				loading: true,
			};
		case types.UPDATE_GENERAL_SETTING:
			return {
				...state,
				gsetting: action.payload,
				loading: false,
			};
		case types.APP_COLOR_UPDATE:
			return {
				...state,
				allSettings: action.payload,
				loading: false,
			};
		case types.RIGHT_ICON_UPDATE:
			return {
				...state,
				message: action?.payload?.message,
				allSettings: action?.payload?.data,
				loading: false,
			};
		case types.LOADING_FAILED:
			return {
				...state,
				loading: false,
			};
			case types.FETCH_ALL_SETTINGS:
			return {
				...state,
				loading: false,
				allSettings: action.payload
			};

		default:
			return { ...state };
	}
}