import Repository from './Repository';
const CATEGORY = '/category';
export default {
	getAllCategories() {
		return Repository.get(`${CATEGORY}`);
	},
	createCategory(payload) {
		let form = new FormData();
		form.append('title', payload.title);
		form.append('file', payload.image);
		return Repository.post(`${CATEGORY}`, form);
	},
	updateCategory(payload) {
		let form = new FormData();
		form.append('title', payload.title);
		form.append('file', payload.image);
		return Repository.put(`${CATEGORY}/${payload.id}`, form);
	},
	deleteCategory(payload) {
		return Repository.delete(`${CATEGORY}/${payload}`, {});
	},
};
