import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Spinner, Button } from "reactstrap";
import { removeSession } from "store/actions/floorPlanActions";
import BasicModal from "./Basic Modal/BasicModal";

export default function FloorPlanCard({ data, blId, loading }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleChange = () => {
    setOpen(!open);
  };
  return (
    <Col xs="12" sm="12" md="3" lg="3" className="p-2 ">
      <div className="card shadow p-2 mb-2 bg-white  border border-dark">
        <div class="text-right">
          {/* <Button
            type="submit"
            className="btn btn-success btn-sm"
            onClick={handleChange}
          >
            {loading ? (
              <Spinner className="d-flex mx-auto" size="sm" color="white" />
            ) : (
              "Notification"
            )
            
            }
          </Button> */}
          <Button
            type="submit"
            className="btn btn-danger btn-sm"
            onClick={() => dispatch(removeSession(data._id, blId))}
          >
            {loading ? (
              <Spinner className="d-flex mx-auto" size="sm" color="white" />
            ) : (
              "Delete"
            )}
          </Button>
        </div>
        <div className="">
          <p>{"Name: " + data.table_name}</p>
          <p>{"Number: " + data.table_id}</p>
          <p>{"Status: " + data.status}</p>
          <p>{"Person: " + data.space_used}</p>
        </div>
      </div>
      <BasicModal
        open={open}
        setOpen={setOpen}
        tableNo={data.table_id}
        _id={data._id}
      />
    </Col>
  );
}
