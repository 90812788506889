import { RepositoryFactory } from "../../repository/RepositoryFactory";
var floorPlansRepository = RepositoryFactory.get("floor_plans");

export const getFloorPlansLoader = (val) => async (dispatch) => {
  dispatch({
    type: "GET_FLOOR_PLANS_LOADER",
    payload: val,
  });
};

export const getSessionsLoader = (val) => async (dispatch) => {
  dispatch({
    type: "GET_SESSIONS_LOADER",
    payload: val,
  });
};

export const removeSessionLoader = (val) => async (dispatch) => {
  dispatch({
    type: "REMOVE_SESSION_LOADER",
    payload: val,
  });
};

export const getAllFloorPlans = (id) => async (dispatch) => {
  dispatch(getFloorPlansLoader(true));
  let { data } = await floorPlansRepository.getAllFloorplans(id);
  try {
    if (data.success) {
      dispatch({
        type: "GET_FLOOR_PLANS",
        payload: data.data,
      });
      dispatch(getFloorPlansLoader(false));
    } else {
      dispatch(getFloorPlansLoader(false));
    }
  } catch (e) {
    dispatch({
      type: "GET_FLOOR_PLANS",
      payload: [],
    });
    dispatch(getFloorPlansLoader(false));
  }
};

export const getAllSessions = (id) => async (dispatch) => {
  dispatch(getSessionsLoader(true));
  let { data } = await floorPlansRepository.getAllSessions(id);
  try {
    if (data.success) {
      dispatch({
        type: "GET_SESSIONS",
        payload: data.data,
      });
      dispatch(getSessionsLoader(false));
    } else {
      dispatch(getSessionsLoader(false));
    }
  } catch (e) {
    dispatch({
      type: "GET_SESSIONS",
      payload: [],
    });
    dispatch(getSessionsLoader(false));
  }
};

export const removeSession = (id, blId) => async (dispatch) => {
  dispatch(removeSessionLoader(true));
  try {
    let { data } = await floorPlansRepository.removeSession(id);

    if (data.success) {
      alert(data.message);
      dispatch(getAllSessions(blId));
      dispatch(removeSessionLoader(false));
    } else {
      dispatch(removeSessionLoader(false));
    }
  } catch (e) {
    alert(e.response?.data?.message);
    dispatch(removeSessionLoader(false));
  }
};
