import axios from 'axios';
import { RepositoryFactory } from '../../repository/RepositoryFactory';
var allergenRepository = RepositoryFactory.get('allergen');

export const getAllergenLoader = val => async dispatch => {
	dispatch({
		type: 'GET_ALLERGEN_LOADER',
		payload: val,
	});
};

export const allergenLoader = val => async dispatch => {
	dispatch({
		type: 'ALLERGEN_LOADER',
		payload: val,
	});
};

export const getAllAllergens = () => async dispatch => {
	dispatch(getAllergenLoader(true));
	const { data } = await allergenRepository.getAllAllergens();
	try {
		if (data.success) {
			dispatch({
				type: 'GET_ALLERGEN',
				payload: data.data,
			});
			dispatch(getAllergenLoader(false));
		} else {
			dispatch(getAllergenLoader(false));
		}
	} catch (e) {
		dispatch({
			type: 'GET_ALLERGEN',
			payload: [],
		});
		dispatch(getAllergenLoader(false));
	}
};

export const createAllergen =
	(payload, onSuccess = () => {}) =>
	async dispatch => {
		dispatch(allergenLoader(true));
		try {
			let { data } = await allergenRepository.createAllergen(payload);
			if (data.success) {
				alert('Allergen Added');
				dispatch(getAllAllergens());
				dispatch(allergenLoader(false));
				onSuccess();
			} else {
				dispatch(allergenLoader(false));
			}
		} catch (e) {
			dispatch(allergenLoader(false));
		}
	};
export const updateAllergen =
	(payload, onSuccess = () => {}) =>
	async dispatch => {
		dispatch(allergenLoader(true));
		try {
			let { data } = await allergenRepository.updateAllergen(payload);
			if (data.success) {
				alert('Allergen Updated');
				dispatch(getAllAllergens());
				dispatch(allergenLoader(false));
				onSuccess();
			} else {
				dispatch(allergenLoader(false));
			}
		} catch (e) {
			dispatch(allergenLoader(false));
		}
	};
export const deleteAllergen =
	(payload, onSuccess = () => {}) =>
	async dispatch => {
		dispatch(allergenLoader(true));
		try {
			let { data } = await allergenRepository.deleteAllergen(payload);
			if (data.success) {
				alert('Allergen Deleted');
				dispatch(allergenLoader(false));
				dispatch(getAllAllergens());
				onSuccess();
			} else {
				dispatch(allergenLoader(false));
			}
		} catch (e) {
			dispatch(allergenLoader(false));
		}
	};
