import React from "react";
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
const PaginationCom =()=>{
    return(
        <>
          <Pagination >
        <PaginationItem active>
          <PaginationLink  href="#">
            1
          </PaginationLink>
        </PaginationItem>
        <PaginationItem>
          <PaginationLink href="#">
            2
          </PaginationLink>
        </PaginationItem>
        <PaginationItem>
          <PaginationLink href="#">
            3
          </PaginationLink>
        </PaginationItem>
        <PaginationItem>
          <PaginationLink next href="#" />
        </PaginationItem>
      
      </Pagination>
        </>
    );
};
export default PaginationCom;