import Repository from './Repository';
const USER = '/user-detail';
const GET_USER = '/user-detail/users';
const DELETE_USER = 'user-detail/delete'
export default {
    getAllUsers(id) {
        return Repository.get(`${GET_USER}/${id}`);
        
    },
    createUsers(payload) {
        // console.log("data new-->", payload)
        return Repository.post(`${USER}`, payload);
    },
    updateUser(payload,_id) {
        // let form = new FormData();
        // form.append('title', payload.title);
        return Repository.put(`${USER}/${_id}`,payload);
    },
    deleteUser(_id) {
        return Repository.delete(`${DELETE_USER}/${_id}`, {});
    },
};