/*!

=========================================================
* Vapiano Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Elktech (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Elktech

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { useLocation, Route, Switch, Redirect } from 'react-router-dom';
// reactstrap components
// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import AdminFooter from 'components/Footers/AdminFooter.js';
import Sidebar from 'components/Sidebar/Sidebar.js';

import routes from 'routes.js';
import routesStoreSettings from 'routesStoreSettings.js';
import { Container } from 'reactstrap';

const Admin = props => {
	const mainContent = React.useRef(null);
	const location = useLocation();

	React.useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		mainContent.current.scrollTop = 0;
	}, [location]);
	let lc_layout = props.lc_layout;
	const getRoutes = routes => {
		let allRoutes = routes.map((prop, key) => {
			if (prop.layout === '/admin' || prop.layout === '/store') {
				if (prop.children && prop.children.length > 0) {
					return prop.children.map((child, childKey) => {
						return (
							<Route
								exact
								path={child.layout + child.path}
								component={child.component}
								key={`Parent${key}ChildKey${childKey}`}
							/>
						);
					});
				} else {
					return (
						<Route
							exact
							path={prop.layout + prop.path}
							component={prop.component}
							key={key}
						/>
					);
				}
			} else {
				return null;
			}
		});
		return allRoutes.flat();
	};

	return (
		<>
			<div className='d-block' ref={mainContent}>
				<AdminNavbar {...props} />
				<div className='d-flex'>
					<Sidebar
						{...props}
						routes={
							lc_layout == 'admin' ? routes : routesStoreSettings
						}
					/>
					<div id='main-content'>
						<Container fluid className='pt-4'>
							<Switch>
								{getRoutes(
									lc_layout == 'admin'
										? routes
										: routesStoreSettings
								)}
								<Redirect from='*' to='/admin/index' />
							</Switch>
						</Container>

						{/* <AdminFooter /> */}
					</div>
				</div>
			</div>
		</>
	);
};

export default Admin;
