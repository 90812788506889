import Repository from "./Repository";
const MENUS = "/menus/menus";
const CREATE_MENUS = "/menus";
const ALLE_ARTIKELENA = "/menus-lightspeed";
const TAKE_AWAY_MENUS = "/menus/take-aways";
const MENU_ITEM = "/menu-item";
const MENU_ITEM_DELETE = "/menus";
const POSITION_DRAG_ITEM = "/menus/order";
const KIOSK_MENU = "/menus/kiosk-menu";
export default {
  addMenu(payload) {
    return Repository.post(`${CREATE_MENUS}`, payload);
  },
  getAllMenus(payload) {
    return Repository.get(`${MENUS}/${payload}?isCache=false`);
  },
  getAllCacheMenus(payload) {
    return Repository.get(`${MENUS}/${payload}`);
  },
  getAllAlleArtikelen(payload) {
    return Repository.get(`${ALLE_ARTIKELENA}/${payload}`);
  },
  getAllKioskMenu(payload) {
    return Repository.get(`${KIOSK_MENU}/${payload}`);
  },
  getAllTakeAwayMenus(payload) {
    return Repository.get(`${TAKE_AWAY_MENUS}/${payload}`);
  },
  getSingleMenuItems(payload) {
    return Repository.get(`${MENU_ITEM}/${payload}`);
  },
  createMenuItem(payload) {
    return Repository.post(`${MENU_ITEM}`, payload);
  },
  deleteMenu(payload) {
    return Repository.delete(`${MENU_ITEM_DELETE}/${payload}`);
  },
  positionDragItem(payload, blId) {
    console.log(payload, blId);
    return Repository.put(`${POSITION_DRAG_ITEM}/${blId}`, payload);
  },
};
