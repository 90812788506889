import React from 'react';
import { Table } from 'reactstrap';

const StoreTable = ()=>{
  const values=[{
    storeName:"VAPIANO MEDIENHAFEN 20",
    takeaway:<img src='/bluedot.png' style={{height:'20px'}}/>,
    delivery:<img src='/bluedot.png' style={{height:'20px'}}/>,
    tables:42,
    floors:2,
  },
  {
    storeName:"VAPIANO DUESSELDORF 35",
    takeaway:<img src='/bluedot.png'style={{height:'20px'}}/>,
    delivery:"",
    tables:96,
    floors:4,
  },
  {
    storeName:"VAPIANO MEDIENHAFEN 20",
    takeaway:<img src='/bluedot.png' style={{height:'20px'}}/>,
    delivery:<img src='/bluedot.png' style={{height:'20px'}}/>,
    tables:96,
    floors:2,
  },
  {
    storeName:"VAPIANO DUESSELDORF 35",
    takeaway:<img src='/bluedot.png'style={{height:'20px'}}/>,
    delivery:"",
    tables:96,
    floors:4,
  },
  {
    storeName:"VAPIANO MEDIENHAFEN 20",
    takeaway:<img src='/bluedot.png' style={{height:'20px'}}/>,
    delivery:<img src='/bluedot.png' style={{height:'20px'}}/>,
    tables:42,
    floors:2,
  },
  {
    storeName:"VAPIANO DUESSELDORF 35",
    takeaway:<img src='/bluedot.png'style={{height:'20px'}}/>,
    delivery:"",
    tables:96,
    floors:4,
  },
  {
    storeName:"VAPIANO MEDIENHAFEN 20",
    takeaway:<img src='/bluedot.png' style={{height:'20px'}}/>,
    delivery:<img src='/bluedot.png' style={{height:'20px'}}/>,
    tables:42,
    floors:2,
  },
  {
    storeName:"VAPIANO DUESSELDORF 35",
    takeaway:<img src='/bluedot.png'style={{height:'20px'}}/>,
    delivery:"",
    tables:96,
    floors:4,
  },
 
]
    return (
		<>
			<Table bordered responsive className='mt-10'>
				<thead>
					<tr>
						<th>Store Name</th>
						<th>Take Away</th>
						<th>Delivery </th>
						<th>Tables</th>
						<th>Floors</th>
					</tr>
				</thead>
				<tbody>
					{values.map((data, i) => {
						return (
							<tr>
								<td>{data.storeName}</td>
								<td>{data.takeaway}</td>
								<td>{data.delivery}</td>
								<td>{data.tables}</td>
								<td>{data.floors}</td>
							</tr>
						);
					})}
				</tbody>
			</Table>
		</>
	);
};

export default StoreTable;