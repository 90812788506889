const initialData = {
	initial_list: [],
	newlist: [],
};

const stationReducers = (state = initialData, action) => {
  switch (action.type) {
    case "GET_ALL_STATIONS":
      return {
        ...state,
        initial_list:  action.payload,
      };
	//   case "CREATE_STATIONS":
    //   return {
    //     ...state,
    //     newlist: [...state.list, action.payload],
    //   };
    // case "DELETE_STATIONS":
	// 	const dlist = state.list.filter((elem) => elem.id !== action.id)
    //   return {
    //     ...state,
    //     newlist: dlist,
    //   };
    default:
      return state;
  }
};

export default stationReducers;
