import Repository from "./Repository";
const ARTICLES = "/articles";
const ARTICLE_DETAIL = "/article-detail";
const GROUP_IMAGE = "/article-detail/change-image";
const GET_IMAGE = "article-detail/group-image"
export default {
  getArticles(payload) {
    return Repository.get(`${ARTICLES}/${payload}`);
  },
  addArticles(payload) {
    return Repository.post(`${ARTICLES}`, payload);
  },
  changeItemStatusRep(payload) {
    return Repository.put(`${ARTICLE_DETAIL}`, payload);
  },
  changeGroupImageRep(payload) {
    let form = new FormData();
    form.append("file", payload.image);
    form.append("id", payload.id);
    form.append("blId", payload.blId);
    form.append("group", payload.group);
    return Repository.put(`${GROUP_IMAGE}`, form);
  },
  getGroupImage(payload) {
    // console.log(payload)
    return Repository.get(`${GET_IMAGE}/${payload.id}?blId=${payload.blId}`);
  }
};
