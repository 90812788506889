import React, { useState } from 'react';
import {
	Button,
	Col,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
} from 'reactstrap';
import StoreTable from 'store/StoreTable';
import PaginationCom from 'store/PaginationCom';
import PageTitle from 'components/PageTitle';
const Store = () => {
	let [addStoreModal, setAddStoreModal] = useState(false);
	const toggleStoreModal = () => {
		setAddStoreModal(!addStoreModal);
	};
	return (
		<>
		
			<div className='-flex align-items-center '>
				<PageTitle icon='/img/sidebar/store.png' title='Stores' />
				<span className='ml-3'>
					<i className='fas fa-cog'></i>
				</span>
				<Button
					onClick={toggleStoreModal}
					color='primary'
					className='mt-3 mt-sm-0 ml-auto'
				>
					Add Store
					<i className='ml-2 fa fa-plus-circle'></i>
				</Button>
			</div>
			<div className='card'>
			<div className='d-flex align-items-center justify-content-between p-3'>
				<span className='d-flex flex-row ml-auto'>
					<input
						type='text'
						placeholder='Search'
						class='form-control'
					/>
				</span>
			</div>

			<div className='mt-3 '>
				<StoreTable />
				</div>
			<div className='d-flex align-items-center justify-content-between'>
				<span>Showing Entities 1 to 10 from 12</span>
				<PaginationCom />
			
			</div>
			</div>
			<Modal centered isOpen={addStoreModal} toggle={toggleStoreModal}>
				<ModalHeader toggle={toggleStoreModal}>Add Store</ModalHeader>
				<Form>
					<ModalBody>
						<Row>
							<Col md='6'>
								<FormGroup>
									<Label>Name</Label>
									<Input type='text' />
								</FormGroup>
							</Col>
							<Col md='6'>
								<FormGroup>
									<Label>City</Label>
									<Input type='text' />
								</FormGroup>
							</Col>
							<Col md='6'>
								<FormGroup>
									<Label>Address</Label>
									<Input type='text' />
								</FormGroup>
							</Col>
							<Col md='6'>
								<FormGroup>
									<Label>CAP</Label>
									<Input type='text' />
								</FormGroup>
							</Col>
						</Row>
					</ModalBody>
					<ModalFooter>
						<Button color='primary'>Done</Button>
					</ModalFooter>
				</Form>
			</Modal>
		</>
	);
};

export default Store;
