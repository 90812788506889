export const LOADING_SETTING="LOADING_SETTING";

export const FETCH_GENERAL_SETTING="FETCH_GENERAL_SETTING";
export const UPDATE_GENERAL_SETTING="UPDARE_GENERAL_SETTING";

export const FETCH_APP_COLOR="FETCH_APP_COLOR";
export const APP_COLOR_UPDATE ="APP_COLOR_UPDATE";
export const RIGHT_ICON_UPDATE ="RIGHT_ICON_UPDATE";

export const FETCH_ALL_SETTINGS="FETCH_ALL_SETTING";
export const LOADING_FAILED="LOADING_FAILED"