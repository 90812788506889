import React from "react";
const SummaryCard =(props)=>{
    return(
        <>
         <div className="card mt-3 p-3 border border-light rounded  ">
                  <p className="ml-2 mt-1 font-weight-bold text-dark">
                   {props.cardName}
                  </p>

                  <div className="d-flex flex-row justify-content-between">
                    <div className="mt-3 text-center ">
                      <div className="d-flex flex-row justify-content-center card-value">
                        <img
                          src="/green-circle.png"
                          alt="green"
                          height="10px"
                          className="mr-2 mt-1"
                        />
                        <h6 className="text-dark ">
                          <b>Last Week</b>
                        </h6>
                      </div>
                      <h2 className="text-dark ">
                        <b>-7%</b>
                      </h2>
                    </div>
                    <div className="mt-3 text-center ">
                      <div className="d-flex flex-row justify-content-center card-value">
                        <img
                          src="/ellipse.png"
                          alt="ellipse"
                          height="10px"
                          className="mr-2 mt-1"
                        />
                        <h6 className="text-dark ">
                          <b>Forecast</b>
                        </h6>
                      </div>
                      <h2 className="font-weight-bold text-dark ">10%</h2>
                    </div>
                    <div className="mt-3 text-center">
                      <div className="d-flex flex-row justify-content-center card-value">
                        <img
                          src="/purple-dot.png"
                          alt="ellipse"
                          height="10px"
                          className="mr-2 mt-1"
                        />
                        <h6 className="text-dark ">
                          <b>Yesterday</b>
                        </h6>
                      </div>
                      <h2 className="text-dark ">
                        <b>+10%</b>
                      </h2>
                    </div>
                    <div className="mt-3 text-center arrow-icon">
                        <img src="/img/summary/right-arrow.png" alt="arrow" height="20px" />
                    </div>
                  </div>
                </div>
        </>
    )
}

export default SummaryCard;