import CategoryModal from "components/CategoryModal";
import PageTitle from "components/PageTitle";
import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import food from "../../../assets/img/food.jpg";
import operate from "../../../assets/img/product-main.jpg";
// import { CategoriesData } from '../../../components/DummyData/Categories';
import axios from "axios";
import { useDispatch, useSelector, connect } from "react-redux";
import StationModal from "components/StationModal";
import {
  createStations,
  deleteStations,
  updateStations,
} from "store/actions/stationActions";
import { getAllStations } from "store/actions/stationActions";

const Stations = () => {
  let [isAddModal, setIsAddModal] = useState(false);
  let [isEditModal, setIsEditModal] = useState(false);
  const [title, setTitle] = useState("");
  const [id, setId] = useState("");
  const [inputName, setInputName] = useState("");
  const [editInputName, setEditInputName] = useState("");
  const [loading, setLoading] = useState(false);
  //   const [stationsData, setStationsData] = useState([]);
  const list = useSelector((state) => state.stationReducers.list);
  const initial_list = useSelector(
    (state) => state.stationReducers.initial_list
  );
  const dispatch = useDispatch();
  const handleClose = () => {
    setIsAddModal(false);
    setTitle("");
  };

  const handleEditClose = () => {
    setIsEditModal(false);
    setTitle("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let obj = {
      title: inputName,
      //   id: new Date().getTime().toString(),
    };
    await dispatch(createStations(obj), setInputName(""));
    setLoading(false);
    setIsAddModal((prevState) => !prevState);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let obj = {
      id: id,
      title: editInputName,
      //   id: new Date().getTime().toString(),
    };
    await dispatch(updateStations(obj), setEditInputName(""));
    setLoading(false);
    setIsEditModal((prevState) => !prevState);
  };

  useEffect(() => {
    dispatch(getAllStations());
    // Safe to add dispatch to the dependencies array
  }, []);

  return (
    <>
      <div className="card p-2 border border-light rounded w-50">
        <PageTitle title="Stations" icon="/img/sidebar/tag.png" />
      </div>
      <div className="d-flex justify-content-end ">
        <button
          onClick={() => {
            setIsAddModal((prevState) => !prevState);
            setTitle("Add Station");
          }}
          className="btn btn-primary "
        >
          <i className="fa fa-plus-circle"></i>
        </button>
      </div>

      <Modal
        className="rounded-0 bg-white"
        isOpen={isAddModal}
        toggle={handleClose}
      >
        <ModalHeader className=" border-0" toggle={handleClose}></ModalHeader>
        <ModalBody className="pt-0 px-5 pb-5">
          <h3 className="font-weight-bold text-uppercase text-center">
            {title}
          </h3>
          <Form role="form" onSubmit={handleSubmit}>
            <FormGroup className="mb-0">
              <Label>
                Item Name{" "}
                <span className="text-danger font-weight-bold">*</span>
              </Label>
              <Input
                className="border- league-form__input rounded-0"
                required
                value={inputName}
                onChange={(e) => setInputName(e.target.value)}
              />
            </FormGroup>
            <Button
              block
              className="rounded-0 w-100 font-weight-bold text-uppercase py-3 mt-3"
              color="primary"
              type="submit"
            >
              {loading ? <Spinner size="sm" /> : <span>Add +</span>}
            </Button>
          </Form>
        </ModalBody>
      </Modal>
      <div className="mt-3 card">
        <Table striped responsive>
          <thead>
            <tr>
              <th>#</th>

              <th>Stations</th>
              {/* <th>Image</th> */}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {initial_list.map((data, ind) => {
              return (
                <tr key={ind}>
                  <th scope="row">{ind + 1}</th>
                  <td>{data.title} </td>
                  <td>
                    <button
                      className="btn"
                      onClick={() => {
                        setIsEditModal((prevState) => !prevState);
                        setTitle("Edit Station");
                        setEditInputName(data.title);
                        setId(data._id);
                      }}
                    >
                      <i className="fa fa-pen fa-sm"></i>
                    </button>
                    <button className="btn">
                      <i
                        className="fa fa-trash fa-sm"
                        onClick={() => dispatch(deleteStations(data._id))}
                      ></i>
                    </button>
                  </td>
                </tr>
              );
              {
              }
            })}
          </tbody>
        </Table>
      </div>
      <Modal
        className="rounded-0 bg-white"
        isOpen={isEditModal}
        toggle={handleEditClose}
      >
        <ModalHeader
          className=" border-0"
          toggle={handleEditClose}
        ></ModalHeader>
        <ModalBody className="pt-0 px-5 pb-5">
          <h3 className="font-weight-bold text-uppercase text-center">
            {title}
          </h3>
          <Form role="form" onSubmit={handleEditSubmit}>
            <FormGroup className="mb-0">
              <Label>
                Item Name{" "}
                <span className="text-danger font-weight-bold">*</span>
              </Label>
              <Input
                className="border- league-form__input rounded-0"
                required
                value={editInputName}
                onChange={(e) => setEditInputName(e.target.value)}
              />
            </FormGroup>
            <Button
              block
              className="rounded-0 w-100 font-weight-bold text-uppercase py-3 mt-3"
              color="primary"
              type="submit"
            >
              {loading ? <Spinner size="sm" /> : <span>Update</span>}
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Stations;
