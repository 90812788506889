import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomInput } from "reactstrap";
import {
  assignItemIngredients,
  removeItemIngredients,
} from "store/actions/itemIngredientActions";

const CustomSwitch = ({ value, check, setIngredientsData }) => {
  const dispatch = useDispatch();
  const [enable, setEnable] = useState(false);
  const handleChange = (name, val, value) => {
    let newArray;
    if (name == "ingredient_name") {
      let memoData = {
        ingredient_name: "",
        sku: "",
        type: "normal",
        price: "",
        group: "",
        group_id: "",
      };
      memoData[name] = val.ingredient_name;
      memoData["sku"] = val.ingredient_sku;
      memoData["price"] = val.ingredient_price;
      memoData["group"] = val.ingredient_group;
      memoData["group_id"] = val.group_id;
      newArray = [...check, memoData];
      if (
        check &&
        check.some((a) => a.ingredient_name === val.ingredient_name)
      ) {
        newArray = newArray.filter(
          (b) => b.ingredient_name !== val.ingredient_name
        );
      }
      setIngredientsData(newArray);
    } else {
      let obj =
        check &&
        check.find((val) => val.ingredient_name === value.ingredient_name);
      if (obj) {
        obj.type = val;
        obj.price = value.ingredient_price;
        obj.group = value.ingredient_group;
        obj.group_id = value.group_id;
        newArray = [...check];
      }
      setIngredientsData(newArray);
    }
  };

  const getCheckedValue = (val, value) => {
    let obj =
      check &&
      check.find(
        (a) => a.type === val && a.ingredient_name === value.ingredient_name
      );
    if (obj) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    let obj =
      check &&
      check.find((val) => val.ingredient_name === value.ingredient_name);
    if (obj) {
      setEnable(true);
    }
  }, [check]);

  return (
    <div className="d-flex flex-row">
      <CustomInput
        type="switch"
        id={value.ingredient_name}
        checked={
          check &&
          check.some((val) => val.ingredient_name === value.ingredient_name)
        }
        onChange={(e) => {
          setEnable(e.target.checked);
          e.target.checked == true
            ? handleChange("ingredient_name", value)
            : handleChange("ingredient_name", value);
        }}
      />{" "}
      <label>{value.ingredient_name}</label>
      {enable && (
        <div>
          <input
            className="ml-2 mt-1"
            name={value.ingredient_name}
            checked={getCheckedValue("Standard", value)}
            type="radio"
            value={"Standard"}
            disabled={!enable}
            onChange={(e) => handleChange("type", e.target.value, value)}
          />
          <label className="ml-2 mb-1">Standard</label>
          {/* <input
            className="ml-2 mt-1"
            checked={getCheckedValue("Extras", value)}
            disabled={!enable}
            value={"Extras"}
            name={value.ingredient_name}
            type="radio"
            onChange={(e) => handleChange("type", e.target.value, value)}
          />
          <label className="ml-2 mb-1">Extras</label>{" "} */}
        </div>
      )}
    </div>
  );
};

export default CustomSwitch;
