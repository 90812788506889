import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import {
  Input,
  Table,
  FormGroup,
  Badge,
  Button,
  Spinner,
  Collapse,
} from "reactstrap";
import { addMenuDetailsInArticle } from "store/actions/article_detailsAction";
import { positionDragItem } from "store/actions/menuActions";
import { addMenu } from "store/actions/menuActions";
import { deleteMenu } from "store/actions/menuActions";

function MenuSection(props) {
  const { menuItems, menuAddLoader, getMenuLoader, getAlleArtikelenLoader } =
    useSelector((state) => state.menu);

  const { articles } = useSelector((state) => state?.articles);
  const { blId } = useSelector((state) => state.blId);
  let [activeMenus, setActiveMenus] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [checkboxData, setCheckboxData] = useState([
    "name",
    "price",
    "sku",
    "menu_tags",
  ]);
  const dispatch = useDispatch();
  const getMenuItems = () => {
    let obj =
      menuItems &&
      menuItems.filter((single) => single.menu_id == props.activeMenu);
    if (obj) {
      return obj;
    } else {
      return [];
    }
  };

  const groupMenus = () => {
    let results = [];
    // console.log(props.menus);
    props.menus &&
      props.menus.map((data) => {
        const check = results.find((el) => el.id === data.id);
        if (results.length == 0 || !check) {
          results.push({
            group: data.group,
            id: data.id,
            items: [
              {
                _id: data._id,
                blId: data.blId,
                name: data.name,
                price: data.price,
                sku: data.sku,
                menu_tags: data.menu_tags,
              },
            ],
          });
        } else {
          check.items.push({
            _id: data._id,
            blId: data.blId,
            name: data.name,
            price: data.price,
            sku: data.sku,
            menu_tags: data.menu_tags,
          });
        }
      });
    return results;
  };

  const getFilteredAlleArtikelen = (items) => {
    let data = [];
    {
      // console.log(items);
    }
    data =
      items &&
      items.filter((item) => {
        return !props.menus.find((el) => {
          return el.sku === item.sku;
        });
      });
    return data;
    // data = props.data.menuEntryGroups.filter(item => {
    // 	return (
    // 		item.menuEntry &&
    // 		item.menuEntry.map(data => {
    // 			return !props.menus.find(el => {
    // 				return el.plu === data.sku;
    // 			});
    // 		})
    // 	);
    // });
  };

  // const expandAll = () => {
  //   setActiveMenus(prevProp => {
  //     return ingredients.map(article => article.id);
  //   });
  // };
  const collapseAll = () => {
    setActiveMenus([]);
  };
  const isMenuActive = (idx) => {
    return activeMenus.indexOf(idx) != -1;
  };
  const toggleMenuActive = (idx) => {
    console.log("TOGGLE MENUU ACTIVE");
    setActiveMenus((prevProp) => {
      let articleIdx = activeMenus.indexOf(idx);

      if (articleIdx == -1) {
        prevProp.push(idx);
        return [...prevProp];
      } else {
        prevProp.splice(articleIdx, 1);
        return [...prevProp];
      }
    });
  };
  const getMenuItemDetails = (id) => {
    if (id) {
      let obj =
        articles &&
        articles.map(
          (article) =>
            article.items && article.items.find((item) => item.id == id)
        );
      if (obj) {
        return obj[0];
      } else {
        return "N/A";
      }
    } else {
      return "N/A";
    }
  };

  const handleCheckboxChange = (name) => {
    let newArray = [...checkboxData, name];
    if (checkboxData.includes(name)) {
      newArray = newArray.filter((n) => n != name);
    }
    setCheckboxData(newArray);
  };
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const temp = groupMenus();
    let tag = temp[0]?.items[0]?.menu_tags;
    if (temp[result.source.index].id === temp[result.destination.index].id)
      //if same line drag
      return;
    if (
      result.source.index === "undefined" ||
      result.destination.index === "undefined"
    )
      return;
    const obj = {
      startIndex: ++result.source.index,
      endIndex: ++result.destination.index,
      menu_tags: tag,
      // Source_Group_Id:temp[result.source.index].id,
      // Destination_Group_Id:temp[result.destination.index].id
    };
    dispatch(positionDragItem(obj, blId));
    console.log(result);
    console.log(temp);
  };
  return (
    <>
      <div className="card">
        <div className="card-header">
          <h5 className="card-title">{props.title}</h5>
        </div>
        <div className="card-body mt-2 p-2">
          <Input
            type="text"
            placeholder="Search"
            onChange={(e) => setSearchItem(e.target.value)}
          />
          <Table borderless responsive size="sm">
            <thead>
              <tr>
                <td className="text-center">NAME</td>
                <td className="text-center">PRICE</td>
                <td className="text-center">SKU</td>
                <td className="text-center">MENUTAGS</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center pl-3">
                  <FormGroup check inline>
                    <Input
                      type="checkbox"
                      checked={checkboxData.includes("name")}
                      onChange={(e) => handleCheckboxChange("name")}
                    />
                  </FormGroup>
                </td>
                <td className="text-center pl-3">
                  <FormGroup check inline>
                    <Input
                      type="checkbox"
                      checked={checkboxData.includes("price")}
                      onChange={(e) => handleCheckboxChange("price")}
                    />
                  </FormGroup>
                </td>
                <td className="text-center">
                  <FormGroup check inline>
                    <Input
                      type="checkbox"
                      checked={checkboxData.includes("sku")}
                      onChange={(e) => handleCheckboxChange("sku")}
                    />
                  </FormGroup>
                </td>
                <td className="text-center">
                  <FormGroup check inline>
                    <Input
                      type="checkbox"
                      checked={checkboxData.includes("menu_tags")}
                      onChange={(e) => handleCheckboxChange("menu_tags")}
                    />
                  </FormGroup>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>

        <div className="px-2">
          {/* <div className='d-flex font-weight-bold'>
						<span className='d-flex align-items-center'>
							Rows per page:{' '}
							<small className='ml-3 mt-1'>1-100 of 466</small>
						</span>
						<div className='ml-auto'>
							<span className='cursor-pointer'>
								<i className='fas fa-angle-left mx-2 fa-1x'></i>
							</span>
							<span className='cursor-pointer'>
								<i className='fas fa-angle-right'></i>
							</span>
						</div>
					</div> */}
        </div>
        <div className="mt-3">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppableArticle">
              {(provided) => (
                <div ref={provided.innerRef}>
                  {props.title == "Alle Artikelen" ? (
                    <>
                      {getAlleArtikelenLoader ? (
                        <Spinner
                          className="d-flex mx-auto mt-5"
                          size="lg"
                          color="primary"
                        />
                      ) : (
                        <>
                          {props.data ? (
                            <>
                              {props.data &&
                                props.data.map((menu, k) => {
                                  return (
                                    <>
                                      <>
                                        <div
                                          className="d-flex align-items-center p-3"
                                          onClick={() => toggleMenuActive(k)}
                                        >
                                          <h6 className="ml-3 mt-1">
                                            {menu.group}
                                          </h6>
                                          {/* <div className='d-flex flex-column group-name'>
                                                <small className='text-xs'>Group name</small>
                                                <h6 className='h6 mb-0'>{ingredient?.group}</h6>
                                              </div> */}

                                          {isMenuActive(k) ? (
                                            <i
                                              style={{ marginLeft: "auto" }}
                                              className="fa fa-angle-up"
                                            ></i>
                                          ) : (
                                            <i
                                              style={{ marginLeft: "auto" }}
                                              className="fa fa-angle-down"
                                            ></i>
                                          )}
                                        </div>

                                        <Table responsive>
                                          <Collapse
                                            style={{
                                              display: isMenuActive(k)
                                                ? "contents"
                                                : "none",
                                            }}
                                            isOpen={isMenuActive(k)}
                                          >
                                            <thead>
                                              <tr
                                                onClick={() =>
                                                  toggleMenuActive(k)
                                                }
                                                className="font-weight-bold"
                                              >
                                                {/* <th></th> */}
                                                {checkboxData.includes(
                                                  "name"
                                                ) ? (
                                                  <th>Name</th>
                                                ) : (
                                                  ""
                                                )}
                                                {checkboxData.includes(
                                                  "price"
                                                ) ? (
                                                  <th>price</th>
                                                ) : (
                                                  ""
                                                )}
                                                {checkboxData.includes(
                                                  "sku"
                                                ) ? (
                                                  <th>sku</th>
                                                ) : (
                                                  ""
                                                )}
                                                {checkboxData.includes(
                                                  "menu_tags"
                                                ) ? (
                                                  <th>MenuTags</th>
                                                ) : (
                                                  ""
                                                )}
                                                {/* <th>Name</th>
															<th>price</th>
															<th>sku</th>
															<th>MenuTags</th> */}
                                                {/* <th>
                                              <i className="fa fa-minus"></i>
                                            </th> */}
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {getFilteredAlleArtikelen(
                                                menu.items
                                              )
                                                .filter((menu) => {
                                                  if (searchItem === "") {
                                                    return menu;
                                                  } else if (
                                                    menu.productName &&
                                                    menu.productName
                                                      .toLowerCase()
                                                      .includes(
                                                        searchItem.toLowerCase()
                                                      )
                                                  ) {
                                                    return menu;
                                                  }
                                                })
                                                .map((data, idx) => {
                                                  return (
                                                    <tr key={idx}>
                                                      <td>
                                                        <Button
                                                          onClick={() => {
                                                            let article_detail =
                                                              {
                                                                blId: blId,
                                                                article_id:
                                                                  data.sku,
                                                                menus: [
                                                                  props.menu_tag,
                                                                ],
                                                              };
                                                            let obj = {
                                                              blId: blId,
                                                              group: menu.group,
                                                              id: menu.id,
                                                              name: data.productName,
                                                              price:
                                                                data.productPrice
                                                                  ? data.productPrice
                                                                  : 0,
                                                              sku: data.sku,
                                                              menu_tags:
                                                                props.menu_tag,
                                                            };
                                                            dispatch(
                                                              addMenu(obj)
                                                            );
                                                            dispatch(
                                                              addMenuDetailsInArticle(
                                                                article_detail
                                                              )
                                                            );
                                                          }}
                                                          color="primary"
                                                          size="sm"
                                                        >
                                                          {menuAddLoader ? (
                                                            <Spinner size="sm" />
                                                          ) : (
                                                            <i
                                                              class="fa fa-plus-circle"
                                                              aria-hidden="true"
                                                            ></i>
                                                          )}
                                                        </Button>
                                                      </td>
                                                      {checkboxData.includes(
                                                        "name"
                                                      ) ? (
                                                        <td>
                                                          {data.productName}
                                                        </td>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {checkboxData.includes(
                                                        "price"
                                                      ) ? (
                                                        <td>
                                                          {data.productPrice
                                                            ? data.productPrice
                                                            : 0}
                                                        </td>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {checkboxData.includes(
                                                        "sku"
                                                      ) ? (
                                                        <td>{data.sku}</td>
                                                      ) : (
                                                        ""
                                                      )}

                                                      <td></td>
                                                    </tr>
                                                  );
                                                })}
                                            </tbody>{" "}
                                          </Collapse>
                                        </Table>
                                      </>
                                    </>
                                  );
                                })}
                            </>
                          ) : (
                            <div className="p-5"></div>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {getMenuLoader ? (
                        <Spinner
                          className="d-flex mx-auto mt-5"
                          size="lg"
                          color="primary"
                        />
                      ) : (
                        <>
                          {props.menus ? (
                            <>
                              {props.menus &&
                                groupMenus().map((data, k) => {
                                  return (
                                    <>
                                      <Draggable
                                        key={k}
                                        draggableId={k.toString()}
                                        index={k}
                                      >
                                        {(provided, snapshot) => (
                                          <>
                                            <div
                                              className="d-flex align-items-center p-3"
                                              onClick={() =>
                                                toggleMenuActive(k)
                                              }
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <h6 className="ml-3 mt-1">
                                                {data.group}
                                              </h6>

                                              {isMenuActive(k) ? (
                                                <i
                                                  style={{ marginLeft: "auto" }}
                                                  className="fa fa-angle-up"
                                                ></i>
                                              ) : (
                                                <i
                                                  style={{ marginLeft: "auto" }}
                                                  className="fa fa-angle-down"
                                                ></i>
                                              )}
                                            </div>

                                            <Table responsive>
                                              <Collapse
                                                style={{
                                                  display: isMenuActive(k)
                                                    ? "contents"
                                                    : "none",
                                                }}
                                                isOpen={isMenuActive(k)}
                                              >
                                                <thead>
                                                  <tr
                                                    onClick={() =>
                                                      toggleMenuActive(k)
                                                    }
                                                    className="font-weight-bold"
                                                  >
                                                    <th></th>
                                                    {checkboxData.includes(
                                                      "name"
                                                    ) ? (
                                                      <th>Name</th>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {checkboxData.includes(
                                                      "price"
                                                    ) ? (
                                                      <th>price</th>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {checkboxData.includes(
                                                      "sku"
                                                    ) ? (
                                                      <th>sku</th>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {checkboxData.includes(
                                                      "menu_tags"
                                                    ) ? (
                                                      <th>MenuTags</th>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {data.items
                                                    .filter((menu) => {
                                                      if (searchItem === "") {
                                                        return menu;
                                                      } else if (
                                                        menu.name &&
                                                        menu.name
                                                          .toLowerCase()
                                                          .includes(
                                                            searchItem.toLowerCase()
                                                          )
                                                      ) {
                                                        return menu;
                                                      }
                                                    })
                                                    .map((item, idx) => {
                                                      return (
                                                        <tr key={idx}>
                                                          <td>
                                                            <Button
                                                              onClick={() => {
                                                                let article_detail =
                                                                  {
                                                                    blId: blId,
                                                                    article_id:
                                                                      item.sku,
                                                                    menus: [
                                                                      props.menu_tag,
                                                                    ],
                                                                  };
                                                                dispatch(
                                                                  deleteMenu(
                                                                    item._id,
                                                                    item.blId
                                                                  )
                                                                );
                                                                dispatch(
                                                                  addMenuDetailsInArticle(
                                                                    article_detail
                                                                  )
                                                                );
                                                              }}
                                                              color="danger"
                                                              size="sm"
                                                            >
                                                              {menuAddLoader ? (
                                                                <Spinner size="sm" />
                                                              ) : (
                                                                <i
                                                                  className="fa fa-minus-circle"
                                                                  aria-hidden="true"
                                                                ></i>
                                                              )}
                                                            </Button>
                                                          </td>
                                                          {checkboxData.includes(
                                                            "name"
                                                          ) ? (
                                                            <td>{item.name}</td>
                                                          ) : (
                                                            ""
                                                          )}
                                                          {checkboxData.includes(
                                                            "price"
                                                          ) ? (
                                                            <td>
                                                              {item.price}
                                                            </td>
                                                          ) : (
                                                            ""
                                                          )}
                                                          {checkboxData.includes(
                                                            "sku"
                                                          ) ? (
                                                            <td>{item.sku}</td>
                                                          ) : (
                                                            ""
                                                          )}
                                                          {checkboxData.includes(
                                                            "menu_tags"
                                                          ) ? (
                                                            <td>
                                                              {item.menu_tags ===
                                                              "In Store" ? (
                                                                <Badge
                                                                  pill
                                                                  className="border border-danger text-danger bg-transparent"
                                                                >
                                                                  IS
                                                                </Badge>
                                                              ) : (
                                                                <Badge
                                                                  pill
                                                                  className="border border-danger text-danger bg-transparent"
                                                                >
                                                                  TA
                                                                </Badge>
                                                              )}
                                                            </td>
                                                          ) : (
                                                            ""
                                                          )}
                                                          {/* <td></td> */}
                                                        </tr>
                                                      );
                                                    })}
                                                </tbody>
                                              </Collapse>
                                            </Table>
                                          </>
                                        )}
                                      </Draggable>
                                    </>
                                  );
                                })}
                            </>
                          ) : (
                            <div className="p-5"></div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </>
  );
}

export default MenuSection;
